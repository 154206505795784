import React from 'react';
import { Button } from '@screentone/core';

import styles from './PreviewThumbnail.module.css';
import { constants } from '../../../utils';

type PreviewThumbnailType = {
  onClick: () => void;
  type?: string;
  children?: any;
  option: any;
  sizeKey: string;
  disabled?: boolean;
  skipOriginal?: boolean;
};

function PreviewThumbnail({ option, sizeKey, onClick, disabled, type, children, skipOriginal }: PreviewThumbnailType) {
  const { DEFAULT_IMAGE_KEY } = constants;
  const { ar, label, url } = option;
  let imageUrl = url;

  const active = type === label;
  const buttonClasses = [styles.preview__button];
  if (ar < 0.6) {
    buttonClasses.push(styles['preview__button--tall']);
  }
  if (sizeKey === DEFAULT_IMAGE_KEY && type !== undefined) {
    buttonClasses.push(styles['preview__button--small']);
  }

  if (skipOriginal && sizeKey === DEFAULT_IMAGE_KEY) {
    return null;
  }

  return (
    <div
      key={label}
      className={disabled ? [styles.preview__menuitem, styles.opacity].join(' ') : styles.preview__menuitem}
      style={
        {
          '--im-preview-ratio': ar,
          '--im-preview-width': `${Math.round(100 * ar)}px`,
          '--im-preview-url': `url(${imageUrl})`,
        } as React.CSSProperties
      }
    >
      <button
        type="button"
        aria-current={active}
        className={buttonClasses.join(' ')}
        onClick={() => onClick()}
        disabled={disabled || skipOriginal}
        title={label}
      >
        <span>{label}</span>
      </button>
      {children}
    </div>
  );
}

function PreviewThumbnailCustomBtn({ active = false, onClick = () => {}, disabled = false }) {
  return (
    <Button
      data-testid="custom-size-btn"
      secondary
      size="sm"
      active={active}
      className={styles['preview__button--token-button']}
      margin={{ top: 'sm' }}
      onClick={() => onClick()}
      disabled={disabled}
    >
      Custom Size
    </Button>
  );
}

export default PreviewThumbnail;

export { PreviewThumbnailCustomBtn };
