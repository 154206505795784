import React from 'react';
import { Sidebar, SideNav, useHeader } from '@screentone/core';
import { useLocation, Link } from 'react-router-dom';

function DeveloperSidebar({ sections }: any) {
  const { containerHeight } = useHeader();
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/$/, '');
  const search = location.search || '';

  return (
    <Sidebar top={`${containerHeight}px`} type="permanent">
      <Sidebar.Section>
        <SideNav>
          {sections.map((section: { label: any; routes: { route: string; label: string }[] }) => {
            return (
              <SideNav.Section label={section.label}>
                {section.routes.map((item: { route: string; label: string; attributes?: any }) => {
                  //   { route: '/developer/ai/resolution', page: AI_Resolution, label: 'Improve Image Resolution' },
                  return (
                    <SideNav.Item
                      {...(item?.attributes || {})}
                      componentEl={Link}
                      to={item.route + search}
                      active={currentPath === item.route}
                      SideNav
                    >
                      {item.label}
                    </SideNav.Item>
                  );
                })}
              </SideNav.Section>
            );
          })}
        </SideNav>
      </Sidebar.Section>
    </Sidebar>
  );
}

export default DeveloperSidebar;
