import { buildUrl } from 'cloudinary-build-url';
import { getCrop } from '../../utils/helpers';

import type { ImageType, PropertyType } from '../../types';

export const getOverlayPreviewImg = ({
  mask,
  renderWidth,
  cloudName,
  image,
  property,
  publishedId,
}: {
  mask: number[];
  renderWidth?: number;
  cloudName: string;
  image: ImageType;
  property: PropertyType;
  publishedId?: string;
}) => {
  const crop = getCrop(image, property, publishedId as string);
  const DEFAULT_RENDER_WIDTH = 400;
  const [x, y, width, height] = mask;
  const transformationsObj = {
    chaining: mask.length
      ? [
          {
            resize: {
              type: 'crop',
              width,
              height,
            },
            x,
            y,
          },
          {
            gravity: 'north_west',
            opacity: 37,
            underlay: `${image.public_id.replace(/\//g, ':')}`,
            x: `-${x}`,
            y: `-${y}`,
          },
        ]
      : [],
  };

  if (crop && crop.length > 0 && crop.join(',') !== mask.join(',')) {
    const [crop_x, crop_y, crop_width, crop_height] = crop;

    transformationsObj.chaining.push({
      resize: {
        type: 'crop',
        width: crop_width,
        height: crop_height,
      },
      x: crop_x,
      y: crop_y,
    });
  }

  transformationsObj.chaining.push({
    resize: {
      type: 'scale',
      width: Math.round(Number(renderWidth) || DEFAULT_RENDER_WIDTH),
    },
    background: 'white',
  });
  const url = buildUrl(image.public_id, {
    cloud: {
      cloudName,
    },
    transformations: transformationsObj,
  });
  return {
    src: url,
    aspectRatio: crop[2] / crop[3] || image.aspect_ratio,
  };
};

export const getCoordinateOffsets = (
  coordinates: number[],
  image: ImageType,
  property: PropertyType,
  publishedId: string
) => {
  if (coordinates.some(isNaN) && coordinates.length <= 4) {
    console.error('Invalid coordinates', coordinates);
  }
  const [x, y, width, height] = coordinates;

  const crop = getCrop(image, property, publishedId);

  if (crop) {
    const [crop_x, crop_y] = crop;
    return [x - crop_x, y - crop_y, width, height];
  }

  return coordinates;
};
