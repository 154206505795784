import React, { useState } from 'react';
import { Group, Button, Typography } from '@screentone/core';
import TwentyTwenty from 'react-twentytwenty';

function DeveloperDocs() {
  const [image, setImage] = useState({
    id: 'cldExample',
    left: 'https://res.cloudinary.com/demo/image/upload/pm/product_updates/2023/02/brooklyn_bridge.jpg',
    right: 'https://res.cloudinary.com/demo/image/upload/e_upscale/pm/product_updates/2023/02/brooklyn_bridge.jpg',
  });
  const [sliderPosition, setSliderPosition] = useState(1);

  const sliderStyles = {
    height: '100%',
    width: '2px',
    background: '#FFF',
  };
  const imageStyles = {
    width: '800px',
  };
  return (
    <>
      <Typography variant="header1">AI : Improve Image Resolution</Typography>
      <Group margin={{ bottom: 'md' }}>
        <Button
          secondary
          size="sm"
          active={image.id === 'cldExample'}
          onClick={() => {
            setImage({
              id: 'cldExample',
              left: 'https://res.cloudinary.com/demo/image/upload/pm/product_updates/2023/02/brooklyn_bridge.jpg',
              right:
                'https://res.cloudinary.com/demo/image/upload/e_upscale/v1674845506/pm/product_updates/2023/02/brooklyn_bridge.jpg',
            });
            setSliderPosition(1);
          }}
        >
          Cloudinary Example
        </Button>
        <Button
          secondary
          size="sm"
          active={image.id === 'dogExample'}
          onClick={() => {
            setSliderPosition(1);
            setImage({
              id: 'dogExample',
              left: 'https://images-wsj.dev.djtools.dowjones.io/image/upload/t_preview/f_auto,q_auto:eco/wsj/cld/aocbgom2g5tbt1elsty7',
              right:
                'https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/wsj/cld/aocbgom2g5tbt1elsty7',
            });
            setSliderPosition(1);
          }}
        >
          Dog Example
        </Button>
        {/* 
        <Button
          secondary
          size="sm"
          active={image.id === 'XuanchengExample'}
          onClick={() => {
            setImage({
              id: 'XuanchengExample',
              left: 'https://images-wsj.dev.djtools.dowjones.io/image/upload/t_preview/f_auto,q_auto:eco/wsj/cld/yycjialquzq3vyier9cv',
              // xright:
              //   'https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/wsj/cld/zsstswjuur0hlcw81dwm',
              right:
                'https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/l_image:upload:wsj:edited-using-ai/c_scale,fl_relative,w_0.20/o_80/fl_layer_apply,g_south_east,x_0.03,y_0.04/wsj/cld/yycjialquzq3vyier9cv',
            });
            setSliderPosition(1);
          }}
        >
          DESMI Foundry Xuancheng im-178814
        </Button> */}
        <Button
          secondary
          size="sm"
          active={image.id === 'flightExample'}
          onClick={() => {
            setImage({
              id: 'flightExample',
              left: 'https://images-wsj.dev.djtools.dowjones.io/image/upload/t_preview/f_auto,q_auto:eco/wsj/cld/zsstswjuur0hlcw81dwm',
              // xright:
              //   'https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/wsj/cld/zsstswjuur0hlcw81dwm',
              right:
                'https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/l_image:upload:wsj:edited-using-ai/c_scale,fl_relative,w_0.20/o_80/fl_layer_apply,g_south_east,x_0.03,y_0.04/wsj/cld/zsstswjuur0hlcw81dwm',
            });
            setSliderPosition(1);
          }}
        >
          WSJ flight Image (im-22665)
        </Button>

        {/* https://images-mansionglobal.dev.djnews.tools/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/l_image:upload:wsj:edited-using-ai/c_scale,fl_relative,w_0.20/o_80/fl_layer_apply,g_south_east,x_0.03,y_0.04/wsj/cld/zsstswjuur0hlcw81dwm.jpg */}
        {/* https://images-mansionglobal.dev.djnews.tools/image/upload/s--KKPq7_4n--///l_image:upload:wsj:edited-using-ai/c_scale,fl_relative,w_0.20/o_80/fl_layer_apply,g_south_east,x_0.03,y_0.04/v1696276538/wires/AFP/92904902_AFP.jpg */}
        {/* flight */}
      </Group>
      <div style={imageStyles}>
        <TwentyTwenty
          defaultPosition={sliderPosition}
          // eslint-disable-next-line @next/next/no-img-element
          left={<img style={imageStyles} src={image.left} alt="original" />}
          // eslint-disable-next-line @next/next/no-img-element
          right={<img style={imageStyles} src={image.right} alt="ai generated" />}
          slider={<div style={sliderStyles} />}
        />
      </div>

      <p>https://cloudinary.com/guides/image-effects/how-to-automatically-improve-image-resolution-complete-guide</p>
    </>
  );
}

export default DeveloperDocs;
