import { Group, Typography, Illustration } from '@screentone/core';
import React from 'react';
import styles from './NoResults.module.css';

function NoResults() {
  return (
    <Group data-testid="search-error" direction="column" align="center" margin={{ vertical: 'xl' }}>
      <Illustration name="empty" className={styles.illustration} />
      <Typography size="xxl">Nothing to see here</Typography>
      <Typography>
        No images were found within your search parameters. Please check your search options and try again.
      </Typography>
    </Group>
  );
}

export default NoResults;
