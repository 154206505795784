import React from 'react';
import { Group, IconAI, Token, Typography } from '@screentone/core';

import userImageDetail from '../../../hooks/useImageDetail';

function AiCaptioning() {
  const { image } = userImageDetail();

  return (
    <>
      {image.info?.detection?.captioning?.data?.caption && (
        <Typography color="asphalt" size="sm">
          <Token icon={IconAI} color="ice">
            AI Image Captioning
          </Token>{' '}
          {image.info.detection.captioning.data.caption}
        </Typography>
      )}
    </>
  );
}

export default AiCaptioning;
