import React from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { FileRejection } from 'react-dropzone';

import actions from './actions';
import { defaultUploadState } from './allReducer';
import { handleImageFiles, retryUploads, upload, retryUploadsPromo } from './effects';
import mergedUploadReducer from './mergedUploadReducer';
import { constants } from '../../utils';
import { useConfig } from '../useConfig';

import UseUploadType, { Files } from './types';

const UploadContext = createContext<
  | {
      actions: typeof actions;
      dispatch: UseUploadType.Merged.Dispatch;
      effects: {
        handleImageFiles(files: File[], errors: FileRejection[]): void;
        retryUploads(): void;
        upload(type: 'single' | 'dynamic'): void;
        retryUploadsPromo(): void;
      };
      state: UseUploadType.Merged.State;
      type: 'single' | 'dynamic';
    }
  | undefined
>(undefined);

/** Arguments for UploadProvider */
type UploadProviderArguments = {
  type?: 'single' | 'dynamic';
  children?: React.ReactNode;
};

/** Provider for context */
export const UploadProvider = ({ type = 'single', children }: UploadProviderArguments) => {
  const { user } = useAuth();
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const [state, dispatch] = useReducer(mergedUploadReducer, defaultUploadState);
  const maxImages = constants.MAX_FILES_IN_UPLOADER[type];

  const effects = {
    handleImageFiles: useCallback(
      (acceptedFiles: File[], rejectedFiles: FileRejection[]) =>
        handleImageFiles({
          acceptedFiles: acceptedFiles as Files.Accepted,
          currentNumberOfAcceptedFiles: state.files.accepted.length,
          dispatch,
          rejectedFiles: rejectedFiles as unknown as Files.Rejected,
          user: user || {},
          property,
          maxImages,
          uploadType: type,
          authFetch
        }),
      [property, maxImages, state.files.accepted.length, type, user]
    ),
    retryUploads: useCallback(
      () => retryUploads({ authFetch, dispatch, property, state }),
      [authFetch, property, dispatch, state]
    ),
    upload: useCallback(
      (type: any) => upload({ authFetch, dispatch, property, state, type }),
      [authFetch, property, dispatch, state]
    ),
    retryUploadsPromo: useCallback(
      () => retryUploadsPromo({ authFetch, dispatch, property, state }),
      [authFetch, property, dispatch, state]
    ),
  };

  return (
    <UploadContext.Provider value={{ actions, dispatch, effects, state, type }}>{children}</UploadContext.Provider>
  );
};

/**
 * Hook for getting and setting information about images in the uploader
 * For more information about the pattern used, see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const useUpload = () => {
  const context = useContext(UploadContext);

  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
};

export default useUpload;
