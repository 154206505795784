import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Group,
  Button,
  Dialog,
  IconBug,
  Typography,
  IconExternalLink,
  useModalState,
  useModalPortal,
  IconCr,
  IconTrash,
  Divider,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import userImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';

import InnerBrowserModel from '../../InnerBrowserModel';

import { iframe } from '../../../utils';
import AddToCollectionDropdown, {
  formatCollectionImage,
} from '../../Collections/AddToCollectionDropdown/AddToCollectionDropdown.component';

interface DescriptionProps {
  disabled?: boolean;
}

function DeveloperButtons({ disabled }: DescriptionProps) {
  const {
    authFetch,
    config: {
      app: { cldAccountId },
    },
    session: { appUrl, property, env },
  } = useConfig();

  const { image, publishedId, transformationType } = userImageDetail();
  const { open: openAddToSources, openModal, closeModal } = useModalState();
  const [debugData, setDebugData] = useState(null);
  const { renderNode } = useModalPortal();

  const { userAccess } = useAuth();
  const isAdmin = userAccess('app_admin');

  return (
    <Box padding={{ all: 'md' }} margin={{ top: 'md' }}>
      <Group direction="column" gap="none">
        <Typography variant="label2">Developer Links</Typography>
        {isAdmin && (
          <Button
            icon={IconExternalLink}
            tertiary
            componentEl="a"
            href={`https://console.cloudinary.com/console/${cldAccountId}/media_library/search/asset/${image.asset_id}/manage?q=&context=manage`}
            target="_blank"
            rel="noreferrer"
            disabled={disabled}
          >
            Cloudinary
          </Button>
        )}

        <Button
          tertiary
          color="lava"
          icon={IconTrash}
          onClick={({ target }: { target: HTMLButtonElement }) => {
            target.setAttribute('disabled', 'true');
            target.setAttribute('aria-disabled', 'true');
            authFetch(
              `/api/:property/${encodeURIComponent(image.public_id)}/deleteCache/${image.type}/${image.resource_type}`,
              {
                method: 'DELETE',
              },
            )
              .then((res) => res.json())
              .then((data) => console.log(`Cached Cleared for ${image.public_id}: `, data))
              .catch((err) => console.error(err))
              .finally(() => {
                target.removeAttribute('disabled');
                target.removeAttribute('aria-disabled');
              });
          }}
        >
          Cache
        </Button>
        <Button
          icon={IconBug}
          tertiary
          disabled={disabled || !publishedId}
          onClick={async () => {
            const data = await iframe.sendToSource({
              image,
              ids: [publishedId as string],
              env,
              property,
              appUrl,
            });
            setDebugData(data);
            openModal();
          }}
        >
          Add to Source
        </Button>
        <Button
          tertiary
          icon={IconBug}
          componentEl={Link}
          disabled={disabled || !publishedId}
          to={`/developer/debugger/renderer?${
            publishedId ? `id=${publishedId.split(',')[0]}&` : ''
          }property=${property}`}
        >
          FN Select
        </Button>
        <Button
          tertiary
          icon={IconBug}
          componentEl={Link}
          disabled={disabled || !publishedId}
          to={`/developer/debugger/quality?${
            publishedId ? `id=${publishedId.split(',')[0]}&` : ''
          }property=${property}`}
        >
          Quality
        </Button>
        <Button
          tertiary
          icon={IconBug}
          componentEl={Link}
          disabled={disabled || !publishedId}
          to={`/developer/debugger/social?${publishedId ? `id=${publishedId.split(',')[0]}&` : ''}property=${property}`}
        >
          Watermarking
        </Button>
        {transformationType === 'djSmartCrop' && (
          <Button
            tertiary
            icon={IconBug}
            componentEl={Link}
            disabled={disabled || !publishedId}
            to={`/developer/debugger/smartcrop?${
              publishedId ? `id=${publishedId.split(',')[0]}&` : ''
            }property=${property}`}
          >
            Smart Crop
          </Button>
        )}
        {isAdmin && (
          <InnerBrowserModel
            trigger="Content Credential"
            icon={IconCr}
            href={`https://contentcredentials.org/verify?source=${image.secure_url.replace(
              'image/upload/',
              'image/upload/fl_c2pa/c_fill,g_auto,h_530,ar_1,dpr_2/f_auto,q_auto:eco/',
            )}`}
          />
        )}
        <Divider />
        <AddToCollectionDropdown images={[formatCollectionImage(image, publishedId)]} />
      </Group>
      {openAddToSources && (
        <Dialog
          onDismiss={closeModal}
          renderNode={renderNode}
          status={openAddToSources ? 'open' : 'closed'}
          size="fluid"
        >
          <Dialog.Title id="dialogTitle">Add to Source JSON</Dialog.Title>
          <Dialog.Content>
            <pre>{JSON.stringify(debugData, null, 4)}</pre>
          </Dialog.Content>
        </Dialog>
      )}
    </Box>
  );
}

export default DeveloperButtons;
