import React from 'react';
import { IconCross, IconMinimize, Responsive } from '@screentone/core';

const CloseButtonIcon = () => (
  <>
    <Responsive xs sm>
      <IconMinimize />
    </Responsive>
    <Responsive md lg xl>
      <IconCross />
    </Responsive>
  </>
);

export default CloseButtonIcon;
