/* eslint-disable no-plusplus */

import actions from './actions';
import UseUploadType from './types';

export const uploadNavigationDefaultState: UseUploadType.Navigation.State = 0;

const uploadNavigationReducer = (
  state: UseUploadType.Navigation.State,
  action: UseUploadType.Navigation.Action
): UseUploadType.Navigation.State => {
  switch (action.type) {
    /** navigate to previous step in upload flow */
    case actions.NAVIGATION.BACK: {
      return (state ? state - 1 : state) as UseUploadType.Navigation.State;
    }

    /** navigate to next step in upload flow */
    case actions.NAVIGATION.FORWARD: {
      return (state <= 1 ? state + 1 : state) as UseUploadType.Navigation.State;
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default uploadNavigationReducer;
