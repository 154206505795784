import Calendar, { format, parse } from '@screentone/addon-calendar';
import { Button, Dropdown, IconCalendar, IconCaretDown, Token, Typography, useDropdownState } from '@screentone/core';
import React, { useState } from 'react';

import useDateFilter from './useDateFilter';
import { constants } from '../../../utils';
import { useSearch } from '../../../hooks/useSearch';

import styles from './DateFilter.module.css';

type DateFilterProps = {
  dateFiltered?: { since: string; until: string };
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<{
      since: string;
      until: string;
    }>
  >;
  numberOfMonths?: number;
};

const DateFilter = ({ dateFiltered, setSelectedDate, numberOfMonths }: DateFilterProps) => {
  const { open: openCalendar, setOpen: setOpenCalendar, componentRef } = useDropdownState();

  const { options, updateSearchOptions } = useSearch();
  const { filters, onSelect, setTriggerText, since, until, triggerText } = useDateFilter();
  const [presetSelected, setPresetSelected] = useState('');

  function getDifferenceInDays(date1: Date, date2: Date): number {
    // Convert both dates to milliseconds
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = date2Ms - date1Ms;

    // Convert milliseconds to days
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    return differenceDays;
  }

  const selectFilter = (startDate: Date | null, endDate: Date | null, label: string): void => {
    onSelect({ startDate, endDate });
    setTriggerText(label);
  };

  return (
    <Dropdown
      data-testid="date-filter"
      componentRef={componentRef}
      onToggle={() => setOpenCalendar(!openCalendar)}
      open={openCalendar}
      padding={{ all: 'none' }}
    >
      <Dropdown.Trigger>
        <Button tertiary icon={IconCalendar} active={triggerText !== 'Any Time' || openCalendar}>
          {triggerText}

          <IconCaretDown style={{ marginLeft: 'var(--st-spacer-xs)' }} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <div className={styles.container}>
          <div className={styles.presets}>
            <Button
              data-testid="any-time-filter"
              onClick={() => {
                setPresetSelected('anytime');
                onSelect({ startDate: filters.anyTime.startDate, endDate: filters.anyTime.endDate });
              }}
              size="sm"
              tertiary
            >
              Any Time
            </Button>
            <Button
              data-testid="today-filter"
              onClick={() => {
                setPresetSelected('today');
                onSelect({ startDate: filters.today.startDate, endDate: filters.today.endDate });
              }}
              size="sm"
              tertiary
            >
              Today
            </Button>
            <Button
              data-testid="last-week-filter"
              onClick={() => {
                setPresetSelected('lastWeek');
                onSelect({ startDate: filters.lastWeek.startDate, endDate: filters.lastWeek.endDate });
              }}
              size="sm"
              tertiary
            >
              Last Week
            </Button>
            <Button
              data-testid="last-month-filter"
              onClick={() => {
                setPresetSelected('lastMonth');
                onSelect({ startDate: filters.lastMonth.startDate, endDate: filters.lastMonth.endDate });
              }}
              size="sm"
              tertiary
            >
              Last Month
            </Button>
            <Button
              data-testid="last-year-filter"
              onClick={() => {
                setPresetSelected('lastYear');
                onSelect({ startDate: filters.lastYear.startDate, endDate: filters.lastYear.endDate });
              }}
              size="sm"
              tertiary
            >
              Last Year
            </Button>
          </div>

          <div data-testid="selected-dates-text" className={styles.textContent}>
            {since ? (
              <Typography>
                Since <Token color="blurple">{format(since, constants.DATE_FORMATS.SEARCH.DATE_FILTER)}</Token> until{' '}
                <Token color="blurple">
                  {until ? format(until, constants.DATE_FORMATS.SEARCH.DATE_FILTER) : filters.today.label}
                </Token>
              </Typography>
            ) : (
              <Typography> No dates selected. </Typography>
            )}
          </div>

          <Calendar
            onSelect={onSelect}
            dateRangeStart
            dateRangeEnd
            startDate={since}
            endDate={until}
            numberOfMonths={numberOfMonths || 2}
            maxDate={new Date()}
            calendarBodyProps={{ style: { border: 0 } }}
          />

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                onSelect({
                  startDate: options.since ? parse(options.since, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null,
                  endDate: options.until ? parse(options.until, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null,
                });
                setOpenCalendar(false);
                const untilDate = new Date(Date.parse(options.until as string));
                const currentDate = new Date();
                const sinceDate = new Date(Date.parse(options.since as string));

                const daysBetweenDates = getDifferenceInDays(untilDate, sinceDate);

                switch (daysBetweenDates) {
                  case 0:
                    if (currentDate > untilDate || currentDate > sinceDate) {
                      break;
                    }
                    selectFilter(filters.today.startDate, filters.today.endDate, filters.today.label);
                    setPresetSelected('today');
                    break;
                  case 7:
                    selectFilter(filters.lastWeek.startDate, filters.lastWeek.endDate, filters.lastWeek.label);
                    setPresetSelected('lastWeek');
                    break;
                  case 30:
                    selectFilter(filters.lastMonth.startDate, filters.lastMonth.endDate, filters.lastMonth.label);
                    setPresetSelected('lastMonth');
                    break;
                  case 365:
                    selectFilter(filters.lastYear.startDate, filters.lastYear.endDate, filters.lastYear.label);
                    setPresetSelected('lastYear');
                    break;
                  default:
                    break;
                }
              }}
              secondary
            >
              Cancel
            </Button>
            <Button
              data-testid="date-filter-search-btn"
              margin={{ left: 'md' }}
              onClick={() => {
                const newSince = since ? format(since, constants.DATE_FORMATS.CLOUDINARY) : '';
                const newUntil = until ? format(until, constants.DATE_FORMATS.CLOUDINARY) : '';

                // setting label

                switch (presetSelected) {
                  case 'today':
                    selectFilter(filters.today.startDate, filters.today.endDate, filters.today.label);
                    break;
                  case 'lastWeek':
                    selectFilter(filters.lastWeek.startDate, filters.lastWeek.endDate, filters.lastWeek.label);
                    break;
                  case 'lastMonth':
                    selectFilter(filters.lastMonth.startDate, filters.lastMonth.endDate, filters.lastMonth.label);
                    break;
                  case 'lastYear':
                    selectFilter(filters.lastYear.startDate, filters.lastYear.endDate, filters.lastYear.label);
                    break;
                  default:
                    selectFilter(filters.anyTime.startDate, filters.anyTime.endDate, filters.anyTime.label);
                }

                if (typeof setSelectedDate !== 'undefined') {
                  setSelectedDate({ since: newSince, until: newUntil });
                  setTriggerText(`${newUntil} ${newSince}`);
                } else {
                  updateSearchOptions({ ...options, since: newSince, until: newUntil });
                }

                setOpenCalendar(false);
              }}
              primary
            >
              Search
            </Button>
          </div>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default DateFilter;
