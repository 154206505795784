import * as helpers from './helpers';
import type { EnvironmentType, PropertyType, ImageType } from '../types';
import round from 'lodash/round';

/**
 * A function for sharing image details via e-mail
 */
const smartCropInfo = async ({
  image,
  env,
  property,
  id,
  width,
  height,
  authFetch,
}: {
  image: ImageType;
  env: EnvironmentType;
  property: PropertyType;
  id: string;
  width: number;
  height: number;
  authFetch: any;
}) => {
  return authFetch(`/api/${property}/${id as string}/smartcrop?width=${width}&height=${height}`)
    .then((oldSC) => {
      const host = helpers.getMD5Domain(env, property);
      const cropObj = JSON.parse(image.context[`dj_smart_crop_${id}`]);
      const newScCrops = cropObj.map((crop) => {
        console.log('crop: ', crop);
        return {
          width: round(crop.width, 4),
          height: round(crop.height, 4),
          x: round(crop.x, 4),
          y: round(crop.y, 4),
          outputHeight: crop.outputHeight,
          outputWidth: crop.outputWidth,
          seed: crop.seed,
          size: crop.size,
        };
      });
      const newScContext = { ...image.context, ...{ [`dj_smart_crop_${id}`]: JSON.stringify(newScCrops) } };
      const newSC = helpers.getCldTransformationImage({
        image: { ...image, ...{ context: newScContext } },
        publishedId: id,
        host,
        width,
        height,
      });

      const cldContext = { ...image.context, ...{ [`transformation_type_${id}`]: 'cldDefault' } };
      const newCLD = helpers.getCldTransformationImage({
        image: { ...image, ...{ context: cldContext } },
        publishedId: id,
        host,
        width,
        height,
      });

      console.log('newSC: ', newSC.smartcrop); // eslint-disable-line no-console
      console.log('oldSC: ', oldSC.smartcrop); // eslint-disable-line no-console

      return { oldSC, newSC, newCLD, error: null };
    })
    .catch((error) => {
      return {
        oldSC: null,
        newSC: null,
        newCLD: null,
        error,
      };
    });
};

export default smartCropInfo;
