import cloneDeep from 'lodash/cloneDeep';
import { errors , regex} from '../../../utils';
import UseUploadType from '../types';
import actions from '../actions';

type SignedParams = {
  [key: string]: string;
};

type UploadImageParams = {
  /** the action to fetch with authorization header */
  authFetch(resource: string, options?: RequestInit): Promise<unknown>;
  /** the action dispatcher from the reducer hook */
  dispatch: UseUploadType.Merged.Dispatch;
  /** current property/brand */
  property: string;
  /** state of useUpload hook */
  state: UseUploadType.Merged.State;
  /** type of image to upload */
  type: 'single' | 'dynamic';
};

/**
 * Upload the image to Cloudinary via image manager's api
 */
const uploadImages = ({ authFetch, dispatch, property, state, type }: UploadImageParams) => {
  const errorFiles: File[] = [];
  const errorMetadata: UseUploadType.Metadata.State = [];
  if (state.upload.status.length > 0) {
    state.files.accepted.forEach((file, i) => {
      if (state.upload.status[i] === 'error' || state.upload.status[i] === 'ready') {
        errorFiles.push(file);
        errorMetadata.push(state.metadata[i]);
      }
    });
  }
  const metadataToUpload = errorMetadata.length > 0 ? errorMetadata : cloneDeep(state.metadata);
  const payload = {
    ...metadataToUpload,
    property,
  };

  return authFetch(`/api/:property/upload/${type === 'single' ? 'request' : type}`, {
    method: 'POST',
    body: JSON.stringify({ metadata: { ...metadataToUpload, property } }),
  })
    .then(async (response: any) => {
      const body = response;

      if (body.length > 0) {
        const filesToUpload = errorFiles.length > 0 ? errorFiles : state.files.accepted;
        filesToUpload.forEach(async (file, i) => {
          try {
            dispatch({ type: actions.UPLOAD.START_FETCH, payload: { payload, index: i } });
            const { formData, uploadUrl } = body[i];
            const form = new FormData();

            form.append('file', file);
            // Append all options (tags, metadata, context, eager, etc) to formData
            Object.entries(formData as SignedParams).forEach(([key, value]) => {
              form.append(key, value);
            });

            const uploadResponse = await fetch(uploadUrl, { method: 'POST', body: form });
            const data = await uploadResponse.text();
            const responseData = JSON.parse(data);

            if (!responseData.error) {
              const cleanResponseData = { ...responseData };
              cleanResponseData.isDynamic = responseData.public_id.split('/')[1] === 'dynamic';
              cleanResponseData.isAnimated = (responseData.pages || 0) > 1;
              cleanResponseData.isChart = !!regex.chartSourceRegex.exec(responseData?.metadata?.import_source || '');

              if (cleanResponseData?.context?.custom) {
                cleanResponseData.context = cleanResponseData.context.custom;
              }
              dispatch({
                type: actions.UPLOAD.FINISH_FETCH,
                payload: { response: cleanResponseData, index: i },
              });
            } else {
              throw new Error(responseData.error.message);
            }
          } catch (error: any) {
            console.error(`error uploading to cloudinary: ${JSON.stringify(error)}`);
            const { message, status } = errors.extractError(error);

            dispatch({
              type: actions.UPLOAD.ERROR,
              payload: { error: message, errorStatus: status, index: i },
            });
          }
        });
      }
    })
    .catch((error: any) => {
      console.error(`error fetching signature from cloudinary: ${JSON.stringify(error)}`);
      const { message, status } = errors.extractError(error);
      dispatch({ type: actions.UPLOAD.ERROR, payload: { error: message, errorStatus: status, index: 0 } });
    });
};

export default uploadImages;
