//   LOOK in src/components/ImageDetails/ImageCoordinates.tsx FOR Old code IMPLEMENTATION
import React, { useRef, useState, useEffect } from 'react';
import ReactCrop, { Crop, PixelCrop, PercentCrop, convertToPercentCrop } from 'react-image-crop';
import useImageDetail from '../../../../hooks/useImageDetail';

import useEditImage from '../../../../hooks/useEditImage';
import { constants } from '../../../../utils';

import { buildCoordinatesFromSaved, buildCoordinatesToSave } from './imageCoordinates.utils';

import 'react-image-crop/dist/ReactCrop.css';
import styles from '../Content.module.css';

type CropProps = Crop;

type ImageCoordinatesProps = {
  /** static height of display image */
  FIXED_IMAGE_HEIGHT?: number;
};

const ImageCoordinates = ({ FIXED_IMAGE_HEIGHT }: ImageCoordinatesProps) => {
  const fixedImageHeight = FIXED_IMAGE_HEIGHT || constants.FIXED_IMAGE_HEIGHT;
  const cropContentType = 'CROP';
  const { image } = useImageDetail();
  const { contentComponent, setData, getData, ARLock, imageSrcUrl } = useEditImage();
  const [localImageSrc, setLocalImageSrc] = useState(
    contentComponent === cropContentType ? image.secure_url : imageSrcUrl || image.secure_url,
  );

  const [coordinates, setCoordinates] = useState(getData('coordinates', contentComponent as string));
  const [cropCoordinates, setCropCoordinates] = useState(getData('coordinates', cropContentType));

  const [focalPointMoved, setFocalPointMoved] = useState(false);

  const [imageWidth, setImageWidth] = useState(
    contentComponent === cropContentType ? image.width : cropCoordinates ? cropCoordinates[2] : image.width,
  );
  const [imageHeight, setImageHeight] = useState(
    contentComponent === cropContentType ? image.height : cropCoordinates ? cropCoordinates[3] : image.height,
  );
  const [offsets, setOffsets] = useState(
    contentComponent === cropContentType
      ? { x: 0, y: 0 }
      : cropCoordinates
      ? { x: Number(cropCoordinates[0]), y: Number(cropCoordinates[1]) }
      : { x: 0, y: 0 },
  );

  const prevNewHeightRef = useRef<number | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setCoordinates(getData('coordinates', contentComponent as string));
    setCropCoordinates(getData('coordinates', cropContentType));
  }, [image, contentComponent]);

  useEffect(() => {
    if (cropCoordinates && contentComponent !== cropContentType) {
      const [cropX, cropY, cropWidth, cropHeight] = cropCoordinates;
      // set the x & y offset
      setOffsets({ x: Number(cropX), y: Number(cropY) });
      // update the image height to the crop height for the scale calculation
      setImageWidth(Number(cropWidth));
      setImageHeight(Number(cropHeight));

      setLocalImageSrc(
        localImageSrc.replace(`v${image.version}`, `c_crop,x_${cropX},y_${cropY},w_${cropWidth},h_${cropHeight}/`),
      );
    }
  }, [cropCoordinates, contentComponent, localImageSrc, image]);

  // if the image's aspect ratio is portrait or landscape, we need to calculate the scale differently
  // although the image has a fixed height of 530px, if the image is much wider than it is long, the
  // actual image itself will not have a height of 530px
  // const renderedHeight = imageRef.current?.getBoundingClientRect().height || FIXED_IMAGE_HEIGHT;
  let renderedHeight: number;
  if (imageHeight > fixedImageHeight) {
    renderedHeight = fixedImageHeight;
  } else {
    renderedHeight = imageHeight;
  }

  const scale = imageHeight / renderedHeight;

  const [pxCrop, setPxCrop] = useState<CropProps | undefined>(
    coordinates ? buildCoordinatesFromSaved(coordinates, offsets, scale) : useState<CropProps>()[0],
  );
  const [percentCrop, setPercentCrop] = useState<CropProps | undefined>();

  const setCrop = (newPixelCrop: PixelCrop, newPercentCrop: PercentCrop, dragIsComplete: boolean = false) => {
    setPxCrop(newPixelCrop);
    setPercentCrop(newPercentCrop);

    if (dragIsComplete) {
      const newScale = imageHeight / (imageRef.current?.clientHeight || renderedHeight);
      const coordinates = buildCoordinatesToSave(newPixelCrop, offsets, newScale);
      setData(
        'coordinates',
        Object.keys(coordinates)
          .map((key) => Math.round(coordinates[key]))
          .join(','),
      );

      setFocalPointMoved(true);
    }
  };

  useEffect(() => {
    // When the aspect ratio is locked, we need to update the crop height to match the aspect ratio
    let x;
    let y;
    let width;
    let height;

    const newScale = imageHeight / (imageRef.current?.clientHeight || renderedHeight);

    if (pxCrop) {
      if (focalPointMoved) {
        x = pxCrop.x;
        y = pxCrop.y;
        width = pxCrop.width;
        height = pxCrop.height;
      } else {
        x = Math.floor((Number(coordinates[0]) - offsets.x) / newScale);
        y = Math.floor((Number(coordinates[1]) - offsets.y) / newScale);
        width = Math.floor(Number(coordinates[2]) / newScale);
        height = Math.floor(Number(coordinates[3]) / newScale);
      }

      const imW = imageWidth / newScale;
      const inH = imageHeight / newScale;

      if (ARLock) {
        prevNewHeightRef.current = (width / Number(ARLock)) as number;
      }
      const newHeight = prevNewHeightRef.current || height;
      const newPxCrop: PixelCrop = {
        x,
        y,
        width,
        height: newHeight,
        unit: 'px',
      };

      const newPercentCrop = convertToPercentCrop(newPxCrop, imW, inH);
      setCrop(newPxCrop, newPercentCrop, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ARLock]);

  return (
    <div className={styles.image}>
      <ReactCrop
        crop={percentCrop || pxCrop}
        onChange={(newPixelCrop: PixelCrop, newPercentCrop: PercentCrop) => {
          setCrop(newPixelCrop, newPercentCrop);
        }}
        onComplete={(newPixelCrop: PixelCrop, newPercentCrop: PercentCrop) => {
          setCrop(newPixelCrop, newPercentCrop, true);
        }}
        minWidth={10}
        minHeight={10}
        ruleOfThirds
        aspect={ARLock ? Number(ARLock) : undefined}
      >
        <img
          data-testid="image-crop-region"
          ref={imageRef}
          src={localImageSrc}
          className={styles.focal_image}
          alt="Adjusting Image Coordinates"
        />
      </ReactCrop>
    </div>
  );
};

export default ImageCoordinates;
