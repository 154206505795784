import React from 'react';
import { Button, Group, IconCaretLeft, IconCaretRight, Typography } from '@screentone/core';

import { constants } from '../../utils';
import { useSearch } from '../../hooks/useSearch';

const { PAGE_SIZE } = constants;

/**
 * Buttons that handle paginating search results from Cloudinary
 */

const Pagination = () => {
  const { state, navigatePage } = useSearch();
  const { currentPage, pages } = state.pagination;
  const { resources, total_count: TOTAL_COUNT } = state?.searchResults;

  if (!resources?.length) return null;

  const imageCountEnd = (resources?.length || 0) + PAGE_SIZE * currentPage;
  const imageCountStart = currentPage * PAGE_SIZE + 1;
  return (
    <Group align="center" direction="column" gap="sm" margin={{ bottom: 'xl' }}>
      <Typography data-testid="pagination-txt">
        Showing {imageCountStart} - {imageCountEnd} out of {TOTAL_COUNT.toLocaleString('en-US') || 'unknown'} images
      </Typography>

      <Group align="center">
        <Button
          data-testid="prev-page-button"
          icon={IconCaretLeft}
          onClick={() => navigatePage('prev')}
          tertiary
          disabled={!currentPage}
        >
          Prev
        </Button>

        <Button
          data-testid="next-page-button"
          icon={IconCaretRight}
          iconPosition="right"
          onClick={() => navigatePage('next')}
          tertiary
          disabled={!pages[currentPage + 1]}
        >
          Next
        </Button>
      </Group>
    </Group>
  );
};

export default Pagination;
