import React from 'react';
import { Button, IconPlus, IconPlusCircle } from '@screentone/core';

import VariantButton, { generateItems, variantChildButtons } from '../Variant';

import { ImageType } from '../../../types';
import useConfig from '../../../hooks/useConfig';
import { createPublishedIdsObj } from '../../../utils/helpers';
import { iframe } from '../../../utils';

function PublishButton({image} : {image: ImageType}) {
  const {
    authFetch,
    session: { appUrl, property, env },
  } = useConfig();

  const publishedIdsObj = createPublishedIdsObj(image as ImageType, property, env);
  return (
    <VariantButton
      ButtonComponentEl={Button}
      size="sm"
      primary
      label={iframe.getSendToSourceLabel()}
      data-testid="addToArticle-btn"
      icon={IconPlus}
      items={generateItems({
        items: publishedIdsObj,
        iconTemplate: () => IconPlusCircle,
        onClickHandler: (e, { id }) => {
          iframe.sendToSource({ image: image, ids: [id as string], env, property, appUrl, authFetch });
        },
        childrenTemplate: variantChildButtons,
      })}
    />
  );
}

export default PublishButton;
