/**
 * EditMetadataSidebar Component
 *
 * Steps to add a new required field in EditMetadataSidebar:
 * 1. Add the field name to the FORM_VALIDATION array in the publisher config file.
 * 2. Assign the setField function to the key/field you want validation in the fieldFunctionList object.
 * 3. Make sure the key/field exist in fieldFunctionListType Interface, SettersRequiredObj type and settersRequiredObj object.
 *
 */

import { Typography, Group, useModalState } from '@screentone/core';
import React, { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { EditImageMetaFormFooter } from '../EditMetadataForm';
import UseUploadType from '../../hooks/useUpload/types';
import type { ImageType, GraphicType, PropertyType } from '../../types';
import { constants } from '../../utils';

import useImageDetail from '../../hooks/useImageDetail';
import { requiredFieldsForPublisher, validateObjectProperties, valuesInitializer } from '../../utils/helpers';
import { EditModalWires, EditWiresMetaDataForm } from './EditWireMetadataForm';
import { useConfig } from '../../hooks/useConfig';
import { CLOUDINARY_MAXIMUM_STRING_LENGTH } from '../../hooks/useUpload/validate';

interface EditMetadataSidebarProps {
  /** onCancel function */
  onCancel: () => void;
  /** set metadata to images */
  onApplyMetadata(encodedPublicId: string, metadata: Partial<ImageType>, currentPublishedId?: string): Promise<unknown>;
}
type SetterRequiredWires = {
  headline: (value: string) => void;
  caption: (value: string) => void;
  credit: (value: string) => void;
  datePhotographed: (value: string) => void;
  graphicType: (value: GraphicType) => void;
  specialInstructions: (value: string) => void;
  category: (value: string) => void;
  city: (value: string) => void;
  state: (value: string) => void;
  country: (value: string) => void;
  subCategories: (value: string) => void;
  region: (value: string) => void;
};

type FieldFunctionListType = {
  headline: string;
  caption: string;
  credit: string;
  datePhotographed: string;
  graphicType: GraphicType;
  specialInstructions: string;
  category: string;
  city: string;
  state: string;
  country: string;
  subCategories: string;
  region: string;
};

function EditWireMetadataSidebar({ onCancel, onApplyMetadata }: EditMetadataSidebarProps): JSX.Element {
  const { image } = useImageDetail();
  const { metadata, public_id: publicId } = image;

  const {
    session: { property },
  } = useConfig();
  const { open, openModal, closeModal } = useModalState();
  const [updatedCaption, setUpdatedCaption] = useState(metadata.caption || '');
  const [updatedCredit, setUpdatedCredit] = useState(metadata.credit || '');
  const [updatedHeadline, setUpdatedHeadline] = useState(metadata.headline || '');
  const [updatedDatePhotographed, setUpdatedDatePhotographed] = useState(metadata.date_photographed || '');
  const [updatedGraphicType, setUpdatedGraphicType] = useState(metadata.graphic_type as GraphicType);
  const [updatedSpecialInstructions, setUpdateSpecialInstructions] = useState(metadata.special_instructions || '');
  const [updatedCategory, setUpdateCategory] = useState(metadata.category || '');
  const [updatedCity, setUpdateCity] = useState(metadata.city || '');
  const [updatedState, setUpdateState] = useState(metadata.state || '');
  const [updatedCountry, setUpdateCountry] = useState(metadata.country || '');
  const [updatedRegion, setUpdateRegion] = useState(metadata.region || '');
  const [updatedSubCategories, setUpdateSubCategories] = useState(metadata.subcategories || '');
  const formValidationFields = constants.PROPERTIES.getFormValidationFields(property as PropertyType) || [];
  const initializeValidationFieldValues = valuesInitializer(formValidationFields);
  const [isSaving, setIsSaving] = useState(false);
  const [inputHasChanged, setInputHasChanged] = useState(false);
  const [validation, setValidation] = useState<Partial<UseUploadType.Validation.ValidFormFieldsType>>(
    initializeValidationFieldValues
  );

  const nonWiresFields: { [key: string]: string } = { slug: '1', contact: '1' };

  const refState = useRef<null | { [key: string]: string }>(null);
  const requiredFields = requiredFieldsForPublisher(property as PropertyType);

  const getFieldValues = () => ({
    updatedCaption,
    updatedCredit,
    updatedHeadline,
    updatedDatePhotographed,
    updatedGraphicType,
    updatedSpecialInstructions,
    updatedCategory,
    updatedCity,
    updatedState,
    updatedCountry,
    updatedRegion,
    updatedSubCategories,
  });
  const [initialValues] = useState(getFieldValues());

  const checkEqualToInitial = () => {
    return isEqual(initialValues, getFieldValues());
  };

  const settersRequiredObj: SetterRequiredWires = {
    headline: setUpdatedHeadline,
    caption: setUpdatedCaption,
    credit: setUpdatedCredit,
    datePhotographed: setUpdatedDatePhotographed,
    graphicType: setUpdatedGraphicType,
    specialInstructions: setUpdateSpecialInstructions,
    category: setUpdateCategory,
    city: setUpdateCity,
    state: setUpdateState,
    country: setUpdateCountry,
    subCategories: setUpdateSubCategories,
    region: setUpdateRegion,
  };

  const setField = <T extends keyof SetterRequiredWires>(fieldValue: string, fieldName: T) => {
    if (!fieldValue && requiredFields[fieldName]) {
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: `${fieldName} is required.`,
      }));
    } else if (fieldValue.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      const excessLength = fieldValue.length - CLOUDINARY_MAXIMUM_STRING_LENGTH;
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: `Caption exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}) by ${excessLength} characters.`,
      }));
    } else {
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: null,
      }));
    }
    settersRequiredObj[fieldName]?.(fieldValue as never);
  };
  const fieldFunctionList = {
    headline: setField,
    caption: setField,
    credit: setUpdatedCredit,
    datePhotographed: setUpdatedDatePhotographed,
    specialInstructions: setUpdateSpecialInstructions,
    category: setUpdateCategory,
    graphicType: setUpdatedGraphicType,
    city: setUpdateCity,
    state: setUpdateState,
    country: setUpdateCountry,
    subCategories: setUpdateSubCategories,
    region: setUpdateRegion,
  };

  const metadataWires: { [key: string]: string } = {
    caption: updatedCaption,
    credit: updatedCredit,
    datePhotographed: updatedDatePhotographed,
    graphicType: updatedGraphicType,
    headline: updatedHeadline,
    specialInstructions: updatedSpecialInstructions,
    category: updatedCategory,
    city: updatedCity,
    state: updatedState,
    country: updatedCountry,
    region: updatedRegion,
    subCategories: updatedSubCategories,
  };
  // Updating validation and button flags when the Edit Metadata sidebar is opened
  useEffect(() => {
    refState.current = metadataWires;
    // Initialize validation field values
    setValidation(initializeValidationFieldValues);
    // get all required field and run setField function for each one
    Object.keys(requiredFields).forEach((field) => {
      const fieldValue = metadataWires[field];
      setField(fieldValue as never, field as keyof FieldFunctionListType);
    });
    setIsSaving(false);
  }, []);

  // useEffect to update specific functions in fieldFunctionList based on requiredFields
  useEffect(() => {
    // Iterate through keys of requiredFields
    Object.keys(requiredFields).forEach((field) => {
      // Cast the field to the keyof typeof fieldFunctionList
      const fieldName = field as keyof typeof fieldFunctionList;
      // Check if the corresponding function in fieldFunctionList exists
      if (fieldFunctionList[fieldName]) {
        // Update the function to be setField
        fieldFunctionList[fieldName] = setField as () => void;
      }
    });
  }, [fieldFunctionList, requiredFields, setField]);

  useEffect(() => {
    const stateHasChanged = Object.entries(metadataWires).some(([key, value]) => value !== refState.current?.[key]);
    setInputHasChanged(stateHasChanged);
  }, [metadataWires]);

  const openApplyModal = () => {
    setIsSaving(true);
    Object.keys(requiredFields).forEach((field) => {
      if (!metadataWires[field]) {
        setValidation((prevState: any) => ({
          ...prevState,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
        }));
        setIsSaving(false);
      }
    });

    openModal();
  };

  const validRequiredFields = () => {
    for (const value of formValidationFields) {
      if (!metadataWires[value] && !nonWiresFields[value as string]) {
        return false;
      }
    }
    return true;
  };

  const resetFormFields = (resetMetadata: any) => {
    // reset state to original
    setIsSaving(false);
    setUpdatedCaption(resetMetadata.caption || '');
    setUpdatedCredit(resetMetadata.credit || '');
    setUpdatedHeadline(resetMetadata.headline || '');
    setUpdatedDatePhotographed(resetMetadata.date_photographed || resetMetadata.datePhotographed || '');
    setUpdatedGraphicType((resetMetadata.graphic_type || resetMetadata.graphicType) as GraphicType);
    setUpdateRegion(resetMetadata.region || '');
    setUpdateSpecialInstructions(resetMetadata.special_instructions || resetMetadata.specialInstructions || '');
    setUpdateCategory(resetMetadata.category || '');
    setUpdateCity(resetMetadata.city || '');
    setUpdateState(resetMetadata.state || '');
    setUpdateCountry(resetMetadata.country || '');
    setUpdateSubCategories(resetMetadata.subcategories || resetMetadata.subCategories || '');
    setValidation(initializeValidationFieldValues);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trimmedMetadataWires: { [key: string]: string } = {};
    Object.entries(metadataWires).forEach(([key, value]) => {
      trimmedMetadataWires[key] = value.trim();
    });
    closeModal();

    onApplyMetadata(publicId, trimmedMetadataWires).finally(() => {
      resetFormFields(trimmedMetadataWires);
      setIsSaving(false);
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const targetValue: Record<string, string> = { [id]: value };
    const keys: any = Object.keys(targetValue);
    // Retrieves the first key (assuming there is at least one) from the keys array.
    const field: keyof FieldFunctionListType = keys[0] || '';

    // Checks if there is a corresponding function for the field in the fieldFunctionList.
    if (fieldFunctionList[field]) {
      // Invokes the function associated with the field, passing the field's new value and the field itself.
      fieldFunctionList[field](targetValue[field] as never, field);
    }
  };

  const setOnCancel = () => {
    resetFormFields(metadata);
    onCancel();
  };

  const disableEditField = { contact: true, slug: true };
  return (
    <>
      <Typography data-testid="edit-metadata-title" variant="label4" margin={{ top: 'sm', horizontal: 'md' }}>
        Update Source Metadata
      </Typography>

      <Group direction="column" gap="sm" margin={{ horizontal: 'md' }}>
        <EditWiresMetaDataForm
          disableEditField={disableEditField}
          caption={updatedCaption}
          credit={updatedCredit}
          headline={updatedHeadline}
          graphicType={updatedGraphicType as GraphicType}
          datePhotographed={updatedDatePhotographed}
          specialInstructions={updatedSpecialInstructions}
          category={updatedCategory}
          city={updatedCity}
          state={updatedState}
          country={updatedCountry}
          subCategories={updatedSubCategories}
          region={updatedRegion}
          onChange={onChange}
          validation={validation}
        />
      </Group>

      <EditModalWires isOpen={open} onDismiss={closeModal} onSubmit={(e) => handleSubmit(e)} />
      <EditImageMetaFormFooter
        disableApplyButton={
          isSaving ||
          checkEqualToInitial() ||
          !!validateObjectProperties(validation, disableEditField) ||
          !validRequiredFields() ||
          !inputHasChanged
        }
        onApplyMetadata={openApplyModal}
        onCancel={setOnCancel}
      />
    </>
  );
}

export default EditWireMetadataSidebar;
