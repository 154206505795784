const styles: any = {
  default: {
    card: {
      boxShadow: 'none',
      maxWidth: '250px',
      width: '100%',
    },
    body: {
      background: 'var(--st-page-bg)',
      boxShadow: 'none',
      border: 'var(--st-border-width) solid var(--st-border-color)',
      borderTop: 'none',
    },
    input: {
      appearance: 'none',
      background: 'var(--st-input-bg)',
      border: 'var(--st-border-width) solid var(--st-border-color)',
      borderRadius: 'var(--st-border-radius)',
      boxShadow: 'none',
      boxSizing: 'border-box',
      color: 'var(--st-text-color)',
      fontFamily: 'var(--st-font-stack)',
      fontSize: 'var(--st-font-size-md)',
      height: 'var(--st-btn-height)',
      lineHeight: 'var(--st-font-line-height-md)',
      padding: 'var(--st-spacer-sm)',
      width: '100%',
      textTransform: 'uppercase',
    },
    label: {
      color: 'var(--st-text-color)',
      fontFamily: 'var(--st-font-stack)',
      fontSize: 'var(--st-font-size-md)',
      textTransform: 'uppercase',
      textShadow: '0px 0px 10px var(--st-content-bg)',
    },
    head: {
      border: 'var(--st-border-width) solid var(--st-border-color)',
    },
  },
};

export default styles;
