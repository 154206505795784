import React from 'react';
import { Button, Dialog, useModalPortal, Typography } from '@screentone/core';

type DetailsProps = {
  /** on submit of dialog */
  onSubmit: (e: any) => void;
  /** on dismiss of Dialog */
  onDismiss: () => void;
  /** whether dialog is open */
  isOpen: boolean;
};

function EditWiresMetadataModal({ isOpen, onDismiss, onSubmit }: DetailsProps) {
  const { renderNode } = useModalPortal();
  return (
    <Dialog
      data-testid="custom-dialog-component"
      renderNode={renderNode}
      aria-labelledby="customSizeTitle"
      aria-describedby="customSizeDesc"
      status={isOpen ? 'open' : 'closed'}
      onDismiss={onDismiss}
      componentEl="form"
      onSubmit={onSubmit}
      size="fluid"
    >
      <Dialog.Title id="customSizeTitle">Updating Source Metadata</Dialog.Title>
      <Dialog.Content id="dialogDesc">
        <Typography>
          Updating the source metadata of a Wires Image changes the metadata for{' '}
          <Typography weight="bold" inline>
            <em>all brands.</em>
          </Typography>
        </Typography>
        <Typography weight="bold" margin={{ top: 'md' }}>
          Are you sure you want to apply these changes?
        </Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button secondary type="reset" data-testid="custom-cancel-button" onClick={onDismiss}>
          Cancel
        </Button>
        <Button primary type="submit" data-testid="custom-preview-button">
          Apply
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export default EditWiresMetadataModal;
