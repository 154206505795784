import actions from './actions';
import UseSearchType from './types';

/**
 * each image represents the data required to render an image prior to uploading it to Cloudinary
 */

export const renderDefaultState: UseSearchType.Render.State = [];

const renderReducer = (
  state: UseSearchType.Render.State,
  action: UseSearchType.Render.Action
): UseSearchType.Render.State => {
  
  switch (action.type) {
    /** add images */
    case actions.RENDER.ADD_IMAGES: {
      return [...state, ...action.payload];
    }

    case actions.RENDER.UPDATE_IMAGE_DATA: {
      const { index, width, height } = action.payload;

      const currentState = [...state];
      currentState[index].width = width;
      currentState[index].height = height;

      return currentState;
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default renderReducer;
