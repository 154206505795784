import React, { useEffect, useState } from 'react';
import { Button, Group, IconFocalPoint, FormLabel, Switch, IconTrash } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';
import useImageDetail from '../../../../hooks/useImageDetail';

const ThumbnailFocalArea = ({ panelKey }: { panelKey: string }) => {
  const { contentComponent, setContentComponent, setData, getData } = useEditImage();
  const { localContext } = useImageDetail();
  const [thumbnailState, setThumbnailState] = useState(getData('disable', panelKey));

  const isActive = contentComponent === panelKey;
  const [hasFocalArea, setHasFocalArea] = useState(!!getData('coordinates', panelKey));

  useEffect(() => {
    setHasFocalArea(!!getData('coordinates', panelKey));
    setThumbnailState(getData('disable', panelKey));
  }, [contentComponent, localContext]);

  return (
    <>
      <FormLabel data-testid="thumb-crop" label="Enable Thumbnail Zoom" labelPosition="right" margin={{ right: 'sm' }}>
        <Switch
          checked={!thumbnailState}
          disabled={false}
          onChange={() => {
            setThumbnailState(!thumbnailState);
            setData('disable', !thumbnailState, panelKey);
          }}
        />
      </FormLabel>

      {!thumbnailState && (
        <>
          <Group gap="sm">
            <Button
              data-testid="add-focal-area-thumbnails"
              tertiary
              icon={IconFocalPoint}
              active={isActive}
              onClick={() => {
                setContentComponent(isActive ? false : panelKey);
              }}
            >
              {hasFocalArea ? 'Edit' : 'Add'} Focal Area for Thumbnails
            </Button>
            {hasFocalArea && (
              <Button
                tertiary
                icon={IconTrash}
                color="lava"
                onClick={() => {
                  setData('coordinates', null, panelKey);
                  setHasFocalArea(false);
                }}
              />
            )}
          </Group>
        </>
      )}
    </>
  );
};

export default ThumbnailFocalArea;
