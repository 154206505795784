import React, { useEffect, useState } from 'react';
import { Button, Group, IconTrash, IconFocalPoint } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';
import { PropertyType } from '../../../../types';
import { getTransformationType } from '../../../../utils/helpers';
import useConfig from '../../../../hooks/useConfig';
import useImageDetail from '../../../../hooks/useImageDetail';
const FocalArea = ({ panelKey }: { panelKey: string }) => {
  const {
    session: { property },
  } = useConfig();
  const { image, publishedId, contentComponent, getData, setData, setContentComponent } = useEditImage();
  const { localContext } = useImageDetail();
  const isActive = contentComponent === panelKey;
  const resizeOnly = getTransformationType(image, property, publishedId as PropertyType) === 'resize' || false;
  const [hasFocalArea, setHasFocalArea] = useState(getData('coordinates', panelKey));

  useEffect(() => {
    setHasFocalArea(getData('coordinates', panelKey));
  }, [contentComponent, localContext]);

  return (
    <Group gap="sm">
      <Button
        data-testid="add-focal-area-btn"
        tertiary
        icon={IconFocalPoint}
        active={isActive}
        onClick={() => {
          setContentComponent(isActive ? false : panelKey);
        }}
        disabled={resizeOnly}
      >
        {hasFocalArea ? 'Edit' : 'Add'} Focal Area
      </Button>
      {hasFocalArea && (
        <Button
          tertiary
          icon={IconTrash}
          color="lava"
          onClick={() => {
            setData('coordinates', null, panelKey);
            setHasFocalArea(false);
          }}
        />
      )}
    </Group>
  );
};

export default FocalArea;
