import { helpers } from '../../utils';
import actions from './actions';
import { uploadFilesDefaultState } from './uploadFilesReducer';
import { renderDefaultState } from './renderReducer';
import { uploadBulkMetadataDefaultState } from './uploadBulkMetadataReducer';
import { uploadMetadataDefaultState } from './uploadMetadataReducer';
import { uploadNavigationDefaultState } from './uploadNavigationReducer';
import { uploadDefaultState } from './uploadReducer';
import { validationDefaultState } from './validationReducer';
import UseSearchType from './types';

/**
 * Combination default state of all pieces of state for useUpload
 */
export const defaultUploadState = {
  bulk: uploadBulkMetadataDefaultState,
  files: uploadFilesDefaultState,
  render: renderDefaultState,
  metadata: uploadMetadataDefaultState,
  navigation: uploadNavigationDefaultState,
  upload: uploadDefaultState,
  validation: validationDefaultState,
};

const allReducer = (
  state: UseSearchType.Merged.State,
  action: UseSearchType.All.Action
): UseSearchType.Merged.State => {
  switch (action.type) {
    /** delete a particular image from the uploader */
    case actions.ALL.REMOVE_IMAGES: {
      const { selected } = action.payload;

      return {
        ...state,
        bulk: { ...state.bulk, selected: [] },
        files: { ...state.files, accepted: helpers.removeElementsFromArray(state.files.accepted, selected) },
        navigation: selected.length === state.files.accepted.length ? 0 : state.navigation,
        render: helpers.removeElementsFromArray(state.render, selected),
        metadata: helpers.removeElementsFromArray(state.metadata, selected),
        validation: helpers.removeElementsFromArray(state.validation, selected),
      };
    }

    case actions.ALL.RESET_UPLOAD: {
      return defaultUploadState;
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default allReducer;
