import { useRef, useState, useCallback, useLayoutEffect, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

/*
Hook to gret Y positions for various elements on the upload page
returns "top" position for the Upload Navbar
and the "top" position for the Bulk Edit Metadata component

navElem is a ref that should go in an empty div that sits right above where the nav component would appear
sidebarElem is a ref that should go in an empty div that sits right above where the sidebar should appear

This is because both of these components have a position: fixed/sticky/absolute
which means that calculating their position becomes impossible/difficult
and we are using these empty divs as proxies to figure out where 
the real components "should" appear
*/
function useUploadPositions() {

  const sidebarElem = useRef(null);
  const [sidebarY, setSidebarY] = useState(0);

  const getYPosition = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;

    // unfortunately there is no way to pass a ref to the header inside auth wrapper
    // so unfortunately this is the best way to get its position
    const headerHTMLElem = document.querySelector('.st_sticky-top') as HTMLElement;
    let headerRect;

    if (headerHTMLElem instanceof HTMLElement) {
      headerRect = headerHTMLElem.getBoundingClientRect();
    }

    const sidebarHTMLElem = sidebarElem.current ? (sidebarElem.current as HTMLElement) : null;
    if (sidebarHTMLElem instanceof HTMLElement) {
      const sidebarRect = sidebarHTMLElem.getBoundingClientRect();
      setSidebarY(sidebarRect.top + scrollTop - (headerRect?.top || 0));
    }
  }, []);

  const debouncedGetYPosition = useMemo(
    () =>
      debounce(() => {
        getYPosition();
      }, 500),
    [getYPosition]
  );

  useLayoutEffect(() => {
    getYPosition();
  }, [getYPosition]);

  useEffect(() => {
    window.addEventListener('resize', debouncedGetYPosition);
    return () => window.removeEventListener('resize', debouncedGetYPosition);
  }, [debouncedGetYPosition]);

  return {
    sidebarElem,
    sidebarY,
  };
}

export default useUploadPositions;
