export const buildCoordinatesFromSaved = (coordinates: number[], offsets: any, scale: any) => {
  return {
    x: (Number(coordinates[0]) - offsets.x) / ((scale * 100) / 100),
    y: (Number(coordinates[1]) - offsets.y) / ((scale * 100) / 100),
    width: Number(coordinates[2]) / ((scale * 100) / 100),
    height: Number(coordinates[3]) / ((scale * 100) / 100),
    unit: 'px' as 'px' | '%',
  };
};

export const buildCoordinatesToSave = (crop: any, offsets: any, scale: number) => {
  return {
    x: Number(crop.x * scale + offsets.x),
    y: Number(crop.y * scale + offsets.y),
    width: Number(crop.width * scale),
    height: Number(crop.height * scale),
    centerX: Number(crop.x * scale + offsets.x + (crop.width * scale) / 2),
    centerY: Number(crop.y * scale + offsets.y + (crop.height * scale) / 2),
  };
};
