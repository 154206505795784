import actions from './actions';
import UseUploadType from './types';
import validate from './validate';
import validateBulk from './validateBulk';

/**
 * Apply the bulk edit form to the metadata on state
 */
const applyBulkFormToMetadata = (
  selected: number[],
  metadata: UseUploadType.Metadata.Metadata[],
  bulkForm: UseUploadType.BulkMetadata.BulkMetadata,
  selectedMetadata: UseUploadType.BulkMetadata.BulkMetadata
): UseUploadType.Metadata.State => {
  const updatedMetadata: UseUploadType.Metadata.State = [];

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0, j = 0; i < metadata.length; i++) {
    // update selected metadata
    if (selected[j] === i) {
      updatedMetadata.push(
        Object.keys(bulkForm).reduce((updated, fieldName) => {
          if (
            bulkForm[fieldName as keyof UseUploadType.BulkMetadata.BulkMetadata] ||
            selectedMetadata[fieldName as keyof UseUploadType.BulkMetadata.BulkMetadata]
          ) {
            return {
              ...updated,
              [fieldName]: bulkForm[fieldName as keyof UseUploadType.BulkMetadata.BulkMetadata],
            };
          }

          return updated;
        }, metadata[i] as UseUploadType.Metadata.Metadata)
      );

      // move pointer to next selected
      j++; /* eslint-disable-line no-plusplus */

      // image is not selected
    } else {
      updatedMetadata.push(metadata[i]);
    }
  }

  return updatedMetadata as UseUploadType.Metadata.State;
};

const defaultMetadata: UseUploadType.BulkMetadata.BulkMetadata = {
  slug: '',
  caption: '',
  contact: '',
  credit: '',
  datePhotographed: '',
  graphicType: '',
  headline: '',
  oneTimeUse: false,
  resizeOnly: false,
  background: '',
  uncredited: false,
};

const defaultValidation: UseUploadType.BulkMetadata.Validation = {
  caption: null,
  contact: null,
  credit: null,
  datePhotographed: null,
  headline: null,
};

export const uploadBulkMetadataDefaultState: UseUploadType.BulkMetadata.State = {
  metadata: defaultMetadata,
  selected: [],
  selectedMetadata: defaultMetadata,
  validation: defaultValidation
};

/**
 * Get the state for `selectedMetadata` by going through all pieces of metadata and clearing all
 * pieces of state that aren't identical
 */
const getSelectedMetadata = (
  selected: number[],
  metadata: UseUploadType.Metadata.State
): UseUploadType.BulkMetadata.BulkMetadata => {
  const filteredMetadata = metadata.filter((_, i) => selected.includes(i));
  // if we don't have any selected images, return the default state
  if (!filteredMetadata.length) return defaultMetadata;

  return Object.keys(defaultMetadata).reduce(
    (total, fieldName) => ({
      ...total,
      // if every [fieldName] in fileteredMetadata is not the same, clear it
      [fieldName]: filteredMetadata.reduce((fieldValue, currentMetadata) => {
        const currentFieldValue = currentMetadata[fieldName as keyof UseUploadType.BulkMetadata.BulkMetadata];

        // clear field if its not the same as the others
        if (currentFieldValue !== fieldValue) return typeof currentFieldValue === 'string' ? '' : false;

        return currentFieldValue;
      }, filteredMetadata[0][fieldName as keyof UseUploadType.BulkMetadata.BulkMetadata]),
    }),
    {} as UseUploadType.BulkMetadata.BulkMetadata
  );
};

const uploadBulkMetadataReducer = (
  state: UseUploadType.Merged.State,
  action: UseUploadType.BulkMetadata.Action
): Partial<UseUploadType.Merged.State> => {
  switch (action.type) {
    /** add metadata for uploads */
    case actions.BULK_METADATA.ADD_IMAGE: {
      const selected = [...state.bulk.selected, action.payload.index].sort();
      const selectedMetadata = getSelectedMetadata(selected, state.metadata);

      return {
        bulk: {
          ...state.bulk,
          selected,
          metadata: selectedMetadata,
          selectedMetadata,
        },
      };
    }

    /** add metadata for uploads */
    case actions.BULK_METADATA.ADD_ALL: {
      const selected = state.metadata.map((_, i) => i);
      const selectedMetadata = getSelectedMetadata(selected, state.metadata);
      return {
        bulk: {
          ...state.bulk,
          selected,
          metadata: selectedMetadata,
          selectedMetadata,
          validation: validateBulk.all(selectedMetadata)
        },
      };
    }

    /** update metadata for bulk edit form  */
    case actions.BULK_METADATA.EDIT: {
      return {
        bulk: {
          ...state.bulk,
          metadata: {
            ...state.bulk.metadata,
            ...action.payload.metadata,
          },
        },
      };
    }

    /** remove metadata for uploads */
    case actions.BULK_METADATA.REMOVE_IMAGE: {
      const selected = state.bulk.selected.filter((imageIndex) => imageIndex !== action.payload.index);
      const selectedMetadata = getSelectedMetadata(selected, state.metadata);

      return {
        bulk: {
          ...state.bulk,
          selected,
          metadata: selectedMetadata,
          selectedMetadata,
        },
      };
    }

    /** add metadata for uploads */
    case actions.BULK_METADATA.REMOVE_ALL: {
      return {
        bulk: {
          ...state.bulk,
          selected: [],
          selectedMetadata: defaultMetadata,
        },
      };
    }

    /**
     * when opening the bulk edit form, update selectedMetadata and metadata with the fields that
     * may have changed
     */
    case actions.BULK_METADATA.REFRESH_BULK_EDIT_FORM: {
      const selectedMetadata = getSelectedMetadata(state.bulk.selected, state.metadata);

      return {
        bulk: {
          ...state.bulk,
          metadata: selectedMetadata,
          selectedMetadata,
        },
      };
    }

    /** remove metadata for uploads */
    case actions.BULK_METADATA.APPLY_METADATA: {
      const metadata = applyBulkFormToMetadata(
        state.bulk.selected,
        state.metadata,
        state.bulk.metadata,
        state.bulk.selectedMetadata
      );

      return {
        metadata,
        validation: metadata.map((m, i) => {
          // image was selected for bulk metadata
          if (state.bulk.selected.includes(i)) return validate.all(m);

          // image not selected - don't validate
          return state.validation[i];
        }),
      };
    }

    case actions.BULK_METADATA.VALIDATION: {
      return { 
        bulk: {
          ...state.bulk,
          validation: validateBulk.all(action.payload.metadata)
        } 
      };
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default uploadBulkMetadataReducer;
