import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Group,
  Grid,
  Textarea,
  Typography,
  IconFormFeedback,
  IconCheckCircle,
  IconError,
} from '@screentone/core';
import { ErrorMessage, LoadingPage, useAuth } from '@screentone/addon-auth-wrapper';

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import useConfig from '../../hooks/useConfig';
import { helpers } from '../../utils';

import type { ImageType } from '../../types';

type CompareImageQualityTypes = {
  /** object representing image's data */
  image: ImageType;
  publishedId?: string;
};

function CompareImageQualityDialogContent({ image: prodImage, publishedId }: CompareImageQualityTypes) {
  const [devImage, setDevImage] = useState<null | ImageType>(null);
  const [prodDomain, setProdDomain] = useState<null | string>(null);
  const [devDomain, setDevDomain] = useState<null | string>(null);

  const [loadingError, setLoadingError] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);

  const {
    authFetch,
    session: { env, property },
  } = useConfig();

  const { userAccess } = useAuth();
  const isAdminOrDeveloper = userAccess(['app_admin', 'developer']);

  useEffect(() => {
    // console.log('useEffect: ');
    if (!devImage) {
      // console.log('authFetch: ');
      authFetch(`/api/services/compare/:property/${prodImage.asset_id}`)
        .then((res) => {
          // console.log('res: ', res);
          setDevImage(res.response.devImage);
          setProdDomain(res.response.prodDomain);
          setDevDomain(res.response.devDomain);
        })
        .catch((err) => {
          console.error('err: ', err);
          setLoadingError(true);
        });
    }
  }, []);

  if (!loadingError) {
    <Alert icon={IconError} type="error" margin={{ bottom: 'md' }}>
      <ErrorMessage message={'There was an error sending your feedback. '} />
    </Alert>;
  }
  if (!devImage) {
    return <LoadingPage />;
  }

  const fnSelectsOptions = {
    image: prodImage,
    width: 944,
    host: helpers.getMD5Domain(env, property),
    publishedId,
    pixel_ratio: window?.devicePixelRatio || 1,
  };

  const cldFnSelects = helpers.getCldTransformationImage(fnSelectsOptions);

  const devUrl = `${devDomain}image/upload/${cldFnSelects.transformation}/${devImage.public_id}`;
  const prodUrl = `${prodDomain}image/upload/${cldFnSelects.transformation}/${prodImage.public_id}`;

  const sendFeedback = async (improved: boolean, feedback?: string) => {
    // console.log('sendFeedback: ', improved, feedback);
    // console.log('window.screen: ', window.screen);
    const screenDetails = await window.getScreenDetails();
    // console.log('screenDetails: ', screenDetails);
    const body = {
      improved,
      feedback,
      devUrl,
      prodUrl,
      dpr: window?.devicePixelRatio,
      screen: screenDetails.screens.map((screen) => ({
        availHeight: screen.availHeight,
        availLeft: screen.availLeft,
        availTop: screen.availTop,
        availWidth: screen.availWidth,
        colorDepth: screen.colorDepth,
        devicePixelRatio: screen.devicePixelRatio,
        height: screen.height,
        isExtended: screen.isExtended,
        isInternal: screen.isInternal,
        isPrimary: screen.isPrimary,
        label: screen.label,
        orientation: screen.orientation.type,
        pixelDepth: screen.pixelDepth,
        width: screen.width,
      })),
      currentScreen: screenDetails.currentScreen.label,
    };
    // console.log('body: ', body);
    setSendingFeedback(true);
    authFetch(`/api/services/compare/:property/${prodImage.asset_id}/feedback`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then((res) => {
        // console.log('res: ', res);
        setShowTextArea(false);
        setFeedbackSent(true);
      })
      .catch((err) => {
        console.error('err: ', err);
        setFeedbackError(true);
      });
  };

  return (
    <>
      {!feedbackSent && !feedbackError && (
        <Alert icon={IconFormFeedback} margin={{ bottom: 'md' }}>
          <Group gap="sm" fullWidth>
            <Group gap="sm" fullWidth>
              Does the new algorithm look better?
              <Button
                secondary
                size="sm"
                onClick={() => {
                  sendFeedback(true);
                  setShowTextArea(false);
                }}
                disabled={sendingFeedback}
              >
                Yes
              </Button>
              <Button secondary size="sm" onClick={() => setShowTextArea(true)} disabled={sendingFeedback}>
                No
              </Button>
            </Group>
            {showTextArea && (
              <Group gap="sm" fullWidth>
                <Textarea
                  fullWidth
                  value={feedbackText}
                  onChange={(event: Event) => setFeedbackText((event.target as HTMLInputElement).value)}
                  type="text"
                  name="name"
                  placeholder="Please add a comment"
                  disabled={sendingFeedback}
                ></Textarea>
                <Button
                  secondary
                  size="sm"
                  onClick={() => sendFeedback(false, feedbackText)}
                  disabled={sendingFeedback}
                >
                  Submit Feedback
                </Button>
              </Group>
            )}
          </Group>
        </Alert>
      )}
      {feedbackSent && (
        <Alert icon={IconCheckCircle} type="success" margin={{ bottom: 'md' }}>
          Feedback sent successfully Thank you for your feedback
        </Alert>
      )}
      {feedbackError && (
        <Alert icon={IconError} type="error" margin={{ bottom: 'md' }}>
          <ErrorMessage message={'There was an error sending your feedback. '} />
        </Alert>
      )}
      <Grid style={{ maxWidth: '944px' }}>
        <Grid.Row>
          <Grid.Col xs={6}>
            <Typography variant="header4" align="left">
              Old algorithm
            </Typography>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Typography variant="header4" align="right">
              New algorithm
            </Typography>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} style={{ aspectRatio: prodImage.aspect_ratio }}>
            <ReactCompareSlider
              itemOne={<ReactCompareSliderImage src={prodUrl} alt="New algorithm" />}
              itemTwo={<ReactCompareSliderImage src={devUrl} alt="Old algorithm" />}
            />
          </Grid.Col>
        </Grid.Row>
        {isAdminOrDeveloper && (
          <Grid.Row>
            <Grid.Col xs={12}>
              <Typography variant="note">
                Develop Url: {devUrl}
                <br />
                Production Url: {prodUrl}
              </Typography>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
}

export default CompareImageQualityDialogContent;
