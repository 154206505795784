// component that copies to clipboard and then displays a "Copied!" message
import React from 'react';

import { Button, Group, Tooltip } from '@screentone/core';
import styles from './CopyToClipboard.module.css';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';

type CopyToClipboardProps = {
  /** text to be copied */
  text: string;
  /** text to display in button */
  children: React.ReactNode;
  /** type of button */
  buttonType?: string;
  /** other props for button component */
  buttonProps?: any;
  /** focal point enabled */
  disabled?: boolean;
  /** component element */
  groupComponentEl?: string | HTMLElement;
  /** on click handler */
  onClick?: (e: Event) => void;
  /** button props */
  [key: string]: any;
};

function CopyToClipboard({
  text,
  children,
  disabled,
  groupComponentEl,
  onClick,
  ...buttonProps
}: CopyToClipboardProps) {
  const [value, copy, supported, error] = useCopyToClipboard();
  if (!('primary' in buttonProps) || !('secondary' in buttonProps) || !('tertiary' in buttonProps)) {
    buttonProps.tertiary = true;
  }
  return (
    <Group gap="none" componentEl={groupComponentEl} fullWidth={buttonProps.fullWidth}>
      {supported ? (
        <Button
          data-testid="copy-to-clipboard"
          onClick={(e: Event) => {
            e.preventDefault();
            copy(text);
            onClick && onClick(e);
          }}
          {...buttonProps}
          disabled={disabled}
        >
          {children}
        </Button>
      ) : (
        <Tooltip>
          <Tooltip.Content position="bottom" id="tooltipContent">
            Clipboard not supported!
          </Tooltip.Content>
          <Tooltip.Trigger aria-describedby="tooltipContent">
            <Button data-testid="copy-to-clipboard" {...buttonProps} disabled>
              {children}
            </Button>
          </Tooltip.Trigger>
        </Tooltip>
      )}
      {value && (
        <span className={styles.position}>
          <span role="alert" className={styles.message} data-testid="copied" data-copied-value={value}>
            Copied!
          </span>
        </span>
      )}

      {error && (
        <span className={styles.position}>
          <span role="alert" className={styles.message} style={{ backgroundColor: 'var(--st-input-color-error)' }}>
            {error}
          </span>
        </span>
      )}
    </Group>
  );
}

export default CopyToClipboard;
