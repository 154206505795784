import React from 'react';
import { Button, IconMinusCircle } from '@screentone/core';

/** add to lightbox button */
function ButtonRemoveFromLightbox(props: any) {
  return (
    <Button tertiary icon={IconMinusCircle} {...props}>
      Remove from Lightbox
    </Button>
  );
}

export default ButtonRemoveFromLightbox;
