import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">Snippety Integration</Typography>
      <MarkdownBlock
        md={`
## Overview

Snippety is a straightforward internal API service designed for extracting key metadata from a URL, 
a process also known as 'unfurling'. By inputting a link, you can retrieve valuable information such as the title, description, media, and more.
Snippety's has been enhanced to provide additional information about some of our internal assets, including Image Manager URLs. 

## Usage

To utilize Snippety, encode an Image Manager Image URL and pass it as a URL parameter to the Snippety server. Include the \`Bearer\` token in the \`Authorization\` header.

## Note

While Snippety supports passing the tool URL, this approach is not recommended as there can be multiple Image Manager IDs (IM-IDs) per image.
Requesting the tool URL will return the last published IM-ID or a 404 error if the image has not been published.

## Endpoints

Snippety provides both a development and production URL. The development endpoint should be used for all non-production environments.

      
**Snippety prod endpoint:**
\`https://snippety.djnews.tools/v1/snippet?url=\${encodeURIComponent(imageUrl)}\`

**Snippety dev endpoint:**
\`https://dev-snippety.djnews.tools/v1/snippet?url=\${encodeURIComponent(imageUrl)}\`
`}
      />
    </>
  );
}

export default DeveloperDocs;
