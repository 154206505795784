import React, { useEffect } from 'react';
import { Helmet as Head } from 'react-helmet';
import { redirect, useLocation, useNavigate } from 'react-router-dom';

import UploadLayout from '../../../../layouts/Upload';
import { UploadProvider } from '../../../../hooks/useUpload';
import { handleDragAndDrop } from '../../../../utils/helpers';
import useConfig from '../../../../hooks/useConfig';

// /[property]/upload
const UploadPage = () => {
  const {
    session: { property },
  } = useConfig();
  const navigate = useNavigate();

  const router = {
    redirect: (url: string) => {
      redirect(url);
    },
    navigate: (url: string) => {
      navigate(url);
    },
  };

  // navigate to upload page if the user drags in an image for upload
  useEffect(() => {
    // speed up rerouting to upload page **ONLY WORKS IN PRODUCTION**
    if(!!property && !!router ) {
      handleDragAndDrop(property, router, true);
    }
    
  }, [property, router]);

  return (
    <>
      <Head>
        <title>Images - Upload</title>
      </Head>
      <UploadProvider type="dynamic">
        <UploadLayout />
      </UploadProvider>
    </>
  );
};

export default UploadPage;
