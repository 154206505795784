import { IconAward, IconStar, IconNewspaper } from '@screentone/core';
import { parse } from '@screentone/addon-calendar';
import round from 'lodash/round';
import pickBy from 'lodash/pickBy';
import { v4 as uuidv4 } from 'uuid';
import * as regex from './regex';
import * as constants from './constants';
import type {
  ImageType,
  ImageBaseType,
  PropertyType,
  EnvironmentType,
  PropertyTypeConfig,
  TransformationType,
  UploadImagePlaceholderType,
  ImageStructuredMetadataKeys,
  UploadApiResponse,
  SearchOptionsType,
} from '../types';
import { contextualMetadata, structuredMetadata } from '../config/uploadMetadataValidation';
import { fnSelectRenderer } from './fnSelect';
import type { DynamicSearchOptionsType } from '../types/search';
import { GRAPHIC_TYPES_OPTIONS_CONFIG, MAX_CLOUDINARY_COLORS, DEFAULT_IMAGE_SET_LABEL, PROPERTY_LABELS } from './constants';

export const roundRatio = (ratio: number) => {
  return round(ratio, 2);
};

/**
 * When building a cloudinary metadata expression we need to escape pipes | in the text
 * because this char is used as a separator between every field
 */
export const sanitizedValue = (value: string, type: string) => {
  if (type === 'boolean' && ['true', 'false'].includes(value)) {
    return value === 'true';
  }
  if (typeof value !== 'string') {
    return value;
  }
  return value.replace(new RegExp(regex.escapedPipe, 'g'), '|').replace(new RegExp(regex.pipe, 'g'), '\\|');
};

/**
 * Given an array, a value and an index, replace the element in the array at the given index with
 * the provided value.
 */
export const replaceElementInArray = (array: any[], value: any, index: number) => [
  ...array.slice(0, index),
  value,
  ...array.slice(index + 1),
];

/**
 * Given an array and an index, remove the element in the array at the given index
 */
export const removeElementsFromArray = (array: any[], indexesToRemove: number[]) => {
  const sortedIndexes = indexesToRemove.sort((a, b) => a - b);
  const filtered = [];

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0, j = 0; i < array.length; i++) {
    if (i !== sortedIndexes[j]) filtered.push(array[i]);
    else j++; /* eslint-disable-line no-plusplus */
  }

  return filtered;
};

interface UploadApiResponseImage extends UploadApiResponse {
  metadata: {
    published_id?: string;
    [futureKey: string]: any;
  };
  context: {
    custom?: {
      [futureKey: string]: any;
    };
    [futureKey: string]: any;
  };
}

export const getAllPublishedIds = (image: ImageType | UploadApiResponseImage, property: PropertyType) => {
  let publishedIdArray: string[] = image?.metadata?.published_id?.match(regex.id.imGroup) || [];
  if (image?.context?.[`published_id_${property}`]) {
    const contextPublishedIdArray = image?.context[`published_id_${property}`].match(regex.id.imGroup) || [];
    publishedIdArray = [...publishedIdArray, ...contextPublishedIdArray];
  }
  return publishedIdArray.filter((item, index) => publishedIdArray.indexOf(item) === index);
};
/**
 * generate the url for requesting images based on the environment
 */
export const getImageDomain = (env: EnvironmentType, property: PropertyType, locale: string = 'default') => {
  let environment: string = 'dev';
  if (['prd', 'prod', 'production'].includes(env)) {
    environment = 'prd';
  }
  if (['int'].includes(env)) {
    environment = 'int';
  }
  if (['dev', 'development'].includes(env)) {
    environment = 'dev';
  }
  if (['local'].includes(env)) {
    environment = 'local';
  }
  console.groupEnd(); // eslint-disable-line no-console

  const imageDomains = constants.PROPERTIES.getImageDomains(property);

  return imageDomains[environment][locale];
};

export const getContextValue = (
  image: ImageType | UploadApiResponseImage,
  key: string,
  property?: PropertyType,
  publishedId?: string | null
) => {
  const context = image?.context?.custom ? image.context.custom : image?.context || {};

  let value = null;
  if (context[`${key}_${publishedId}`]) {
    value = context[`${key}_${publishedId}`];
  } else if (context[`${key}_${property}`]) {
    value = context[`${key}_${property}`];
  } else if (context[key]) {
    value = context[key];
  }
  return value;
};

// generate cache buster string from the crop context, coordinates and background color
export const getCachedBusterString = (image: ImageType, property: PropertyType, publishedId: string) => {
  const crop = getContextValue(image, 'crop', property, publishedId) || '';
  const disableThumb = getContextValue(image, 'disable_thumbnails_crop', property, publishedId) || '';
  const coordinates = getContextValue(image, 'coordinates', property, publishedId) || '';
  const backgroundColor = getContextValue(image, 'background', property, publishedId) || '';
  const cbString = `${crop.replaceAll(',', '')}${coordinates.replaceAll(',', '')}${backgroundColor?.replace('#', '')}`;

  return cbString.length > 0 ? `?cb=${cbString}${disableThumb === 'true' ? '1' : '0'}` : '';
};
// getPublishedLabel from the image context ``published_id_label_${publishedId}``
export const getPublishedLabel = (image: ImageType, publishedId: string, property: PropertyType) => {
  const label = getContextValue(image, 'published_label', property, publishedId);
  return label || null;
};

/**
 * Build Dynamic image from related resources results
 */
const getDynamicImage = (resources: any, property: PropertyType, env: EnvironmentType) => {
  const images = resources?.map((image: any) => {
    const url = getImageUrl({
      image: { ...image, ...{ context: { transformation_type: 'dynamic' } } },
      property,
      env,
      height: image.height > constants.FIXED_IMAGE_HEIGHT ? constants.FIXED_IMAGE_HEIGHT : image.height,
      aspect_ratio: image.width / image.height,
    });

    const obj = {
      w: image.width,
      h: image.height,
      ar: image.width / image.height,
      isAltSize: image.isAltSize,
      image: { ...image, ...{ context: { transformation_type: 'dynamic' } } },
      url,
      label: image?.metadata?.import_source_name
        ? image?.metadata?.import_source_name
            .toUpperCase()
            .replace('IMAGE-', '')
            .replace(`${property.toUpperCase()}-`, '')
        : image.asset_id,
    };
    return [`${obj.label}`, obj];
  });
  const mappedImages = Object.fromEntries(
    new Map(
      images.sort((a: { isAltSize: any }[], b: { isAltSize: any }[]) => {
        return b[1].isAltSize === a[1].isAltSize ? 0 : b[1].isAltSize ? -1 : 1;
      })
    )
  );
  return mappedImages;
};

export const createPublishedIdsObj = (
  image: ImageType,
  property: PropertyType,
  env: EnvironmentType,
  localContext?: any
) => {
  const publishedIdsObjArr = getAllPublishedIds(image, property as PropertyType);
  const IMAGE_DOMAIN = getImageDomain(env, property);

  return publishedIdsObjArr.reduce((a, publishedId) => {
    const cacheBusterString = getCachedBusterString(image, property, publishedId);
    const i = publishedIdsObjArr.indexOf(publishedId);

    const previewSizes = image.isDynamic
      ? getDynamicImage(image.additional_resources || [image], property, env)
      : getPreviewSizes({
          image,
          publishedId,
          localContext,
          property,
          env,
          cacheBusterString,
        });

    const dynamicLabel = image.isChart ? 'Chart Image' : 'Promo Image';
    const label =
      getPublishedLabel(image, publishedId, property as PropertyType) ||
      `${DEFAULT_IMAGE_SET_LABEL}${i === 0 ? '' : i}`;

    return {
      ...a,
      [publishedId]: {
        id: publishedId,
        label: image.isDynamic || image.isChart ? dynamicLabel : label,
        url: `${IMAGE_DOMAIN}${publishedId}/previewSquareThumb${cacheBusterString}`,
        previewSizes,
      },
    };
  }, {});
};

export const getLastPublished = (image: ImageType | UploadApiResponseImage, property: PropertyType) => {
  const publishedIdArray: string[] = getAllPublishedIds(image, property);
  const publishedId = publishedIdArray[publishedIdArray.length - 1]?.trim();
  const isValid = regex.id.im.test(publishedId);
  return isValid ? publishedId : null;
};

export const checkIfPublished = (image: ImageType | UploadApiResponseImage, property?: PropertyType) => {
  const publishedIds = getAllPublishedIds(image, property as PropertyType);
  return publishedIds.length > 0;
};

export const getTransformationType = (
  image: ImageType | UploadApiResponseImage,
  property: PropertyType,
  publishedId: string | null
) => {
  return getContextValue(image, 'transformation_type', property, publishedId);
};

export const getColors = (image: ImageType | UploadApiResponseImage) => {
  const colors =
    image?.colors?.reduce((p, c, i) => {
      if (i < MAX_CLOUDINARY_COLORS) {
        return [...p, c[0]];
      }
      return p;
    }, [] as string[]) || [];

  return colors;
};

export const transparentToRGBA = (color: string) => {
  return color === 'transparent' ? 'rgba(0, 0, 0, 0)' : color;
};

export const rgbaToTranparent = (color: string) => {
  return color === 'rgba(0, 0, 0, 0)' ? 'transparent' : color;
};

export const getRelatedProperties = (property: PropertyType) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();
  const relatedProperties = ALL_PROPERTIES[property].RELATED_PROPERTIES || [];
  return relatedProperties;
};

export const getPlaced = (image: ImageType | UploadApiResponseImage, property: PropertyType) => {
  const relatedProperties = getRelatedProperties(property);
  const relatedPacedProperties: { [key: string]: any } = {}; // Add index signature

  const placed = getContextValue(image, 'placed_in_allesseh', property as PropertyType);
  if (placed) {
    relatedPacedProperties[property] = placed;
  }

  relatedProperties.forEach((relatedProperty) => {
    const relatedPlaced = getContextValue(image, 'placed_in_allesseh', relatedProperty as PropertyType);
    if (relatedPlaced) {
      relatedPacedProperties[relatedProperty] = placed;
    }
  });

  if (Object.keys(relatedPacedProperties).length === 0) {
    return null;
  }

  return {
    ...relatedPacedProperties,
  };
};

export const getCoordinates = (image: ImageType | UploadApiResponseImage, publishedId: string) => {
  const context = image?.context?.custom ? image.context.custom : image?.context || {};

  let coordinates = '';
  if (context[`coordinates_${publishedId}`]) {
    coordinates = context[`coordinates_${publishedId}`];
  }

  return (typeof coordinates === 'string' ? coordinates.split(',') : coordinates) || [];
};

export const getCrop = (image: ImageType | UploadApiResponseImage, property: PropertyType, publishedId: string) => {
  const crop = getContextValue(image, 'crop', property, publishedId);
  return (typeof crop === 'string' ? crop.split(',') : crop) || [];
};

/**
 * Given a string, attempt to parse it into a date using known date formats
 */
export const parseDate = (date: string) => {
  // we don't have a date
  if (!date) return null;
  const possibleDateFormats = [
    constants.DATE_FORMATS.CLOUDINARY,
    constants.DATE_FORMATS.UPLOADS.EDIT_METADATA_FORM,
    'yyyy:MM:dd',
    'yyyy:MM:dd HH:mm:ss',
    "yyyy-MM-dd'T'kk:mm:ss",
    "yyyy-MM-dd'T'kk:mm:ssxxxxx",
    "yyyy-MM-dd'T'kk:mm:ss.SSSxxxxx",
  ];
  let parsedDate = null;

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < possibleDateFormats.length; i++) {
    parsedDate = parse(date, possibleDateFormats[i], new Date());

    // date is valid
    if (!Number.isNaN(parsedDate?.getTime())) {
      return parsedDate;
    } else {
      try {
        parsedDate = new Date(date);
        if (!Number.isNaN(parsedDate?.getTime())) {
          return parsedDate;
        }
      } catch (error) {
        // console.error('error: ', error);
        return null;
      }
    }
  }

  return null;
};

/**
 * Format a user.name (from the useAuth hook) from "Last, First" to "First Last"
 */
export const formatUserName = (name: string) => {
  if (name.includes(',')) {
    const nameParts = name.split(', ');

    // name doesn't match correct format
    if (nameParts?.length !== 2) return '';

    return `${nameParts[1]} ${nameParts[0]}`;
  }

  return name;
};

export const getAdditionalImageDomains = (property: PropertyType) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();

  return ALL_PROPERTIES[property].ADDITIONAL_IMAGE_DOMAINS || [];
};

export const getScaledHeight = (width: number, height: number, newWidth: number) => {
  return Math.round(height * (newWidth / width));
};
export const getImageDimensions = (
  image: ImageType | UploadImagePlaceholderType,
  property: PropertyType,
  publishedId?: string
) => {
  // Note: this width matches the default width in cloudinary fn_selects

  const { width, height, aspect_ratio: ar } = image;
  let aspectRatio = ar || width / height || 1.5;
  if (publishedId) {
    const crop = getCrop(image as ImageType, property, publishedId);
    if (crop.length > 0) {
      const [, , cropWidth, cropHeight] = crop;
      aspectRatio = Number(cropWidth) / Number(cropHeight) || 1.5;
      if (width > constants.DEFAULT_WIDTH) {
        return [
          constants.DEFAULT_WIDTH,
          getScaledHeight(Number(cropWidth), Number(cropHeight), constants.DEFAULT_WIDTH),
          roundRatio(aspectRatio),
        ];
      }
      return [Number(cropWidth), Number(cropHeight), Number(aspectRatio)];
    }
  }
  if (width > constants.DEFAULT_WIDTH) {
    return [constants.DEFAULT_WIDTH, getScaledHeight(width, height, constants.DEFAULT_WIDTH), roundRatio(aspectRatio)];
  }

  return [width, height, roundRatio(aspectRatio)];
};

export const getImageAspectRatio = (
  image: ImageType | UploadImagePlaceholderType,
  property: PropertyType,
  publishedId?: string
) => {
  const [, , aspectRatio] = getImageDimensions(image, property, publishedId);
  return aspectRatio;
};
/**
 * generate charts domain
 */
export const getChartsDomain = (env: EnvironmentType) => {
  let environment = 'dev.';
  if (['prd', 'prod', 'production'].includes(env)) {
    environment = '';
  }
  if (['int'].includes(env)) {
    environment = 'int.';
  }
  if (['dev', 'development'].includes(env)) {
    environment = 'dev.';
  }
  return `charts.${environment}dowjones.io`;
};

/**
 * validate if the import source value sent matches environment running
 */
export const validImportSourceEnv = ({env = 'local', importSource, user } : {
  env?: EnvironmentType,
  importSource: string,
  user: any
}) => {
  
  const importSourceParts = importSource.split('_');

  if (
    regex.m2mImportSource(user.M2mScope as string).test(importSource) &&
    importSourceParts === user.M2mScope && (
    (['prd', 'prod', 'production'].includes(env) && importSourceParts.length === 2) ||
    (['int'].includes(env) && importSourceParts[1] === 'int') ||
    (['dev', 'development'].includes(env) && importSourceParts[1] === 'dev') ||
    (['local', 'dev'].includes(env) && importSourceParts[1] === 'local'))
  ) {
    return true;
  }

  return false;
};

export const getCropInfo = (property: PropertyType) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();

  return ALL_PROPERTIES[property].CROP;
};

export const getMD5Domain = (env: EnvironmentType, property: PropertyType) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();
  const currentPropertyConfig: PropertyTypeConfig = ALL_PROPERTIES[property];
  return currentPropertyConfig.MD5_4_DOMAIN[env];
};
/**
 * get default values
 */
export const getDefaultValues = (property: PropertyType, VALUE: string) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();
  const currentPropertyConfig: PropertyTypeConfig = ALL_PROPERTIES[property];
  const allDefaults = currentPropertyConfig.DEFAULTS || {};
  return allDefaults[VALUE] || constants.DEFAULTS[VALUE];
};

/**
 * format for custom crops key name: custom_123x456 (width x height)
 */
const getCustomCrops = (metadata: object, location: string) => {
  return Object.keys(metadata)
    .filter((key) => /^custom_/.test(key))
    .map((key) => {
      const height = key.match(/(?<=^custom_\d+x)\d+/)?.[0];
      const width = key.match(/(?<=^custom_)\d+/)?.[0];

      return {
        width,
        height,
        label: key,
        params: { width, height },
        location: `${location}?width=${width}&height=${height}`,
      };
    });
};

const getAltSized = (property: PropertyType, location: string, publishedId: string) => {
  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();
  const altSizes = ALL_PROPERTIES[property].ALT_SIZES;
  return altSizes.map((size: any) => {
    const ar = size.ar || getImageAspectRatio(size, property, publishedId);
    return {
      label: size.label,
      width: size.width,
      height: size.height,
      params: { size: ar },
      location: `${location}/?size=${ar}`,
    };
  });
};
/**
 * Given an image, reformat that data into the default for downstream consumption
 */
export const getImageObject = (
  image: ImageType,
  env: EnvironmentType,
  property: PropertyType,
  appUrl: string,
  publishedId: string
) => {
  const IMAGE_DOMAIN = getImageDomain(env, property);
  const transformationType = getTransformationType(image, property, publishedId);
  const isResizeOnly = transformationType === 'resize';

  const status =
    typeof image?.metadata?.active === 'string' ? image?.metadata?.active === 'true' : image?.metadata?.active;

  const [width, height, aspectRatio] = getImageDimensions(image, property, publishedId as string);
  const altSizes = getAltSized(property, `${IMAGE_DOMAIN}${publishedId}`, publishedId);
  const altSizesObj: any = {
    softcrops: [
      {
        height: getScaledHeight(image.width, image.height, constants.DEFAULT_WIDTH),
        width: constants.DEFAULT_WIDTH,
        label: 'Default',
        params: {},
        location: `${IMAGE_DOMAIN}${publishedId}/`, // the trailing slash is required for the CMS to work
      },
      ...altSizes,
      ...getCustomCrops(image?.context || {}, `${IMAGE_DOMAIN}${publishedId}/`),
    ],
  };

  const range: any = [];
  let imageFormat = 'softcrop';

  if (image?.isDynamic && image?.additional_resources) {
    imageFormat = 'sizedcrop';
    altSizesObj.range = image.additional_resources
      .sort((a: any, b: any) => a.width - b.width)
      .map((img: any) => ({
        width: img.width,
        height: img.height,
        size: getImageAspectRatio(img, property, publishedId),
      }));

    for (let i = 0; i < range.length; i += 1) {
      const min = range[i - 1] ? range[i].width : null;
      const max = range[i + 1] ? range[i + 1].width - 1 : null;
      if (min) range[i]['min-width'] = min;
      if (max) range[i]['max-width'] = max;
    }
  }

  const object: any = {
    id: publishedId,
    credit: image.metadata?.credit || '',
    caption: image.metadata?.caption || '',
    title: image?.metadata?.headline,
    originalWidth: image.width,
    originalHeight: image.height,
    width,
    height,
    aspectRatio,
    reuseType: image.tags?.includes(constants.TAGS.ONE_TIME_USE) ? 'restricted' : 'full',
    type: image?.metadata?.graphic_type === 'Commerce' ? 'Photo' : image?.metadata?.graphic_type,
    href: `${IMAGE_DOMAIN}${publishedId}?size=${image?.isChart ? 1 : aspectRatio}`,
    formation: imageFormat,
    src: {
      baseUrl: IMAGE_DOMAIN,
      imageId: publishedId,
      path: publishedId,
      prams: {
        size: aspectRatio,
      },
    },
    sizeCode: 'default',
    imageDetailsHref: `${appUrl}/${property}/image/${publishedId}`,
    contentType: `image/${image.format === 'jpg' ? 'jpeg' : image.format}`,
    isResizeOnly,
    alt_sizes: altSizesObj,
    altSrc: [],
    status: publishedId && status ? 'Published' : 'Draft',
    internal: {
      slug: getPublishedLabel(image, publishedId, property) || 'Default',
      previewUrls: {
        default: `${IMAGE_DOMAIN}${publishedId}/preview`,
        thumbSquare: `${IMAGE_DOMAIN}${publishedId}/previewSquareThumb`,
      },
    },
  };

  const additionalDomains = getAdditionalImageDomains(property);

  additionalDomains.forEach((domain) => {
    const ALT_IMAGE_DOMAIN = getImageDomain(env, property, domain);
    object.altSrc.push({
      [domain]: {
        href: `${ALT_IMAGE_DOMAIN}${publishedId}?size=${aspectRatio}`,
        altKey: domain,
        baseUrl: ALT_IMAGE_DOMAIN,
        imageId: publishedId,
        path: publishedId,
        params: {
          size: aspectRatio,
        },
      },
    });
  });
  return object;
};

/**
 * Generates cloudinary URL of the image referenced in public ID sized down by half
 */
type ImageUrlProps = {
  image: ImageType | UploadImagePlaceholderType;
  env: EnvironmentType;
  property: PropertyType;
  publishedId?: string;
  width?: number;
  height?: number;
  aspect_ratio?: number;
  pixel_ratio?: number;
  defaultPreviewUrl?: boolean;
  cacheBuster?: string;
  format?: string;
};

type ImageTransformationProps = {
  image: ImageType;
  host: string;
  publishedId?: string;
  width?: number;
  height?: number;
  aspect_ratio?: number;
  pixel_ratio?: number;
  named?: string;
  format?: string;
};

type DocumentProps = ImageBaseType & {
  metadata: {
    external_id: string;
    value: string;
  }[];
  context: {
    custom: {
      transformation_type: TransformationType;
      [key: string]: string;
    };
  };
};

type FormattedMetadataType = {
  external_id: string;
  value: string;
};

export const getCldTransformationImage = ({
  image,
  width,
  height,
  aspect_ratio,
  pixel_ratio,
  host,
  named,
  publishedId,
  format,
}: ImageTransformationProps) => {
  const transformations: string[] = [];
  if (width) transformations.push(`$width_!${width}!`);
  if (height) transformations.push(`$height_!${height}!`);
  if (aspect_ratio) transformations.push(`$size_!${roundRatio(aspect_ratio)}!`);
  if (pixel_ratio) transformations.push(`$pixelratio_!${pixel_ratio}!`);
  if (!named && !width && !height) transformations.push('$width_!600!');
  if (host) transformations.push(`$host_!${host}!`);
  if (named) transformations.push(`$named_!${named}!`);
  if (format) transformations.push(`$ext_!${format}!`);

  const getDocument = () => {
    const metadata = image?.metadata || {};
    const formattedMetadata: FormattedMetadataType[] = Object.keys(metadata).map((key) => {
      const value = metadata[key as ImageStructuredMetadataKeys] as string;
      return { external_id: key, value };
    });

    const document: DocumentProps = {
      ...image,
      ...{
        metadata: formattedMetadata,
        context: { custom: image?.context || {} },
      },
    };

    return JSON.stringify([document]);
  };

  const getContext = () => {
    return {
      resource: publishedId || '',
      transformation: transformations.join(','),
    };
  };
  const updatedImage = fnSelectRenderer(getDocument, getContext);
  return updatedImage;
};

export const getImageUrl = ({
  image,
  publishedId,
  width,
  height,
  aspect_ratio,
  pixel_ratio,
  env,
  property,
  defaultPreviewUrl,
  format,
}: ImageUrlProps) => {
  try {
    if (['local-upload', 'authenticated'].includes(image?.access_mode)) {
      return image.preview_url || image.secure_url;
    }
    const imageDomain = getImageDomain(env, property);
    const host = getMD5Domain(env, property);
    const named = defaultPreviewUrl ? 'preview' : undefined;
    const pixelRatio: number = pixel_ratio || (typeof window !== 'undefined' && window.devicePixelRatio ? window.devicePixelRatio : 1);

    if (image.isDynamic && !publishedId) {
      publishedId = getAllPublishedIds(image as ImageType, property)[0];
    }

    const res = getCldTransformationImage({
      image: image as ImageType,
      publishedId,
      width,
      height,
      aspect_ratio,
      pixel_ratio: pixelRatio,
      host,
      named,
      format,
    });
    if (!res) console.error('getImageUrl error: ', res, image);

    const { transformation } = res;

    const publicId = (image as ImageType).public_id;
    const imageUrl = `${imageDomain}image/upload/${transformation}${defaultPreviewUrl ? '/f_auto' : ''}/${publicId}`;

    return imageUrl;
  } catch (error) {
    console.error('getImageUrl - Invalid image URL: ', error);
    return 'Invalid image URL';
  }
};

/**
 * Validates the form fields for a given Publisher
 * @param {PropertyType} property - the property type to validate
 * @param {boolean} disableRequiredFields - param to disable required field validation if needed
 * @returns {{[key: string]: boolean}} - an object with boolean values based on the validation
 */
export const requiredFieldsForPublisher = (
  property: PropertyType,
  disableRequiredFields: boolean = false
): { [key: string]: boolean } => {
  const formValidationFields = constants.PROPERTIES.getFormValidationFields(property);

  if (!formValidationFields || formValidationFields.length === 0) {
    throw new Error(`Form validation fields not found for property "${property}"`);
  }

  let requiredFields: { [key: string]: boolean } = {};

  formValidationFields.forEach((field) => {
    requiredFields[field] = true;
  });

  // cleaning required fields object key/value if needed
  if (disableRequiredFields) {
    requiredFields = {};
  }

  return requiredFields;
};

type Source = {
  key: string;
  label: string;
  includeKey: string;
};

/**
 * This function generates an array of source objects based on the provided property.
 * Each source object contains a key, a label, and an includeKey.
 * The function maps over the wires array and checks if the source exists in the constants.CLD_SOURCES.
 * If it does, it creates a new object with the key, label, and includeKey properties.
 * The key is the lowercased source, the label is the value of the source in the constants.CLD_SOURCES, and the includeKey is a string that starts with 'include' and ends with the value of the source in the constants.CLD_SOURCES.
 * The function then filters out any null values and adds an object for 'Uploaded' to the end of the array.
 *
 * @param {PropertyType} property - The property based on which the wires array is generated.
 * @returns {Source[]} An array of source objects.
 */

export const getCldSources = (property: PropertyType): Source[] => {
  const CLD_SOURCES = constants.PROPERTIES.getCldSources(property);
  return CLD_SOURCES;
};

export const getDownloads = (property: PropertyType): Source[] => {
  const DOWNLOADS = constants.PROPERTIES.getDownloads(property);
  return DOWNLOADS;
};

/**
 * Generates an array of objects representing labels and folders for wires based on the specified property and wire names.
 *
 * @param property - The type of property for which wire folders are generated.
 * @param source - An optional array of source keys to filter. If empty, all wire sources for the property are included.
 * @returns An array of objects with 'label' and 'folder' properties representing wire labels and corresponding folders.
 */
export const filterCldSources = (
  property: PropertyType,
  source: string[] = []
): { label: string; folder: string; subSources?: { label: string; folder: string }[] }[] => {
  const CLD_SOURCES = constants.PROPERTIES.getCldSources(property);
  const res =
    source.length === 0
      ? CLD_SOURCES
      : pickBy<Record<string, { label: string; folder: string; subSources?: { label: string; folder: string }[] }>>(
          CLD_SOURCES,
          (value, key) => {
            return source.includes(key);
          }
        );
  return res;
};

export const convertTZ = (date: string, tzString: string) => {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

// Helper for manipulating specific object properties with `useReducer`
export const mergeReducer = (oldState: any, newState: any) => {
  return { ...oldState, ...newState };
};
// helper for manipulating cursor in the new SearchPrivider

export const getNextPageCursor = (currentPage: number, pages: string[], direction: 'prev' | 'next') => {
  let cursor = null;
  let newCurrentPage;
  if (direction === 'next') {
    newCurrentPage = currentPage + 1;
    cursor = currentPage + 1 === pages.length ? pages[currentPage] : pages[currentPage + 1];
  } else if (direction === 'prev') {
    newCurrentPage = currentPage > 0 ? currentPage - 1 : 0;
    cursor = pages[currentPage - 1];
  }
  return { cursor, newCurrentPage };
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return 'unavailable';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Processes dynamic search options to determine relevant characteristics.
 *
 * This function analyzes the provided options object, separating the 'oneTimeUse' property
 * from other options, and evaluates the presence of dynamic options and non-empty options.
 *
 * @param options - The dynamic search options to be processed.
 * @returns An object with insights into the dynamic options and non-empty options.
 * @typedef {Object} ProcessedOptions
 * @property {boolean} dynamicOptionsPresent - Indicates whether non-primitive, non-empty values
 *                                             are present in the dynamic options.
 * @property {boolean} hasNonEmptyOptions - Indicates whether non-empty objects are present in
 *                                          the dynamic options (excluding 'oneTimeUse').
 */
const processOptions = (options: DynamicSearchOptionsType) => {
  const { oneTimeUse, ...restOptions } = options;

  // Check if any non-object values in 'restOptions' are not false, null, or empty strings.
  const dynamicOptionsPresent = Object.values(restOptions)
    .filter((value) => typeof value !== 'object')
    .some((value) => value !== false && value !== null && value !== '');

  // Check if there are non-empty objects in 'restOptions'.
  const hasNonEmptyOptions = Object.values(restOptions).reduce(
    (acc, value) => acc || (typeof value === 'object' && value !== null && Object.keys(value).length > 0),
    false
  ) as boolean;

  return {
    dynamicOptionsPresent,
    hasNonEmptyOptions,
  };
};

/**
 * Checks if there are present search options based on the provided parameters .
 *
 * @param options - The dynamic search options to be checked.
 * @param pagination - Object containing the current page number.
 * @param userQuery - The user's search query string.
 * @param currentProperty - The current property type for which the search is being conducted.
 * @returns A boolean indicating whether there are present search options or not.
 */
export const isPresentSearchOptions = (
  options: DynamicSearchOptionsType,
  pagination: {
    currentPage: number;
  },
  userQuery: string,
  currentProperty: PropertyType
): boolean => {
  // Destructure the 'oneTimeUse' property from 'options'
  const { oneTimeUse } = options;

  // Process dynamic options to check if they are present or have non-empty values
  const { dynamicOptionsPresent, hasNonEmptyOptions } = processOptions(options);

  // Get the default 'oneTimeUse' value for the current property from constants
  const { oneTimeUse: externalDefaultOneTimeUse } = constants.PROPERTIES.getInitialQueryParams(currentProperty);

  // Check if any of the conditions for present search options are met
  return (
    Boolean(oneTimeUse !== externalDefaultOneTimeUse || userQuery?.length || pagination?.currentPage > 0) ||
    dynamicOptionsPresent ||
    hasNonEmptyOptions
  );
};

export const handleDragAndDrop = (
  currentProperty: PropertyType,
  router: { redirect: (url: string) => void; navigate: (url: string) => void },
  dynamic: boolean = false
) => {
  const handleDragEnter = (e: DragEvent) => {
    //  reroute page if the dragging contains a file
    // dragging images from the gallery will have a dataTransfer
    // object with a kind of 'string'
    const item = e.dataTransfer?.items[0]; // Prevent Safari Crash
    if (item?.kind === 'file') {
      router.navigate(`/${currentProperty}/images/upload${dynamic ? '/dynamic' : ''}`);
      // since this event listener only runs once, add it again
    }
  };

  document.addEventListener('dragenter', handleDragEnter, { once: true });

  // clean up even listener when the update unmounts
  return () => document.removeEventListener('dragenter', handleDragEnter);
};

export const getPreviewSizes = ({
  image,
  publishedId,
  localContext,
  property,
  env,
  cacheBusterString,
}: {
  image: ImageType;
  publishedId?: string;
  localContext?: any;
  property: PropertyType;
  env: EnvironmentType;
  cacheBusterString?: string;
}) => {
  const updateImage = { ...image };
  updateImage.context = { ...image.context, ...(localContext || {}) };

  const ALL_PROPERTIES = constants.PROPERTIES.getAllConfigs();
  const sizes = {
    ...{
      [constants.DEFAULT_IMAGE_KEY]: {
        ar: getImageAspectRatio(image, property, publishedId) || image.aspect_ratio,
        defaultPreviewUrl: true,
        label: 'Default',
      },
    },
    ...ALL_PROPERTIES[property].PREVIEW_SIZES,
  };
  const sizesObj: any = {};

  const getThumbnailCrop = getContextValue(updateImage, 'disable_thumbnails_crop', undefined, publishedId);
  const thumbnailsDisabled = typeof getThumbnailCrop === 'string' ? getThumbnailCrop === 'true' : getThumbnailCrop;

  const transformationType = getTransformationType(updateImage, property, publishedId as string);

  Object.keys(sizes).forEach((key) => {
    sizesObj[key] = {
      ar: sizes[key].ar,
      label: thumbnailsDisabled ? sizes[key].label.replace('Thumb', 'Small') : sizes[key].label,
      url: `${getImageUrl({
        image: updateImage,
        publishedId,
        property,
        env,
        aspect_ratio: sizes[key].ar,
        defaultPreviewUrl: sizes[key].defaultPreviewUrl,
        height: sizes[key]?.h || (!sizes[key]?.isThumbnail ? constants.FIXED_IMAGE_HEIGHT : undefined),
        width: sizes[key]?.w || (sizes[key]?.isThumbnail ? constants.THUMBNAIL_WIDTH : undefined),
        format: transformationType === 'cldDefault' ? updateImage.format : undefined,
      })}${cacheBusterString ? `?cb=${cacheBusterString}` : ''}`,
    };
  });

  return sizesObj;
};

export const convertCldMetadataStringToObj = (metadata: any = {}) => {
  const updatedMetadata: any = {};
  Object.keys(metadata).forEach((metadataType) => {
    const data = metadata[metadataType];

    if (data instanceof Map) {
      updatedMetadata[metadataType] = Object.fromEntries(data);
    } else {
      updatedMetadata[metadataType] = {};

      if (typeof data === 'string') {
        data.split('|').forEach((item: any) => {
          const [key, value] = item.split('=');
          updatedMetadata[metadataType][key] = value;
        });
      } else {
        updatedMetadata[metadataType] = data;
      }
    }
  });
  return updatedMetadata;
};

export const getCldMetadataLabel = (key: string) => {
  const updatedKey = key.split('_im-')[0];
  const fields: Record<string, string> = {};
  const allMetadata: any = { ...contextualMetadata, ...structuredMetadata };

  Object.keys(allMetadata).forEach((item) => {
    const obj = allMetadata[item];
    fields[obj.cloudinaryId] = obj.label;
  });

  const label = fields[updatedKey];
  return [label || updatedKey, updatedKey];
};

/**
 * Generates an array of graphic type options based on the keys in GRAPHIC_TYPES_OPTIONS_CONFIG.
 *
 * @returns {Object[]} An array of graphic type option objects. Each object has the following properties:
 * - `graphicKey`: The key of the graphic type, which is one of the keys in GRAPHIC_TYPES_OPTIONS_CONFIG.
 * - `label`: The label of the graphic type, retrieved from GRAPHIC_TYPES_OPTIONS_CONFIG.
 *
 * This function is useful for creating options for a dropdown or a similar UI element where the user can select a graphic type.
 */
export const getGraphicTypeOptions = () => {
  return Object.keys(GRAPHIC_TYPES_OPTIONS_CONFIG).map((graphicKey) => ({
    graphicKey,
    label: GRAPHIC_TYPES_OPTIONS_CONFIG[graphicKey].label,
  }));
};

export const getKeywords = (property: PropertyType) => {
  return {
    TAGS: constants.PROPERTIES.getKeywords(property),
    ICONS: {
      star: IconStar,
      newspaper: IconNewspaper,
      award: IconAward,
    },
  };
};

/**
 * Filters graphic types based on a provided graphicTypeObj and returns their corresponding labels.
 *
 * @param graphicTypeObj - An object representing graphic types with keys as strings and values as booleans.
 *                        It indicates which graphic types to include in the filtered result.
 * @returns An array of labels for graphic types that satisfy the conditions specified in the graphicTypeObj.
 */
const filterGraphicTypesObject = (graphicTypeObj: { [key: string]: boolean }) => {
  // Get the graphic type options with camelCase keys and original labels
  const graphicTypeOptions = getGraphicTypeOptions();

  // Filter the graphic types based on the provided graphicTypeObj
  const filteredTypesKeys = graphicTypeOptions
    .filter((option) => graphicTypeObj?.[option.graphicKey as keyof typeof graphicTypeObj] === true)
    .map((filteredOption) => filteredOption.graphicKey);

  // Return the array of filtered graphic type labels
  return filteredTypesKeys;
};

/**
 * Cleans and organizes search options for improved usability.
 *
 * The `cleanOptions` function takes a search options object as input and performs
 * two main tasks: extracting sources with the 'include' prefix and true values, and
 * creating a cleaned options object by removing keys with the 'include' prefix.
 * The resulting object is well-structured and ready for use in search operations.

 * @param options - The search options object to be cleaned.
 * @returns A cleaned search options object with organized data.
* */
export const cleanOptions = (options: SearchOptionsType) => {
  const graphicTypesFilterValues = options?.graphicTypesFilter as { [key: string]: boolean };
  const graphicTypesFilter = filterGraphicTypesObject(graphicTypesFilterValues || {});

  const cleanedOptions = Object.entries(options || {})
    ?.filter(([key]) => !key.startsWith('include'))
    ?.reduce((acc, [key, value]) => {
      acc[key as keyof SearchOptionsType] = value;
      return acc;
    }, {} as SearchOptionsType);

  const cleanOptionsWithObjectAdded = { ...cleanedOptions, graphicTypesFilter };
  return cleanOptionsWithObjectAdded;
};

/**
 * Validates an object's properties, checking if at least one property has a non-null value.
 *
 * @param obj - The object to validate, with properties of type `Partial<{ [key: string]: string | null }>`
 * @returns A boolean indicating whether at least one property in the object has a non-null value.
 */
export function validateObjectProperties(
  obj: Partial<{ [key: string]: string | null }>,
  disabledFields: Partial<{ [key: string]: boolean }>
): boolean {
  return Object.keys(obj).some((key) => {
    const value = obj[key];
    return value !== null && !disabledFields[key];
  });
}

/**
 * Initializes an object with keys from the provided array, where each key is initially set to null.
 *
 * @param arr - An array of string keys to initialize in the resulting object.
 * @returns An object with keys from the input array, each initially set to null.
 */
export function valuesInitializer(arr: string[] | undefined): { [key: string]: string | null } {
  // Initialize an empty object to store key-value pairs
  const result: { [key: string]: string | null } = {};

  // If the input array is provided, iterate through its keys and set each key to null in the result object
  arr?.forEach((key: string) => {
    result[key] = null;
  });

  // Return the resulting object
  return result;
}
export const getToday = () => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return today;
};

// delay, timeout, sleep
export const wait = (millisecons: number) => new Promise((resolve) => setTimeout(resolve, millisecons));

/**
 * Generates an array of graphic types based on the provided keys.
 *
 * @param {string[]} graphicKeys - An array of keys to generate the graphic types.
 *
 * @returns {Object[]} An array of graphic type objects. Each object has the following properties:
 * - `metadata`: The metadata of the graphic type, retrieved from GRAPHIC_TYPES_OPTIONS_CONFIG.
 * - `key`: The key of the graphic type, which is one of the input graphicKeys.
 * - `label`: The label of the graphic type, retrieved from GRAPHIC_TYPES_OPTIONS_CONFIG.
 *
 * The function filters out any keys that are not present in GRAPHIC_TYPES_OPTIONS_CONFIG.
 */
export const generateGraphicType = (graphicKeys: string[]) => {
  return graphicKeys
    .filter((graphicKey: string) => GRAPHIC_TYPES_OPTIONS_CONFIG[graphicKey])
    .map((validGraphicKey: string) => ({
      metadata: GRAPHIC_TYPES_OPTIONS_CONFIG[validGraphicKey].metadata,
      key: validGraphicKey,
      label: GRAPHIC_TYPES_OPTIONS_CONFIG[validGraphicKey].label,
    }));
};

export const generateUuid = () => {
  return uuidv4();
};

export const handleApplyTags = (publicId: string, tags: string[], authFetch: any, method: string) => {
  return new Promise((resolve, reject) => {
    const encodedPublicId = encodeURIComponent(publicId);
    authFetch(`/api/:property/${encodedPublicId}/tags`, {
      method,
      body: JSON.stringify({
        tags,
      }),
    })
      .then((data: any) => {
        resolve(data);
      })
      .catch((err: Error) => {
        console.error('handleApplyTags Error: ', err);
        reject(err);
      });
  });
};

/**
 * Filters an array of tags based on a property.
 *
 * @param {string[]} tags - The array of tags to filter.
 * @param {PropertyType} property - The property to use for filtering.
 *
 * @returns {string[]} The filtered array of tags.
 *
 * The function works as follows:
 * 1. It first identifies tags that include any of the existing properties.
 * 2. Then it creates a new array of tags, excluding those that match the identified tags from step 1,
 *    unless they include the property.
 */

export const filterTags = (tags: string[], property: PropertyType): string[] => {
  const matchingWords = tags.filter((tag) => Object.keys(PROPERTY_LABELS).some((propertyKey) => tag.toLowerCase().includes(propertyKey)));
  const newTags = tags.filter((tag) => !matchingWords.includes(tag) || tag.toLowerCase().includes(property));

  return newTags;
};

export const formatTag = (tag: string, property: PropertyType) => {
  const KEYWORDS = constants.PROPERTIES.getKeywords(property) || {};
  const keywordTags: { [key: string]: string } = {}; // Add type annotation for keywordTags object
  Object.keys(KEYWORDS).forEach((tagKey) => {
    const tagObj = KEYWORDS[tagKey];
    keywordTags[tagObj.key] = tagObj.label; // Fix object property assignment syntax
  });

  if (keywordTags[tag]) return keywordTags[tag];
  return tag.replace(`${property.toUpperCase()}#TAG#`, '');
};


/**
 * Asynchronously fetches the latest uploads images based on a given latestImageID.
 *
 * @returns {Promise<unknown[]>} - A promise that resolves to an array of the fetched images, or an empty array if no images could be fetched.
 */
export const getLatestUploadFromLocalStorage = async(
    property: string, 
    fetchImageByID: (id: string) => Promise<unknown>
): Promise<ImageType[]> => {
    try {
        const imageIds = localStorageHelper.readItem<string[]>(property);
        
        if (Array.isArray(imageIds) && imageIds.length > 0) {
            const fetchPromises = imageIds.map((imageId) => 
                fetchImageByID(imageId).catch(err => {
                    console.error(`Error fetching image with id ${imageId}:`, err);
                    return null;
                })
            );
            
            const images = (await Promise.all(fetchPromises)).filter(image => image !== null) as ImageType[];
          return images;
        }
        
        return [];
    } catch (error) {
        console.error(`Error in getLatestUploadFromLocalStorage for property ${property}:`, error);
        return [];
    }
};

type StorageValue = string | number | boolean | object | null;

export const localStorageHelper = {
  generateKey: (property: string, newKey?: string): string => 
    newKey || `latestUploadIds_${property}`,

  handleError: (action: string, key: string, err: unknown): void => {
    console.error(`Error ${action} item with key "${key}":`, err);
  },

  createItem: <T extends StorageValue>(
    property: string, 
    value: T, 
    customKey?: string
  ): void => {
    const key = localStorageHelper.generateKey(property, customKey);
    try {
      localStorage.setItem(key, JSON.stringify(value));
      localStorage.setItem(`${property}:backToUploadList`, 'true');
    } catch (err) {
      localStorageHelper.handleError('creating', key, err);
    }
  },

  readItem: <T extends StorageValue>(
    property: string, 
    customKey?: string
  ): T | null => {
    const key = localStorageHelper.generateKey(property, customKey);
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) as T : null;
    } catch (err) {
      localStorageHelper.handleError('reading', key, err);
      return null;
    }
  },

  deleteItem: (property: string, customKey?: string): void => {
    const key = localStorageHelper.generateKey(property, customKey);
    try {
      localStorage.removeItem(key);
      localStorage.removeItem(`${property}:backToUploadList`);
    } catch (err) {
      localStorageHelper.handleError('deleting', key, err);
    }
  },

};

/**
 * Function to parse true or false strings to Boolean in case is necessary
 */
export const parseToBoolIfNeeded = (value: string) => {
  if (['true', 'false'].includes(value)) {
    return value === 'true';
  }
  return value;
};

export const getResizeOnlyFlag = (image: ImageType | UploadApiResponseImage, property: PropertyType) => {
  const { context } = image;
  if (context && context.hasOwnProperty(constants.IMAGE_PROPERTY.RESIZE_ONLY)) {
    return Boolean(getContextValue(image, constants.IMAGE_PROPERTY.RESIZE_ONLY, property));
  } else {
    const publishedIds = getAllPublishedIds(image, property as PropertyType);
    return getTransformationType(image, property, publishedIds[0]) === 'resize';
  }
};


/**
 * Normalizes a string to a slug format suitable for URLs. It trims whitespace, converts to lowercase,
 * replaces disallowed characters with hyphens, and removes leading/trailing hyphens. 
 *
 * @param value - The string to be normalized.
 * @returns The normalized slug string.
 */
export const slugNormalize = (value: string): string => {
  // Normalizes input based on the event type
  let normalizedValue = value
    .replace(regex.slugReplace, '-') // Replace disallowed characters with hyphens
    .replace(/-+/g, '-'); // Ensure single hyphens only

  // Limit the length of the slug to 32 characters
  return normalizedValue.length <= 32 ? normalizedValue : normalizedValue.slice(0, 32);
};


/**
 * Retrieves the index and count of an image asset ID within a list of image asset IDs.
 *
 * @param lastUploadedImages - An array of image asset IDs that were recently uploaded, or null.
 * @param imageAssetId - The image asset ID to search for.
 * @param property - The property name used to retrieve the list of image asset IDs from local storage.
 * @returns An object containing the index and count of the image asset ID within the list.
 *          If the image asset ID is not found, the index will be 0.
 */
export const getImageIndexAndCount = (
  lastUploadedImages: string[] | null,
  imageAssetId: string,
  property: PropertyType
): { index: number; count: number } => {
  const images = lastUploadedImages?.length
    ? lastUploadedImages
    : localStorageHelper.readItem<string[]>(property) || [];

  const index = images.indexOf(imageAssetId) + 1;
  const count = images.length;

  return { index, count };
};
