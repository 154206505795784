import React, { useEffect, useState } from 'react';
import { Button, Group, IconCrop, IconTrash } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';
import useImageDetail from '../../../../hooks/useImageDetail';

const Crop = ({ panelKey }: { panelKey: string }) => {
  const { contentComponent, setContentComponent, setData, getData } = useEditImage();
  const { localContext } = useImageDetail();
  const isActive = contentComponent === panelKey;

  const [hasCrop, setHasCrop] = useState(getData('coordinates', panelKey));
  useEffect(() => {
    setHasCrop(getData('coordinates', panelKey));
  }, [contentComponent, localContext]);

  return (
    <Group gap="sm">
      <Button
        data-testid="reframe-btn"
        tertiary
        icon={IconCrop}
        active={isActive}
        onClick={() => {
          setContentComponent(isActive ? false : panelKey);
        }}
      >
        Reframe
      </Button>
      {hasCrop && (
        <Button
          tertiary
          icon={IconTrash}
          color="lava"
          onClick={() => {
            setData('coordinates', null, panelKey);
            setHasCrop(false);
          }}
        />
      )}
    </Group>
  );
};

export default Crop;
