import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">Image URL Structure</Typography>
      <MarkdownBlock
        md={`

The Image URL uses the base URL followed by the image ID and relevant query strings e.g.

- \`https://images.wsj.net/im-1113/?width=600&height=400\`
- \`{BASE_URL}/{IMAGE_ID}/?{QUERY_STRING}\`

If no query string is passed then the image will be resized to the width of 1280.

### Supported query strings:

- \`width\` _Integer_ - in pixels
- \`height\` _Integer_ - in pixels
- \`pixel_ratio\` _Float_ - Used for scaling up images on retina mobile devices. When requesting an image for retina devices you should always request the same pixel width and height for all devices and use pixel_ratio to scale up the image. If you request different pixel width and/or height for a different screen you are likely to be returned a different crop
- \`size\` _Float_ (_String_ deprecated) - a decimal aspect ratio, this will indicate the intended ratio of an image. If you pass both width and height along with a float, this value will be ignored.
  - If a string is provided, we will treat it as a custom size and return the exact crop saved by the user. This custom size is formatted as \`custom_{width}x{height}\`.

Predetermined image sizes

These are determined sizes often used by third parties, so they do not need to pass query strings. Examples of these images are social crops or syndication sizes. The REF ID returns a predefined size. Currently, legacy image letters are also supported. 

- https://images.wsj.net/im-1113/social?pixel_ratio=2
- \`{BASE_URL}/{IMAGE_ID}/{REF_ID}?{QUERY_STRING}\`

### Supported query strings:

- \`pixel_ratio\` _Float_ - (same as above)

### Optional Image File Extension

Add an image file extension before the ?{QUERY_STRING} to override the default auto format behavior.  


`}
      />
    </>
  );
}

export default DeveloperDocs;
