import { calendarHooks, format, parse } from '@screentone/addon-calendar';
import { useState } from 'react';
import { isEqual } from 'lodash';

import { constants } from '../../../utils';
import { useSearch } from '../../../hooks/useSearch';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
const lastMonth = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

// time filters for dates
const filters = {
  anyTime: { startDate: null, endDate: null, label: 'Any Time' },
  today: { startDate: today, endDate: today, label: 'Today' },
  lastWeek: { startDate: lastWeek, endDate: today, label: 'Last Week' },
  lastMonth: { startDate: lastMonth, endDate: today, label: 'Last Month' },
  lastYear: { startDate: lastYear, endDate: today, label: 'Last Year' },
};

/**
 * Given a starting date and an ending date, get the corresponding trigger text
 *
 * @param {Date | null} start startDate
 * @param {Date | null} end endDate
 * @returns {{
 *   filters: typeof filters,
 *   onSelect: ({ startDate?: Date | null, endDate?: Date | null }) => { startDate: Date | null, endDate: Date | null },
 *   setTriggerText: () => void,
 *   since: Date | null,
 *   until: Date | null,
 *   triggerText: string,
 * }} An object containing state used in the `DateFilter` component
 */
const getTriggerText = (start: Date | null, end: Date | null) => {
  const filter = Object.values(filters).find(
    ({ startDate, endDate }) => startDate?.getTime() === start?.getTime() && endDate?.getTime() === end?.getTime()
  );

  if (filter) return filter.label;

  if (start && end) {
    if (start.getTime() === end.getTime()) {
      return `${format(start, constants.DATE_FORMATS.CLOUDINARY)}`;
    }
    return `${format(start, constants.DATE_FORMATS.CLOUDINARY)} - ${format(end, constants.DATE_FORMATS.CLOUDINARY)}`;
  }
  if (start) {
    if (start.getTime() === today.getTime()) {
      return `${filters.today.label}`;
    }
    return `Since ${format(start, constants.DATE_FORMATS.CLOUDINARY)}`;
  }

  return '';
};

/**
 * A hook for managing the state in the `DateFilter` component
 *
 * @returns {{
 *   filters: typeof filters,
 *   onSelect: ({ startDate?: Date | null, endDate?: Date | null }) => { startDate: Date | null, endDate: Date | null },
 *   setTriggerText: (text: string) => void,
 *   since: Date | null,
 *   until: Date | null,
 *   triggerText: string,
 * }} An object containing state used in the `DateFilter` component
 */
const useDateFilter = () => {
  const { options } = useSearch();
  const [currentOptions, setOptions] = useState(options);
  // object that represents the relevant query params in local storage
  const query = {
    since: options.since,
    until: options.until,
  };

  // This is only for initial render
  const {
    endDate: until,
    onSelect,
    startDate: since,
  } = calendarHooks.useDateRange({
    startDate: options.since ? parse(query.since, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null,
    endDate: options.until ? parse(query.until, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null,
  });

  const [triggerText, setTriggerText] = useState(getTriggerText(since, until));

  // date filter has changed - date filters have probably been cleared
  if (!isEqual(options, currentOptions)) {
    const newSince = query.since ? parse(query.since, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null;
    const newUntil = query.until ? parse(query.until, constants.DATE_FORMATS.CLOUDINARY, new Date()) : null;
    const updatedTriggerText = getTriggerText(newSince, newUntil);
    setTriggerText(updatedTriggerText);
    setOptions(options);
    onSelect({ startDate: newSince, endDate: newUntil });
  }

  return { filters, onSelect, setTriggerText, since, until, triggerText };
};

export default useDateFilter;
