import React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
// Main Routes

import Collections from './[property]/Collections';
import ImageSearch from './[property]/Images';
import ImageDetails from './[property]/Images/ImageDetails';
import ImageUpload from './[property]/Images/Upload';
import DynamicImageUpload from './[property]/Images/Upload/dynamic';

import StaticImages from './[property]/Static';

// Developer Routes
import DeveloperRoutes from './developer/routes';

let Redirect = () => {
  let { property } = useParams();
  const { state, search, pathname, ...other } = useLocation();
  if (pathname.split('/')[2] === 'upload') {
    return <Navigate to={pathname.replace('upload', 'images/upload')} replace />;
  }
  return <Navigate to={`/${property}/images${search}`} relative="path" state={state} replace />;
};

const MainRoutes = [
  { path: '/:property', component: Redirect, protected: true },
  { path: '/:property/image/', component: Redirect, protected: true },
  { path: '/:property/images/', component: ImageSearch, protected: true },
  { path: '/:property/static/', component: StaticImages, protected: true, restricted: ['app_admin'] },
  { path: '/:property/collections/', component: Collections, protected: true, restricted: ['app_admin'] },
  { path: '/:property/:type/:id/:mode?/:im?', component: ImageDetails, protected: true },
  { path: '/:property/upload', component: Redirect, protected: true },
  {
    path: '/:property/images/upload',
    component: ImageUpload,
    protected: true,
    restricted: ['${property}.upload'],
  },
  { path: '/:property/upload/dynamic', component: Redirect, protected: true },
  {
    path: '/:property/images/upload/dynamic',
    component: DynamicImageUpload,
    protected: true,
    restricted: ['${property}.upload'],
  },
];

const Routes = [...MainRoutes, ...DeveloperRoutes];

export { Routes };
export default Routes;
