import React from 'react';
import { Token } from '@screentone/core';

import UseUploadType from '../../hooks/useUpload/types';

/**
 * A utility function for checking the validation object for complete metadata
 */
export const checkCompleteness = (validation: UseUploadType.Validation.Validation) =>
  Object.values(validation).every((entry) => entry === false || entry === null);

interface TextProps {
  /** Total of required fields without errors */
  totalComplete: number;
  /** Total of required fields */
  totalRequired: number;
}

/**
 * Text that marks the EditMetadataCard as being complete
 */
export const CompleteText = ({ totalComplete, totalRequired }: TextProps) => {
  return (
    <Token data-testid="required-fields-token" color="emerald">
      {totalComplete}/{totalRequired}
    </Token>
  );
};

/**
 * Text that marks the EditMetadataCard as containing errors
 */
export const ErrorText = ({ totalComplete, totalRequired }: TextProps) => {
  return (
    <Token data-testid="required-fields-token" color="lava">
      {totalComplete}/{totalRequired}
    </Token>
  );
};
