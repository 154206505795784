import actions from '../actions';
import UseUploadType from '../types';
import uploadImages from './uploadImages';

type UploadParams = {
  /** the action to fetch with authorization header */
  authFetch(resource: string, options?: RequestInit): Promise<unknown>;
  /** the action dispatcher from the reducer hook */
  dispatch: UseUploadType.Merged.Dispatch;
  /** current property/brand */
  property: string;
  /** state of useUpload hook */
  state: UseUploadType.Merged.State;
  /** type of image to upload */
  type: 'single' | 'dynamic';
};

/**
 * Handle image file(s) being added to the uploader
 */
export const upload = async ({ authFetch, dispatch, property, state, type }: UploadParams) => {
  dispatch({ type: actions.UPLOAD.INITIALIZE, payload: { length: state.files.accepted.length } });

  return new Promise(() => {
    uploadImages({ authFetch, dispatch, property, state, type });
  });
};

export default upload;
