import React, { RefObject } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import { Dispatch, State } from '../../hooks/useLightbox';
import { getImageUrl } from '../../utils/helpers';
import { ImageType, PropertyType, EnvironmentType } from '../../types';

import styles from './CardDragLayer.module.css';

// size of the gap between images: `--st-spacer-md`
const ST_SPACER_MD = 16;

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  return {
    transform: `translate(${x}px, ${y}px)`,
    filter: 'drop-shadow(0 2px 12px var(--st-color-asphalt)',
  };
};

type CardDragLayerProps = {
  /** The number of images in each row */
  columnCount: number;
  /**
   * an array containing information regarding the width (in percent) that each image should occupy
   * in a given row. `grid` corresponds with `images`
   */
  grid: { width: number }[];
  /** a ref to the Gallery grid */
  gridContainerRef: RefObject<HTMLDivElement>;
  /** values exported from the useLightbox hook */
  lightboxResources: {
    actions: Record<string, string>;
    dispatch: Dispatch;
    state: State;
  };
  /** the property that the user is currently viewing */
  property: PropertyType;
  /** the environment that the user is currently viewing */
  env: EnvironmentType;
};

/**
 * If the lightbox, when a user drags a stack of images, this component groups together the stack of
 * images and renders them below the dragged image
 */
const CardDragLayer = ({
  columnCount,
  grid,
  gridContainerRef,
  lightboxResources,
  property,
  env,
}: CardDragLayerProps) => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  const mainImage = lightboxResources.state.selectedForDrag.find((image) => image.asset_id === item.asset_id);
  const reorderedDraggedImages = [
    mainImage, // put the dragged image at the top of the stack
    ...lightboxResources.state.selectedForDrag.filter((image) => image.asset_id !== item.asset_id),
  ].filter((image) => !!image) as unknown as ImageType[];

  return (
    <div className={styles.imageStack}>
      <div style={getItemStyles(currentOffset)}>
        {itemType === 'image' && (
          <div>
            {reorderedDraggedImages.slice(0, 3).map((image: ImageType, i: number) => {
              const gap = ST_SPACER_MD * columnCount;
              const width = ((gridContainerRef.current?.clientWidth || 0) - gap) * (grid[item.index].width / 100);
              const height = width * (item.height / item.width);

              return (
                <div
                  key={image.asset_id}
                  className={styles.cardDragged}
                  style={{
                    transform: i ? `rotateZ(${-i * 2.5}deg)` : 'auto',
                    zIndex: lightboxResources.state.selectedForDrag.length - i,
                  }}
                >
                  <img
                    alt=""
                    className={styles.image}
                    src={getImageUrl({ image, env, property, defaultPreviewUrl: true })}
                    draggable={false}
                    width={width}
                    height={height}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDragLayer;
