import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">Machine-to-Machine Authentication</Typography>
      <MarkdownBlock
        md={`
_Instructions for generating for machine-to-machine authentication for Image Manager._

## Generating a token

To generate a machine-to-machine authentication token.

[![Run in Postman](/run-pstmn-button.svg)](https://app.getpostman.com/run-collection/2390215-e5117a35-6b45-4336-814b-61904fa78a06?action=collection%2Ffork&collection-url=entityId%3D2390215-e5117a35-6b45-4336-814b-61904fa78a06%26entityType%3Dcollection%26workspaceId%3D859a311b-0a9b-4f9f-9cd3-4288c59ab5a7)

### Request parameters

1. \`issuer\` - The complete URL for a Image Manager's Authorization Server.
2. \`basic_token\` - Okta's basic token to authenticate against the issuer is a base64 string containing
   \`{client_id}:{client_secret}\`. This string contains a _client_secret_ and should not be shared or passed over public channels, this _client_secret_ should reside safely on secure server. **This \`basic_token\` should only be user on the server and _NEVER_ the client!**

   - \`client_id\` - the id of the client in the okta app
   - \`client_secret\` - the secret of the client in the okta app

3. \`scope\` - Sets the permissions needed for any given request

You can [ask the Image Manager team our dev slack channel](https://app.slack.com/client/T025QN6JG/CM49J817F) for these values or the Postman collection link.

### Request example

Using these values, you can construct a request for a jwt that can be used to authenticate a request to Image Manager's API with a bearer token:

\`\`\`sh
curl -X POST 
  -H "Accept: application/json" 
  -H "Cache-Control: no-cache" 
  -H "Authorization: Basic {basic_token}" 
  -d "grant_type=client_credentials&scope={scope}" 
  {issuer}/v1/token
\`\`\`

### Response properties

- \`access_token\` _String_ - An access token.
- \`token_type\` _String_ - The audience of the token.
- \`expires_in\` _Integer_ - The expiration time of the access token in seconds.
- \`scope\` _String_ - The scopes contained in the access token.

#### Rate limits

Okta has rate limits setup on their endpoints, to avoid hitting these limits we suggest caching the returned JWT based upon the \`expires_in\` response.

#### Possible errors

- \`invalid_client\` - The specified client_id isn't found.
- \`invalid_grant\` - The code, refresh_token, or username and password combination is invalid, or the redirect_uri doesn't match the one used in the authorization request.
- \`invalid_request\` - The request structure is invalid. For example, the basic authentication header is malformed, both header and form parameters are used for authentication, no authentication information is provided, or the request contains duplicate parameters.
- \`invalid_scope\` - The scopes list contains an invalid or unsupported value.
- \`unsupported_grant_type\` - The grant_type isn't authorization_code, refresh_token, or password.

### Response example (success):

\`\`\`json
{
  "access_token": "eyJhbGciOiJSUzI1NiJ9.....CJrtwgKqLqcWonNtiw",
  "token_type": "Bearer",
  "expires_in": 1800,
  "scope": "dj.tools.test.user"
}
\`\`\`

### Response example (error)

\`\`\`json
HTTP 401 Unauthorized
Content-Type: application/json;charset=UTF-8
{
    "error" : "invalid_client",
    "error_description" : "No client credentials found."
}
\`\`\`
`}
      />
    </>
  );
}

export default DeveloperDocs;
