import { useMemo } from 'react';
import { PropertyType, SearchOptionsType } from '../types';
import { constants } from '../utils';

interface IOptionsFromQueryParamsProps {
  property: PropertyType;
}

// TODO: Update SearchOptionsType to remove the cropped prop
const initialOptions: SearchOptionsType = {
  query: '',
  since: '',
  user: null,
  until: '',
  oneTimeUse: false,
  statusFilter: null,
  advancedFilter: {},
  sourceFilter: [],
  graphicTypesFilter: {},
};

type TUseOptionsFromQueryParamsHook = {
  initialOptions: Partial<SearchOptionsType>;
};

function useOptionsFromQueryParams({ property }: IOptionsFromQueryParamsProps): TUseOptionsFromQueryParamsHook {

  const defaultBrandOptions: Record<string, string | boolean | null> = useMemo(() => {
    const defaultQueryParams = constants.PROPERTIES.getInitialQueryParams(property);
    return {
      ...initialOptions,
      ...defaultQueryParams,
    };
  }, [property]);

  return { initialOptions: defaultBrandOptions };
}

export default useOptionsFromQueryParams;
