import React, { useState } from 'react';
import {
  Typography,
  FormLabel,
  Input,
  PageWrapper,
  Box,
  Button,
  Select,
  Tabs,
  Token,
  IconLoader,
  Table,
  Alert,
  IconInfo,
} from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

import UploadExample from './components/upload';
import { PropertyType } from '../../../types';
import { constants } from '../../../utils';

function DeveloperUpload() {
  const properties = constants.PROPERTY_LABELS;
  const [property, setProperty] = useState<PropertyType>('ydj');
  const [accessToken, setAccessToken] = useState('');

  const [imageId, setImageId] = useState('im-88745765');
  const [deleteImportSource, setDeleteImportSource] = useState('dj.tools.ydj_local_image123456');
  const [deleteType, setDeleteType] = useState('kill');

  const [currentTab, setCurrentTab] = useState('upload');
  const [currentDeleteTab, setCurrentDeleteTab] = useState('data');

  const [apiDeleteRequest, setApiDeleteRequest] = useState<Request>();
  const [apiDeleteRequestBody, setApiDeleteRequestBody] = useState<any>();
  const [apiDeleteResponse, setApiDeleteResponse] = useState<Response>();
  const [apiDeleteResult, setApiDeleteResult] = useState('');

  const cellStyle = { fontFamily: 'var(--st-code-font-stack)', overflow: 'scroll' };

  const deleteImage = () => {
    const body = {
      importSource: deleteImportSource,
      type: deleteType,
    };
    const imRequestOptions = {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    const request = new Request(`/api/${property}/${imageId}`, imRequestOptions);

    setApiDeleteRequest(request);
    setApiDeleteRequestBody(body);

    fetch(request)
      .then((response) => {
        console.log('response: ', response);
        setApiDeleteResponse(response);
        return response.json();
      })
      .then((result) => {
        setApiDeleteResult(result);
        console.log('result: ', result);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setCurrentDeleteTab('request');
      });
  };
  return (
    <PageWrapper>
      <Typography variant="header1">Third Party Endpoints</Typography>
      <Box margin={{ bottom: 'md' }}>
        <Box.Title>Authentication</Box.Title>
        <Box.Content>
          <MarkdownBlock
            md={`

To access the Image Manager API you will need to access token. Image Manager has two types of
endpoints, Internal and Private, most of Image Mangers API endpoints are internal endpoints used by the
Image Manager client and can change at any time. Private endpoints are available to be used with for other
Dow Jones/News Corp. applications.

Image Manager generates two types of JWT access tokens;

1. \`User Access Token\` - this token is generated using the Image Manager UI. The permissions of this token will
  be determined by the roles defined in users profile.
2. \`Machine-to-Machine (M2M) Authentication\` - this machine tokens can be generate by calling the Image Manager
  Authorization Server. M2M tokens will not contain 
  any user information and will the Scope will determined the public endpoint it authorizes. 
  [See our M2M Authentication guild](/developer/api/m2m-authentication) for details on generating an access token. 
  The M2M access token can be cached and used for multiple calls to the Image Manager API. The expiry length of
  the token can be set per scope, by default it is 1 hour.

If you are using M2M you can pass user information in custom headers.

### Additional User Headers

- \`x-djtools-dj-user-id\` - _optional:_ user's SAMAccountName / DJ ID to pass in the header of requests to Image Manager
- \`x-djtools-email\` - _optional:_  user's email to pass in the header of requests to Image Manager
- \`x-djtools-username\` - _optional:_ user's display name to pass in the header of requests to Image Manager

`}
          />
        </Box.Content>
      </Box>

      <Typography variant="header2">Third Party Endpoints</Typography>
      <Box>
        <Box.Title>M2M token</Box.Title>
        <Box.Content>
          <FormLabel label="Property" labelPosition="top">
            <Select
              name="name"
              value={property}
              onChange={(event: any) => {
                setProperty(event.target.value as PropertyType);
              }}
            >
              {' '}
              {Object.keys(properties).map((e) => (
                <option key={e} value={e}>
                  {properties[e as PropertyType]}
                </option>
              ))}
            </Select>
          </FormLabel>
          <FormLabel fullWidth label="Access Token" required>
            <Input
              onChange={({ target }: { target: HTMLInputElement }) => setAccessToken(target.value.trim())}
              onBlur={({ target }: { target: HTMLInputElement }) => {
                setAccessToken(target.value.trim());
              }}
              placeholder={'Access Token'}
              value={accessToken}
              // error={requiredFields.headline ? !headline.length : false}
            />
          </FormLabel>
        </Box.Content>
      </Box>
      <Tabs
        role="tablist"
        onChange={(e: string) => setCurrentTab(e)}
        value={currentTab}
        margin={{ bottom: 'md', top: 'md' }}
      >
        <Tabs.Item role="tab" aria-selected="true" aria-controls="tabpanel-upload" id="tab-upload" value="upload">
          Upload
        </Tabs.Item>
        <Tabs.Item role="tab" aria-selected="true" aria-controls="tabpanel-delete" id="tab-delete" value="delete">
          Delete
        </Tabs.Item>
      </Tabs>

      {currentTab === 'upload' && <UploadExample accessToken={accessToken} property={'ydj' as PropertyType} />}

      {currentTab === 'delete' && (
        <Box id="tabpanel-delete" role="tabpanel" aria-labelledby="tab-delete">
          <Box.Content>
            <Alert inline icon={IconInfo}>
              Use the example to see the request and response data
            </Alert>
            <Tabs
              role="tablist"
              onChange={(e: string) => setCurrentDeleteTab(e)}
              value={currentDeleteTab}
              margin={{ vertical: 'md' }}
            >
              <Tabs.Item value="data">Data</Tabs.Item>
              <Tabs.Item value="try">Example</Tabs.Item>
              <Tabs.Item
                value="request"
                disabled={!apiDeleteResult}
                icon={apiDeleteResult && !apiDeleteResult ? IconLoader : undefined}
              >
                Request
              </Tabs.Item>
            </Tabs>
            {currentDeleteTab === 'data' && (
              <>
                <Typography variant="header3">Delete Properties</Typography>
                <Table>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Path</Table.Cell>
                      <Table.Cell>
                        <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                          {`/api/{:PROPERTY}/{:ID}`}
                        </Typography>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Method</Table.Cell>
                      <Table.Cell>
                        <Token color="lava">DELETE</Token>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan="2" isLabel>
                        Path Parameters
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>PROPERTY</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        Property key <br />
                        <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                          {Object.keys(properties).join(' | ')}
                        </Typography>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>ID</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        Image ID <br />
                        <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }}>
                          Published/IM ID | Public ID | assest ID
                        </Typography>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell colSpan="2" isLabel>
                        Headers
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>authorization</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>Bearer {`{:TOKEN}`}</Token>
                        <Typography variant="note" margin={{ top: 'xs' }}>
                          <Token>TOKEN</Token> User or M2M access token.
                        </Typography>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>Content-Type</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>application/json</Token>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell colSpan="2" isLabel>
                        Body Object
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>importSource</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required for M2M tokens
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                          String
                        </Typography>
                        {' - '}
                        A namespaced identifier of the uploaded image <br />
                        <Typography
                          variant="note"
                          style={{ fontFamily: 'var(--st-code-font-stack)' }}
                          margin={{ bottom: 'xs' }}
                        >
                          {`{:SCOPE}_{:ENVIRONMENT}_{:EXTERNAL_ID}`} | {`{:SCOPE}_{:EXTERNAL_ID}`} (for production)
                        </Typography>
                        <Typography variant="note" margin={{ bottom: 'xs' }}>
                          SCOPE: The scope of the M2M token used to upload the image.
                        </Typography>
                        <Typography variant="note" margin={{ bottom: 'xs' }}>
                          ENVIRONMENT: The current environment "dev" | "int" (not required for production).
                        </Typography>
                        <Typography variant="note">
                          EXTERNAL_ID: The custom value to identity the image set on upload [a-zA-Z0-9].
                        </Typography>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>type</Token>
                        {' - '}
                        <Typography variant="note" inline>
                          Required for M2M tokens
                        </Typography>
                      </Table.Cell>
                      <Table.Cell>
                        <Typography variant="note" style={{ fontFamily: 'var(--st-code-font-stack)' }} inline>
                          String
                        </Typography>
                        {' - '}
                        Import source of the uploaded image <br />
                        <Typography
                          variant="note"
                          style={{ fontFamily: 'var(--st-code-font-stack)' }}
                          margin={{ bottom: 'xs' }}
                        >
                          kill (future options will include: soft | set)
                        </Typography>
                        <Typography variant="note" margin={{ bottom: 'xs' }}>
                          <Token>kill</Token> will immediately and permanently delete a single images from Cloudinary's
                          servers.
                        </Typography>
                        <Typography color="lava" weight="med" variant="note" margin={{ bottom: 'xs' }}>
                          Not yet implimented
                        </Typography>
                        <Typography variant="note" margin={{ bottom: 'xs' }}>
                          <Token style={{ textDecoration: 'line-through' }}>soft</Token> will prevent the image from
                          being accessed publicly or via the tool however a reference will be kep on Cloudinary's
                          servers to prevent the same image being re-uploaded.
                        </Typography>
                        <Typography variant="note">
                          <Token style={{ textDecoration: 'line-through' }}>set</Token> will remove the image set for a
                          published ID. The original image and other image sets will sill be available. The ID passed in
                          the URL must be the published ID.
                        </Typography>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                  Example
                </Typography>
                <Typography variant="note" style={cellStyle}>
                  <pre>{`const body = {
  importSource:,
  type,
};
const options = {
  method: 'DELETE',
  headers: {
    authorization: \`Bearer \${accessToken}\`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
};

const request = new Request(\`/api/\${property}/\${imageId}\`, options);

fetch(request)
  .then((response) => response.json())
  .then((result) => console.log('Success calling delete endpoint: ', result)})
  .catch((error) => console.error('Error calling delete endpoint', error));
`}</pre>
                </Typography>

                <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                  Error Response
                </Typography>
                <Typography variant="note" style={cellStyle}>
                  <pre>{`{
  "ok": false,
  "message": "Unauthorized 001"
}`}</pre>
                </Typography>

                <Typography variant="header4" margin={{ top: 'md', bottom: 'none' }}>
                  Success Response
                </Typography>
                <Typography variant="note" style={cellStyle}>
                  <pre>{`{
  "{:PUBLIC_ID}": "deleted"
}`}</pre>
                  <Token style={{ fontFamily: 'var(--st-code-font-stack)' }}>PUBLIC_ID</Token> The public ID of the
                  deleted image.
                </Typography>
              </>
            )}
            {currentDeleteTab === 'try' && (
              <>
                <FormLabel fullWidth label="Image ID" required>
                  <Input
                    onChange={({ target }: { target: HTMLInputElement }) => {
                      setImageId(target.value.trim());
                    }}
                    placeholder={'Image ID'}
                    value={imageId}
                    // error={requiredFields.headline ? !headline.length : false}
                  />
                </FormLabel>
                <FormLabel fullWidth label="Import Source" required>
                  <Input
                    onChange={({ target }: { target: HTMLInputElement }) => {
                      setDeleteImportSource(target.value.trim());
                    }}
                    placeholder={'{{SCOPE}}_{{ENVIRONMENT}}_{{EXTERNAL_ID}}'}
                    value={deleteImportSource}
                    // error={requiredFields.headline ? !headline.length : false}
                  />
                </FormLabel>
                <FormLabel fullWidth label="Delete Type" required>
                  <Select
                    name="name"
                    value={deleteType}
                    onChange={({ target }: { target: HTMLInputElement }) => {
                      setDeleteType(target.value);
                    }}
                  >
                    <option disabled value="set">
                      set
                    </option>
                    <option disabled value="soft">
                      soft
                    </option>
                    <option value="kill">kill</option>
                  </Select>
                </FormLabel>
                <Button
                  primary
                  margin={{ top: 'md' }}
                  onClick={() => {
                    deleteImage();
                  }}
                >
                  Delete Image
                </Button>
              </>
            )}
            {currentDeleteTab === 'request' && (
              <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell isLabel style={{ width: `120px` }}>
                      Request URL
                    </Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        {apiDeleteRequest?.url}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell isLabel>Method</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        {apiDeleteRequest?.method}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell isLabel>Status Code</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        {apiDeleteResponse?.status} {apiDeleteResponse?.text}
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell isLabel>Headers</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        authorization: Bearer {accessToken.slice(0, 16)}...
                        <br />
                        'Content-Type': application/json
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell isLabel>Body</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        <pre>{JSON.stringify(apiDeleteRequestBody, null, 2)}</pre>
                      </Typography>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell isLabel>Response</Table.Cell>
                    <Table.Cell>
                      <Typography variant="note" style={cellStyle}>
                        <pre>{JSON.stringify(apiDeleteResult, null, 2)}</pre>
                      </Typography>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Box.Content>
        </Box>
      )}
    </PageWrapper>
  );
}

export default DeveloperUpload;
