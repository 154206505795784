import ReactGA from 'react-ga4';

/**
 * component for Google Analytics
 */

function GoogleAnalytics({ analyticsId }: { analyticsId?: string }) {
  if (!analyticsId) return null;

  ReactGA.initialize(analyticsId);
}

export default GoogleAnalytics;
