import { constants } from '../../utils';
import actions from './actions';
import UseUploadType from './types';

export const uploadFilesDefaultState: UseUploadType.Files.State = {
  accepted: [],
  rejected: [],
  status: constants.LOADING_STATES.READY,
};

const uploadFileReducer = (
  state: UseUploadType.Files.State,
  action: UseUploadType.Files.Action
): UseUploadType.Files.State => {
  switch (action.type) {
    /** return state for when we finish fetch */
    case actions.FILES.FINISH_PARSING: {
      return {
        ...state,
        accepted: [...state.accepted, ...action.payload],
        status: constants.LOADING_STATES.READY,
      };
    }

    /** handle rejected files */
    case actions.FILES.REJECTION: {
      return {
        ...state,
        rejected: action.payload,
        status: constants.LOADING_STATES.READY,
      };
    }

    /** return state for when we start fetching from the API */
    case actions.FILES.START_PARSING: {
      return {
        ...state,
        status: constants.LOADING_STATES.LOADING,
      };
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default uploadFileReducer;
