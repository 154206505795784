/**
 * EditMetadataSidebar Component
 *
 * Steps to add a new required field in EditMetadataSidebar:
 * 1. Add the field name to the FORM_VALIDATION array in the publisher config file.
 * 2. Assign the setField function to the key/field you want validation in the fieldFunctionList object.
 * 3. Make sure the key/field exist in FieldFunctionListType Interface, SettersRequiredObj type and settersRequiredObj object.
 *
 */

import { Typography, Group } from '@screentone/core';
import React, { useState, useEffect, SetStateAction } from 'react';
import { isEqual } from 'lodash';
import { EditImageMetaForm, EditImageMetaFormFooter } from '../EditMetadataForm';
import UseUploadType from '../../hooks/useUpload/types';
import type { ImageType, GraphicType } from '../../types';
import { constants } from '../../utils';
import {
  requiredFieldsForPublisher,
  getContextValue,
  valuesInitializer,
  validateObjectProperties,
  getAllPublishedIds,
  getResizeOnlyFlag,
} from '../../utils/helpers';
import useImageDetail from '../../hooks/useImageDetail';
import { useConfig } from '../../hooks/useConfig';
import { CLOUDINARY_MAXIMUM_STRING_LENGTH } from '../../hooks/useUpload/validate';

interface EditMetadataSidebarProps {
  /** set metadata to images */
  onApplyMetadata(encodedPublicId: string, metadata: Partial<ImageType>, currentPublishedId?: string): Promise<unknown>;
}

type SettersRequiredObj = {
  caption: (value: string) => void;
  credit: (value: string) => void;
  // contact: (value: string) => void;
  datePhotographed: (value: string) => void;
  graphicType: (value: GraphicType) => void;
  headline: (value: string) => void;
  oneTimeUse: (value: boolean) => void;
  resizeOnly: (value: boolean) => void;
};

interface FieldFunctionListType {
  headline: string;
  caption: string;
  credit: string;
  datePhotographed: string;
  graphicType: string;
  oneTimeUse: string;
  resizeOnly: string;
}

function EditMetadataSidebar({ onApplyMetadata }: EditMetadataSidebarProps): JSX.Element {
  const { image, publishedId } = useImageDetail();
  const { metadata, tags = [], public_id: publicId } = image;
  const {
    session: { property },
  } = useConfig();
  const requiredFields = requiredFieldsForPublisher(property);
  const allPublishedIds = getAllPublishedIds(image, property);
  const isDisabledResizeOnlyVariant = getAllPublishedIds(image, property).length > 1;
  const isResizeOnlyDisabled = getResizeOnlyFlag(image, property);
  const oneTimeUse = tags?.includes('one_time_use') || false;
  const uncredited = tags?.includes('uncredited') || false;
  const [updatedCaption, setUpdatedCaption] = useState(metadata.caption || '');
  const [updatedCredit, setUpdatedCredit] = useState(metadata.credit || '');
  const [updatedHeadline, setUpdatedHeadline] = useState(metadata.headline || '');
  const [updatedDatePhotographed, setUpdatedDatePhotographed] = useState(metadata.date_photographed || '');
  const [calendarError, setCalendarError] = useState('');
  const [updatedGraphicType, setUpdatedGraphicType] = useState(metadata.graphic_type);
  const [updatedResize, setUpdatedResizeOnly] = useState(isResizeOnlyDisabled);
  const [updatedOneTimeUse, setUpdatedOneTimeUse] = useState(oneTimeUse);
  const [updatedUncredit, setUpdatedUncredited] = useState(uncredited);
  const background = getContextValue(image, 'background', property, publishedId);
  const [updatedBackground, setUpdatedBackground] = useState(background || '');

  useEffect(() => {
    setUpdatedBackground(background || '');
  }, [background]);

  const metadataObj: any = {
    caption: updatedCaption,
    credit: updatedCredit,
    datePhotographed: updatedDatePhotographed,
    graphicType: updatedGraphicType as GraphicType,
    headline: updatedHeadline,
    oneTimeUse: updatedOneTimeUse,
    resizeOnly: updatedResize,
    uncredited: updatedUncredit,
    background: updatedBackground || '',
  };
  const [initialFormFields, setInitialFormFields] = useState(metadataObj);
  const formValidationFields = constants.PROPERTIES.getFormValidationFields(property);
  const initializeValidationFieldValues = valuesInitializer(formValidationFields);

  const [validation, setValidation] = useState<Partial<UseUploadType.Validation.ValidFormFieldsType>>(
    initializeValidationFieldValues
  );
  const [isSaving, setIsSaving] = useState(false);

  const checkEqualToInitial = () => {
    return isEqual(metadataObj, initialFormFields);
  };
  const setOneTimeUse = (target: boolean) => {
    const cleanCaption = updatedCaption?.replace('*** ONE-TIME USE ***', '').trim();
    const newCaption = target ? `*** ONE-TIME USE *** ${cleanCaption || ''}`.trim() : cleanCaption;
    setUpdatedCaption(newCaption);
    setUpdatedOneTimeUse(target);
  };

  const settersRequiredObj: SettersRequiredObj = {
    caption: setUpdatedCaption,
    credit: setUpdatedCredit,
    datePhotographed: setUpdatedDatePhotographed,
    graphicType: setUpdatedGraphicType,
    headline: setUpdatedHeadline,
    oneTimeUse: setUpdatedOneTimeUse,
    resizeOnly: setUpdatedResizeOnly,
  };

  // USED FOR REQUIRED FIELDS
  const setField = <T extends keyof SettersRequiredObj>(fieldValue: string, fieldName: T) => {
    if (!fieldValue && requiredFields[fieldName]) {
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: `${fieldName} is required.`,
      }));
    } else if (fieldValue.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      const excessLength = fieldValue.length - CLOUDINARY_MAXIMUM_STRING_LENGTH;
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: `Caption exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}) by ${excessLength} characters.`,
      }));
    } else {
      setValidation((prevState) => ({
        ...prevState,
        [fieldName]: null,
      }));
    }
    settersRequiredObj[fieldName]?.(fieldValue as never);
  };

  const setUncredited = (target: boolean) => {
    const cleanCredit = updatedCredit?.replace('Uncredited', '').trim();
    const newCredit = target ? 'Uncredited' : cleanCredit;
    setField(newCredit as never, 'credit');
    setUpdatedUncredited(target);
  };

  const fieldFunctionList = {
    headline: setField,
    caption: setField,
    credit: setField,
    // contact: setUpdatedContact,
    datePhotographed: setField,
    graphicType: setUpdatedGraphicType,
    oneTimeUse: setOneTimeUse,
    resizeOnly: setUpdatedResizeOnly,
    uncredited: setUncredited,
    background: setUpdatedBackground,
  };

  // Updating validation and button flags when the Edit Metadata sidebar is opened
  useEffect(() => {
    const ignore = ['contact'];
    // Initialize validation field values
    setValidation(initializeValidationFieldValues);
    // get all required field and run setField function for each one
    Object.keys(requiredFields).forEach((field) => {
      if (!ignore.includes(field)) setField(metadataObj[field] as never, field as keyof FieldFunctionListType);
    });
    setIsSaving(false);
  }, []);

  // useEffect(() => {
  //   console.log('validation:', validation);
  // }, [validation]);
  const setOnApply = () => {
    Object.keys(requiredFields).forEach((field) => {
      if (!metadataObj[field]) {
        setValidation((prevState) => ({
          ...prevState,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
        }));
        setIsSaving(false);
      }
    });
    setIsSaving(true);
    onApplyMetadata(publicId, metadataObj, allPublishedIds[0]).finally(() => {
      setInitialFormFields(metadataObj);
      setIsSaving(false);
    });
  };

  const reset = () => {
    setIsSaving(false);
    // reset state to original
    setUpdatedCaption(metadata.caption || '');
    setUpdatedCredit(metadata.credit || '');
    setUpdatedHeadline(metadata.headline || '');
    setUpdatedDatePhotographed(metadata.date_photographed || '');
    setUpdatedGraphicType(metadata.graphic_type);
    setUpdatedResizeOnly(isResizeOnlyDisabled);
    setUpdatedOneTimeUse(oneTimeUse);
    setUpdatedUncredited(uncredited);
    setValidation(initializeValidationFieldValues);
    setUpdatedBackground(getContextValue(image, 'background', property, publishedId) || '');
    setCalendarError('');
  };
  const disableEditField = { contact: true, slug: true };

  return (
    <>
      <Typography data-testid="edit-metadata-title" variant="label4" margin={{ top: 'sm', horizontal: 'md' }}>
        Edit Metadata
      </Typography>

      <Group direction="column" gap="sm" margin={{ horizontal: 'md' }}>
        <EditImageMetaForm
          disableEditField={disableEditField}
          caption={updatedCaption}
          credit={updatedCredit}
          headline={updatedHeadline}
          graphicType={updatedGraphicType as GraphicType}
          datePhotographed={updatedDatePhotographed}
          oneTimeUse={updatedOneTimeUse}
          disableOneTimeUse={oneTimeUse}
          resizeOnly={updatedResize}
          disableResize={isResizeOnlyDisabled || isDisabledResizeOnlyVariant}
          uncredited={updatedUncredit}
          background={updatedBackground}
          onChange={(target: any) => {
            const keys: any = Object.keys(target);
            const field: keyof FieldFunctionListType = keys[0] || '';
            if (fieldFunctionList[field]) fieldFunctionList[field](target[field] as never, field);
            // console.log('field on change:', field);
            // console.log('keys:', keys);
          }}
          calendarError={calendarError}
          setCalendarError={setCalendarError}
          isDynamic={image.isDynamic}
          isUpload={false}
          validation={validation}
        />
      </Group>

      <EditImageMetaFormFooter
        disableApplyButton={
          isSaving ||
          !!validateObjectProperties(validation, disableEditField) ||
          checkEqualToInitial() ||
          !!calendarError
        }
        onApplyMetadata={setOnApply}
        onCancel={reset}
      />
    </>
  );
}

export default EditMetadataSidebar;
