import React, { useEffect, useRef, useState } from 'react';
import { Group, Dropdown, IconPlusCircle, Button, Loader } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { useConfig } from '../../../hooks/useConfig';
import useAlert from '../../../hooks/useAppAlerts';

import { handleApplyTags } from '../../../utils/helpers';

import type { ImageType } from '../../../types';

type ImageTokenListProps = {
  /** image */
  image: ImageType;
  setImage: (image: ImageType) => void;
  keywords: any;
};

const AddKeyword = ({ image, setImage, keywords }: ImageTokenListProps) => {
  const { setAlert } = useAlert();
  const { userAccess } = useAuth();
  const { authFetch } = useConfig();
  const [updating, setUpdating] = useState<string | boolean>(false);

  const handleApplyTagsHandler = (publicId: string, tags: string[], method: string) => {
    return new Promise((resolve, reject) => {
      handleApplyTags(publicId, tags, authFetch, method)
        .then((data: any) => {
          resolve(data);
          const updateTagList = image.tags ? [...image.tags, ...tags] : [tags];
          if (setImage && updateTagList) {
            const updatedImage = { ...(image as ImageType), ...{ tags: updateTagList } };
            setImage(updatedImage as ImageType);
            setUpdating(false);
          }
        })
        .catch((err: Error) => {
          console.error('handleApplyTags Error: ', err);
          setAlert(err.message || 'Error adding keywords', { type: 'error' });
          reject(err);
          setUpdating(false);
        });
    });
  };

  const { TAGS, ICONS } = keywords;

  const tagButtonRef = useRef<HTMLDivElement>(null);
  const [tagPosition, setTagPosition] = useState('left');

  useEffect(() => {
    if (tagButtonRef.current) {
      const { right } = tagButtonRef.current.getBoundingClientRect();
      const pageWidth = document.documentElement.clientWidth;
      setTagPosition(pageWidth - (right + 220) > 0 ? 'left' : 'right');
    }
  }, [tagButtonRef.current, document.documentElement.clientWidth]);

  return (
    <>
      {userAccess('edit.wires') &&
        TAGS &&
        Object.keys(TAGS).filter((tagKey) => TAGS[tagKey].editable && !image.tags?.includes(TAGS[tagKey].key)).length >
          0 && (
          <div ref={tagButtonRef}>
            <Dropdown position={tagPosition}>
              <Dropdown.Trigger>
                <Button tertiary size="sm" color="gray" icon={IconPlusCircle}>
                  Add Keyword
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content padding={{ all: 'sm' }}>
                {Object.keys(TAGS).map((tagKey) => {
                  const tag = TAGS[tagKey];
                  if (TAGS[tagKey].editable && !image.tags?.includes(tag.key)) {
                    return (
                      <Button
                        tertiary
                        fullWidth
                        disabled={!!updating}
                        icon={updating !== tag.key ? ICONS[tag.icon as keyof typeof ICONS] : null}
                        onClick={() => {
                          setUpdating(tag.key);
                          handleApplyTagsHandler(image.public_id, [tag.key], 'POST');
                        }}
                      >
                        {updating === tag.key && (
                          <Loader style={{ boxSizing: 'border-box' }} size="md" margin={{ right: 'sm' }} />
                        )}
                        Add {tag.label}
                      </Button>
                    );
                  }
                  return null;
                })}
              </Dropdown.Content>
            </Dropdown>
          </div>
        )}
    </>
  );
};

export default AddKeyword;
