import { User } from '@screentone/addon-auth-wrapper';
import actions from '../actions';
import UseUploadType, { Files } from '../types';
import processDroppedFiles from './processDroppedFiles';
import type { PropertyType } from '../../../types';


type HandleImageFileParams = {
  /** an array of images that the user intends to upload to Cloudinary */
  acceptedFiles: Files.Accepted;
  /** a number representing the number of files that are currently accepted */
  currentNumberOfAcceptedFiles: number;
  /** the action dispatcher from the reducer hook */
  dispatch: UseUploadType.Merged.Dispatch;
  /** a array of images that were rejected by the uploader */
  rejectedFiles: Files.Rejected;
  /** user object from useAuth */
  user: User;
  /** current property */
  property: PropertyType;
  /** max number of images allowed to be uploaded */
  maxImages: number;
  /** upload type */
  uploadType: 'dynamic' | 'single';
  authFetch: (resource: string, options?: RequestInit | undefined) => Promise<any>;
};

/**
 * Handle image file(s) being added to the uploader
 */
const handleImageFiles = async ({
  acceptedFiles,
  currentNumberOfAcceptedFiles,
  dispatch,
  rejectedFiles,
  user,
  property,
  maxImages,
  uploadType,
  authFetch,
}: HandleImageFileParams) => {
  dispatch({ type: actions.FILES.START_PARSING });

  const { accepted, rejected, renderData, metadata } = await processDroppedFiles({
    acceptedFiles,
    currentNumberOfAcceptedFiles,
    rejectedFiles,
    user,
    property,
    maxImages,
    uploadType,
    authFetch,
  });

  // handle rejected files
  if (rejected.length) {
    dispatch({
      type: actions.FILES.REJECTION,
      payload: rejected.map(({ errors, file,asset_id }) => ({
        code: errors[0].code, // we only care about the first error
        filename: file.name,
        message: errors[0].message, // we only care about the first error
        path: file.path,
        type: file.type,
        asset_id: asset_id,
      })),
    });
  }

  dispatch({ type: actions.FILES.FINISH_PARSING, payload: accepted });
  dispatch({ type: actions.RENDER.ADD_IMAGES, payload: renderData });
  dispatch({ type: actions.METADATA.ADD_IMAGES, payload: metadata });
  dispatch({ type: actions.VALIDATION.ADD_IMAGES, payload: metadata });
  
  if (uploadType === 'dynamic') {
    dispatch({ type: actions.BULK_METADATA.ADD_ALL });
  }
};

export default handleImageFiles;
