import React, { useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Group,
  Typography,
  Token,
  IconTrash,
  Alert,
  IconCheckCircle,
  IconMinusCircle,
} from '@screentone/core';

import useImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';

function Derived({ meta, assetId }: { meta: any; assetId: string }) {
  const { authFetch } = useConfig();
  const [isDeleted, setIsDeleted] = useState(false);
  const { deleteDerived } = useImageDetail();
  return (
    <Box>
      <Box.Content>
        {!isDeleted && (
          <Grid>
            <Grid.Row>
              <Grid.Col xs={12}>
                <Typography>
                  <Token variant="note" margin={{ top: 'md', bottom: 'lg' }}>
                    {meta.transformation.replace(/\/$/, '')}
                  </Token>
                </Typography>
                <img style={{ maxWidth: '100%', maxHeight: '530px' }} alt="caption" src={meta.secure_url} />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Group direction="row" gap="xs">
                  <Typography size="sm">
                    Format:{' '}
                    <Typography size="sm" weight="bold" inline>
                      {meta.format}
                    </Typography>
                  </Typography>{' '}
                  -{' '}
                  <Typography size="sm">
                    Bytes:{' '}
                    <Typography size="sm" weight="bold" inline>
                      {meta.bytes}
                    </Typography>
                  </Typography>
                </Group>

                <Button
                  tertiary
                  color="lava"
                  icon={IconTrash}
                  margin={{ top: 'md' }}
                  onClick={({ target }: { target: HTMLButtonElement }) => {
                    target.setAttribute('disabled', 'true');
                    target.setAttribute('aria-disabled', 'true');
                    authFetch(`/api/:property/${assetId}/derived/${meta.id}`, {
                      method: 'DELETE',
                    })
                      .then((res) => {
                        return res.json();
                      })
                      .then((data) => {
                        if (data.deleted[meta.id] === 'deleted') {
                          deleteDerived(meta.id);
                          setIsDeleted(true);
                        }
                      })
                      .catch((err) => console.error(err));
                  }}
                >
                  Delete
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        )}
        {isDeleted && (
          <>
            <Typography>
              <Token icon={IconMinusCircle} color="lava" margin={{ top: 'md', bottom: 'lg' }}>
                {meta.transformation.replace(/\/$/, '')}
              </Token>
            </Typography>
            <Alert icon={IconCheckCircle}>This derived version has been deleted</Alert>
          </>
        )}
      </Box.Content>
    </Box>
  );
}

export default Derived;
