// Used to display title, and reset buttons, and toggle between list and grid views
import { Button, Group, Typography, Token, IconRefresh, IconGrid, IconBlocks } from '@screentone/core';
import React from 'react';

import styles from './SearchTitle.module.css';

type SearchTitleProps = {
  /** callback when refresh button is pressed, also controls whether it appears */
  onRefresh(): void;
  /** callback when reset button is pressed, also controls whether it appears */
  onReset(): void;
  /** callback when user switches to grid/list views */
  onSwitchView(view: 'grid' | 'list'): void;
  /** search term, if any */
  query?: string;
  /** current view */
  view: 'grid' | 'list';
  /** if search options such as user, query, date are being used */
  showReset: boolean;
};

function SearchTitle({ onReset, query, showReset, onSwitchView, view, onRefresh }: SearchTitleProps) {
  return (
    <Group margin={{ bottom: 'md' }} valign="end" align="space-between">
      <Group gap="sm" valign="end">
        <Typography variant="header2" data-testid="latest-images-header" margin={{ all: 'none' }}>
          {query ? (
            <>
              Results for <mark className={styles.searchtitle__term}>{query}</mark>
            </>
          ) : (
            'Latest Images'
          )}
        </Typography>
        <Group gap="sm" valign="end" style={{ whiteSpace: 'nowrap' }} margin={{ bottom: 'sm' }}>
          <Button data-testid="refresh-btn" tertiary size="sm" icon={IconRefresh} onClick={onRefresh} color="blurple" />
          {showReset && (
            <Button data-testid="reset-btn" tertiary size="sm" onClick={() => onReset()} color="gray">
              Reset
            </Button>
          )}
        </Group>
      </Group>

      <Group gap="sm" align="end" valign="end" wrap={false} margin={{ bottom: 'sm' }}>
        <Button
          data-testid="gridview-button"
          tertiary
          icon={IconGrid}
          onClick={() => onSwitchView('grid')}
          color="blurple"
          aria-label="Grid View"
          title="Grid View"
          active={view === 'grid'}
        />
        <Button
          data-testid="listview-button"
          tertiary
          icon={IconBlocks}
          onClick={() => onSwitchView('list')}
          color="blurple"
          aria-label="List View"
          title="List View"
          active={view === 'list'}
        />
      </Group>
    </Group>
  );
}

export default SearchTitle;
