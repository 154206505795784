import React from 'react';
import { Helmet as Head } from 'react-helmet';
import { UploadProvider } from '../../../../hooks/useUpload';
import UploadLayout from '../../../../layouts/Upload';

// /[property]/upload
const UploadPage = () => {
  return (
    <>
      <Head>
        <title>Images - Upload</title>
      </Head>
      <UploadProvider>
        <UploadLayout />
      </UploadProvider>
    </>
  );
};

export default UploadPage;
