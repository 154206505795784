import { helpers } from '../../utils';
import actions from './actions';
import UseSearchType from './types';

export const uploadMetadataDefaultState: UseSearchType.Metadata.State = [];

const uploadMetadataReducer = (
  state: UseSearchType.Metadata.State,
  action: UseSearchType.Metadata.Action
): UseSearchType.Metadata.State => {
  switch (action.type) {
    /** add metadata for uploads */
    case actions.METADATA.ADD_IMAGES: {
      return [...state, ...action.payload];
    }

    /** update metadata for uploads */
    case actions.METADATA.EDIT: {
      return helpers.replaceElementInArray(
        state,
        Object.assign(state[action.payload.index], action.payload.metadata),
        action.payload.index
      );
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default uploadMetadataReducer;
