import React, { useState } from 'react';
import { Button, Group, useModalState, useModalPortal, Dialog, FormLabel, Input, Select } from '@screentone/core';

import useConfig from '../../../hooks/useConfig';

type AddCollectionProps = {
  componentEl?: React.ElementType;
  children?: React.JSX.Element | string;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  icon?: React.ElementType;
  images?: any;
  toolTipContent?: string;
};

function CreateCollection({ componentEl: Component = Button, children, images, ...props }: AddCollectionProps) {
  const { open, openModal, closeModal } = useModalState();

  const { authFetch } = useConfig();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [visibility, setVisibility] = useState('private');

  return (
    <>
      <Component
        onClick={() => {
          openModal();
        }}
        {...props}
      >
        {children}
      </Component>
      {open && (
        <Dialog
          onDismiss={(e: Event) => {
            closeModal();
          }}
          status={open ? 'open' : 'closed'}
          // renderNode={renderNode}
        >
          <Dialog.Title id="dialogTitle">Add Collection</Dialog.Title>
          <Dialog.Content id="dialogDesc">
            <Group gap="sm">
              <FormLabel label="Title" labelPosition="top" fullWidth>
                <Input
                  placeholder="Add a title"
                  value={title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(e.target.value);
                  }}
                />
              </FormLabel>
              <FormLabel label="Description" labelPosition="top" fullWidth>
                <Input
                  placeholder="Describe collection uses"
                  value={description}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value);
                  }}
                />
              </FormLabel>
              <FormLabel label="Visibility" labelPosition="top" fullWidth>
                <Select
                  name="name"
                  value={visibility}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setVisibility(e.target.value);
                  }}
                >
                  <option value="private">Private</option>
                  <option value="public">Public</option>
                </Select>
              </FormLabel>
            </Group>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              secondary
              onClick={() => {
                console.log('CLOSE - cancel');
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={() => {
                authFetch(`/api/:property/collections`, {
                  method: 'POST',
                  body: JSON.stringify({
                    title: title,
                    description: description,
                    visibility: visibility,
                    images,
                  }),
                });
                console.log('CLOSE - create');
                closeModal();
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
}

export default CreateCollection;
