import type { ImageType, PropertyType } from '../../types';

import { helpers } from '../../utils';

/**
 * These utils were copied over from the old IM Gallery component logic
 * Given an array of images, it returns an array of percent-based widths for each image
 * Based on a given number of columns per row
 * We have removed the paddingBottom that was used in the previous version
 * since we are now using aspect-ratio and flexbox
 * and because in flexbox, padding-bottom will use the flex container's width as a basis, rather than that individual elem
 */
const MAX_ITEM_HEIGHT = 230;

type GalleryUtilParams = {
  images: ImageType[];
  containerWidth: number;
  columnCount: number;
};

function findRowHeight({ images, containerWidth, columnCount }: GalleryUtilParams, property: PropertyType) {
  const rowAspectRatio = images.reduce((totalAspectRatio, image) => {
    const aspectRatio = helpers.getImageAspectRatio(image, property);
    return totalAspectRatio + aspectRatio;
  }, 0);

  const rowHeight = containerWidth / rowAspectRatio;
  const isLatestBatch = images.length !== columnCount;

  return isLatestBatch ? Math.min(rowHeight, MAX_ITEM_HEIGHT) : rowHeight;
}

function scaleImages({ images, containerWidth, columnCount }: GalleryUtilParams, property: PropertyType) {
  const rowHeight = findRowHeight({ images, containerWidth, columnCount }, property);

  return images.map((image: ImageType) => {
    const aspectRatio = helpers.getImageAspectRatio(image, property);

    const scaledWidth = rowHeight * aspectRatio;
    return {
      width: (scaledWidth / containerWidth) * 100,
    };
  });
}

function buildGrid({ images, columnCount, containerWidth }: GalleryUtilParams, property: PropertyType) {
  let grid: { width: number }[] = [];

  for (let i = 0; i < images.length; i += columnCount) {
    const batch = images.slice(i, i + columnCount);
    const scaled = scaleImages({ images: batch, containerWidth, columnCount }, property);

    grid = [...grid, ...scaled];
  }

  return grid;
}

export default buildGrid;
export { buildGrid };
