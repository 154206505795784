import React from 'react';
import {
  Dropdown,
  Button,
  IconFilter,
  Grid,
  useDropdownState,
  IconCaretDown,
  Token,
  Responsive,
} from '@screentone/core';

import KeywordsFilter from './KeywordsFilter';
import RegionFilter from './RegionFilter';
import CategoriesFilter from './CategoriesFilter';

import type { SearchOptionsType } from '../../../types';

import styles from './AdvancedSearch.module.css';

type AdvancedSearchProps = {
  currentOptions: SearchOptionsType;
  onSearch: (options?: SearchOptionsType) => void;
};

const AdvancedSearch = ({ currentOptions, onSearch }: AdvancedSearchProps) => {
  const { advancedFilter } = currentOptions;

  const {
    open: openAdvancedSearch,
    setOpen: setOpenAdvancedSearch,
    componentRef: advancedSearchComponentRef,
  } = useDropdownState();

  return (
    <>
      <Responsive xs>
        {(matches: boolean) => {
          return (
            <Dropdown
              componentRef={advancedSearchComponentRef}
              onToggle={() => setOpenAdvancedSearch(!openAdvancedSearch)}
              open={openAdvancedSearch}
              position="right"
              padding={{ vertical: 'sm', horizontal: 'md' }}
            >
              <Dropdown.Trigger data-testid="advanced-filter">
                <Button tertiary icon={IconFilter}>
                  Advanced
                  {advancedFilter && Object.keys(advancedFilter).length > 0 ? (
                    <Token data-testid="advanced-filter-number" color="blurple" className={styles.search__caret__icon}>
                      {Object.keys(advancedFilter).length}
                    </Token>
                  ) : (
                    ''
                  )}
                  <IconCaretDown className={styles.search__caret__icon} data-testid="advanced-filter-down-arrow" />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content style={{ zIndex: 'var(--st-zindex-mid)' }}>
                <Grid>
                  {/* <Grid.Row className={matches ? styles.row_wrap : styles.row_nowrap}> */}
                  <Grid.Row className={matches ? styles.row_wrap : styles.row_nowrap}>
                    <Grid.Col className={styles.section}>
                      <RegionFilter onSearch={onSearch} advancedFilter={advancedFilter || {}} />
                    </Grid.Col>
                    <Grid.Col className={styles.section}>
                      <KeywordsFilter onSearch={onSearch} advancedFilter={advancedFilter || {}} />
                    </Grid.Col>
                    <Grid.Col className={styles.section}>
                      <CategoriesFilter onSearch={onSearch} advancedFilter={advancedFilter || {}} />
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Dropdown.Content>
            </Dropdown>
          );
        }}
      </Responsive>
    </>
  );
};

export default AdvancedSearch;
