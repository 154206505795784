import React from 'react';
import { Overlay, useModalPortal } from '@screentone/core';
import { useSearchParams } from 'react-router-dom';

import useEditImage from '../../../hooks/useEditImage';
import useImageDetail from '../../../hooks/useImageDetail';

import ImageCoordinates from './ImageCoordinates';
import ImageHeader from './ImageHeader';
import ImagePreview from './ImagePreview';
import Image from '../../Image';

import { isFrame } from '../../../utils/iframe';

import styles from './Content.module.css';

const Content = () => {
  const { image, publishedId, publishedIdsObj } = useImageDetail();
  const { CONTENT_COMPONENT_CONST, contentComponent, previewType } = useEditImage();
  let [searchParams] = useSearchParams();
  const useOverlay = searchParams.get('overlay') !== 'false';

  const PREVIEW_SIZES = publishedIdsObj[publishedId as string]?.previewSizes || [];

  const imageUrl = PREVIEW_SIZES[previewType]?.url || image.preview_url || image.secure_url;
  const { renderNode } = useModalPortal();

  if (useOverlay || isFrame()) {
    return (
      <>
        <div className={styles.image}>
          <Image src={imageUrl} />
        </div>
        <div>
          <ImagePreview />
        </div>
        {[
          CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY,
          CONTENT_COMPONENT_CONST.THUMB_FOCAL_AREA.KEY,
          CONTENT_COMPONENT_CONST.CROP.KEY,
        ].includes(contentComponent) && (
          <Overlay renderNode={renderNode} status="open">
            <div
              style={
                isFrame()
                  ? { margin: 0, width: '100vw', height: '100vh', overflow: 'auto' }
                  : { maxWidth: '100vw', maxHeight: '100vh', overflow: 'auto' }
              }
            >
              <ImageHeader canEditFocalPoint={true} useOverlay />
              <ImageCoordinates />
              <ImagePreview skipOriginal={!!contentComponent} />
            </div>
          </Overlay>
        )}
      </>
    );
  }
  switch (contentComponent) {
    case CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY:
    case CONTENT_COMPONENT_CONST.THUMB_FOCAL_AREA.KEY:
    case CONTENT_COMPONENT_CONST.CROP.KEY:
      return (
        <>
          <ImageCoordinates />
          <ImagePreview />
        </>
      );
    default:
      return (
        <>
          <div className={styles.image}>
            <Image src={imageUrl} />
          </div>
          <div>
            <ImagePreview />
          </div>
        </>
      );
  }
};

export default Content;
