import React, { useEffect, useState } from 'react';
import { Alert, Group, IconError, PageWrapper, Typography, Grid, Wrapper } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';

import useAssetManager from '../../hooks/useAssetManager';
import { useConfig } from '../../hooks/useConfig';
import useUpload from '../../hooks/useUpload';
import { validateEmptyFields, validateBulkEmptyFields } from '../../hooks/useUpload/validateEmptyFields';

import UploadNavbar from '../../components/Upload/Navbar';
import UploadBox from '../../components/Upload/UploadBox';
import UploadForm from '../../components/Upload/Form';
import UploadDynamicForm from '../../components/Upload/Form/Dynamic';
import UploadStaging from '../../components/Upload/Staging';

import { constants } from '../../utils';
import { getLatestUploadFromLocalStorage, localStorageHelper } from '../../utils/helpers';

import type { ImageType } from '../../types';

import styles from './Upload.module.css';

const UploadLayout = () => {
  const { actions, dispatch, effects, state, type } = useUpload();
  const { setLatestUploads } = useAssetManager();
  const { user } = useAuth();
  const {
    session: { property },
    authFetch,
  } = useConfig();
  const navigate = useNavigate();
  const [onDragOver, setOnDragOver] = React.useState(false);
  const [backToUploadList, setBackToUploadList] = useState('false');

  const { validFiles } = constants;

  const { getRootProps, getInputProps } = useDropzone({
    accept: validFiles[type],
    maxSize: constants.MAX_FILE_SIZE,
    onDrop: (files: File[], errors: FileRejection[]) => {
      effects.handleImageFiles(files, errors);
      setOnDragOver(false);
    },
    onDragOver: (e) => {
      // console.log('onDragOver e: ', e);
      setOnDragOver(true);
    },
    onDragLeave: (e) => {
      // console.log('onDragLeave e: ', e);
      setOnDragOver(false);
    },
  });

  const backButton = () => {
    if (!location.pathname.includes('/upload')) {
      localStorageHelper.deleteItem(property);
    }

    if (!state.navigation) {
      navigate(-1);
    } else {
      dispatch({ type: actions.NAVIGATION.BACK });
    }
  };

  const publishButton = () => {
    // add files
    if (!state.navigation && state.files.accepted.length) {
      dispatch({ type: actions.NAVIGATION.FORWARD });
    }

    // upload images to cloudinary
    const djUserId = (user?.dj_user_id && user?.dj_user_id.toLowerCase()) || '';
    if (state.navigation === 1 || state.navigation === 2) {
      if (state?.files?.accepted.length === 1) {
        const image = state?.upload?.response[0];
        navigate(`/${property}/image/${state?.upload?.response[0].asset_id}`, { state: { image } });
      } else if (state?.files?.accepted.length > 1) {
        navigate(`/${property}?user=${djUserId}`);
      }
    } else if (backToUploadList === 'true') {
      navigate(`/${property}?user=${djUserId}`);
    }
    return null;
  };

  const [, hasErrors] =
    type === 'dynamic'
      ? validateBulkEmptyFields(state.bulk.validation, property)
      : validateEmptyFields(state.validation, property);
  const canPublish =
    (!state.navigation && state.files.accepted.length) ||
    backToUploadList === 'true' ||
    ((state.navigation === 1 || state.navigation === 2) &&
      state.upload.status.every((status) => status === 'complete'));

  useEffect(() => {
    // Dupe code from PageNavigation
    console.log('TODO UPDATE: UPLOAD getLatestUploadFromLocalStorage'); // eslint-disable-line no-console
    getLatestUploadFromLocalStorage(property, async (id) => await authFetch(`/api/:property/${id}`))
      .then((images: ImageType[]) => {
        setLatestUploads(images);
      })
      .catch((err) => {
        console.error('getLatestUploadFromLocalStorage: ', err);
      });
  }, [property]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => state.files.accepted.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [state.files.accepted]);

  const { onClick, ...others } = getRootProps();
  useEffect(() => {
    const backToUploadListValue = localStorage.getItem(`${property}:backToUploadList`) || 'false';
    setBackToUploadList(backToUploadListValue);
  }, [localStorage.getItem(`${property}:backToUploadList`)]);

  return (
    <div className={`${styles.pageWrapper} ${onDragOver ? styles.onDragOver : ''}`} {...others}>
      <UploadNavbar
        status={state.upload.status}
        response={state.upload.response}
        onButtonLeft={state.navigation < 1 ? backButton : null}
        onButtonRight={canPublish && !hasErrors ? publishButton : null}
        stepActive={state.navigation + 1}
        stepComplete={state.navigation}
        type={type}
      />
      <div className={styles.pageWrapperContent}>
        {!state.navigation && (
          <>
            <PageWrapper>
              <div>
                {!!state.files.rejected.length && (
                  <Grid>
                    <Grid.Row center="xs">
                      <Grid.Col md={state.files.accepted.length ? 10 : 8} xs={12}>
                        <div className={styles.errorWrapper}>
                          <Group
                            align="stretch"
                            className={styles.errorContainer}
                            direction="column"
                            data-testid="error-alert"
                            gap="sm"
                            margin={{ vertical: 'md' }}
                          >
                            {state.files.rejected.map((rejectedFile, i) => {
                              const link = rejectedFile.code.includes('image-exists')
                                ? `/${property}/image/${rejectedFile.asset_id}`
                                : '';
                              return (
                                <Alert
                                  icon={IconError}
                                  key={rejectedFile.path}
                                  onDismiss={() => {
                                    // filter out error that was dismissed
                                    dispatch({
                                      type: actions.FILES.REJECTION,
                                      payload: state.files.rejected.filter((_, j) => i !== j),
                                    });
                                  }}
                                  type={link.length ? 'primary' : 'error'}
                                >
                                  {rejectedFile.message} - {rejectedFile.filename}
                                  {link.length && (
                                    <Link to={link} target="_blank">
                                      View i mage
                                    </Link>
                                  )}
                                </Alert>
                              );
                            })}
                          </Group>
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                )}
                {!state.files.accepted.length && backToUploadList === 'false' && (
                  <Grid>
                    <Grid.Row>
                      <Grid.Col md={8} xs={12} mdOffset={2}>
                        <Wrapper margin={{ bottom: 'mlg' }}>
                          <Typography
                            margin={{ bottom: 'sm', top: 'lg' }}
                            variant="header3"
                            data-testid="promo-image-upload-msg-header"
                          >
                            Upload {type === 'dynamic' ? 'Promo Image' : ''}
                          </Typography>
                          <Typography data-testid="promo-image-upload-msg-txt">
                            {type === 'dynamic'
                              ? 'Promo images are dynamic images, featuring various-sized images grouped under one ID. When requested, the relevant size is delivered with extra padding. '
                              : ''}
                          </Typography>
                          <Typography data-testid="promo-image-upload-limit">
                            Select or drag up to {constants.MAX_FILES_IN_UPLOADER[type]} images to begin.
                          </Typography>
                        </Wrapper>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row center="xs">
                      <Grid.Col xs={12} md={8}>
                        <UploadBox handleBrowseFiles={onClick} />
                        <input data-testid="file-input-inside" {...getInputProps()} />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                )}
                {!!state.files.accepted.length && (
                  <>
                    {type === 'dynamic' ? (
                      <UploadDynamicForm handleBrowseFiles={onClick} />
                    ) : (
                      <UploadForm handleBrowseFiles={onClick} />
                    )}
                    <input data-testid="file-input-metadata" {...getInputProps()} />
                  </>
                )}
              </div>
            </PageWrapper>
            <input data-testid="file-input-outside" {...getInputProps()} />
          </>
        )}

        {(state.navigation === 1 || state.navigation === 2 || backToUploadList === 'true') && <UploadStaging />}
      </div>
    </div>
  );
};

export default UploadLayout;
