import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">Developer Docs</Typography>
      <MarkdownBlock
        md={`
  Welcome to our developer documentation. This guide is intended for developers who
  need to integrate with Image Manager and handle image data.

  Image Manager is designed to return crops of any size needed by the client, utilizing Cloudinary algorithm. 
  However, not all images can be cropped, so Image Manager supports various image types. Images should be requested
  at the size they will be displayed to ensure the correct image size is delivered. For more information on requesting 
  the correct image size, refer to our [Image URL Structure](/developer/images/url-structure) documentation.
  
  Image Manager supports multiple properties, and each property has its own [Image Domains](/developer/images/domains).

  ## Integration Options

  There are several ways to integrate with Image Manager:

  - [**Iframe Integration:**](/developer/iframe) Open Image Manager within an iframe within your application. This option provides users with access to the complete feature set of Image Manager and will pass information back to your application.
  - [**Snippety Integration:**](/developer/snippety) Access an individual image's information via the Image URL, using our internal Snippety service to retrieve basic information about the image. This option is excellent if you know the \`IM-ID\` of the image you need information for.
  - [**API Integration:**](/developer/api) Access cropped images via a REST API. This option provides access to all cropped/published images with a basic search option to integrate with an existing service.
  `}
      />
    </>
  );
}

export default DeveloperDocs;
