import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success
type CopySupported = boolean;
type ErrorValue = string | null;

// hook for copying string into browser clipboard
// copied from https://usehooks-ts.com/react-hook/use-copy-to-clipboard
function useCopyToClipboard(): [CopiedValue, CopyFn, CopySupported, ErrorValue] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const [error, setError] = useState<string | null>(null);
  const copySupported = !!navigator?.clipboard;
  const copy: CopyFn = async (value: string | { text: string; html: string }) => {
    if (!copySupported) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      if (typeof value === 'string') {
        await navigator.clipboard.writeText(value);
        setCopiedText(value);
      } else {
        const clipboardItem = new ClipboardItem({
          'text/html': new Blob([value.html], { type: 'text/html' }),
          'text/plain': new Blob([value.text], { type: 'text/plain' }),
        });

        await navigator.clipboard.write([clipboardItem]);
        setCopiedText(value.text);
      }
      // hide message
      setTimeout(() => {
        setCopiedText(null);
      }, 1000);
      return true;
    } catch (err) {
      console.warn('Copy failed', err);
      setCopiedText(null);
      setError('Copy failed!');
      // hide message
      setTimeout(() => {
        setCopiedText(null);
        setError(null);
      }, 1000);
      return false;
    }
  };

  return [copiedText, copy, copySupported, error];
}

export default useCopyToClipboard;
