import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  Button,
  useDropdownState,
  IconImageGallery,
  IconCaretDown,
  FormLabel,
  Checkbox,
  Group,
  Token,
} from '@screentone/core';
import { SearchOptionsType } from '../../../types';
import { getGraphicTypeOptions } from '../../../utils/helpers';

type GraphicTypeFilterProps = {
  currentOptions: SearchOptionsType;
  onSearch: (options: SearchOptionsType) => void;
};

const GraphicTypeFilter: React.FC<GraphicTypeFilterProps> = ({ currentOptions, onSearch }) => {
  const { open, setOpen, componentRef } = useDropdownState();
  const { graphicTypesFilter } = currentOptions;
  const graphicTypesOptions = getGraphicTypeOptions();
  const [graphicOptionsState, setGraphicOptionState] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setGraphicOptionState((graphicTypesFilter as { [key: string]: boolean }) || {});
  }, [graphicTypesFilter]);

  const handleGraphicTypeOptionsSelected = (filterOption: string) => {
    const newFilterOptionsStates = {
      ...(graphicOptionsState || {}),
      [filterOption]: !graphicOptionsState?.[filterOption],
    };

    // Filter object for false values and only include them if the value is true.
    const filteredOptions = Object?.entries(newFilterOptionsStates || {}).reduce(
      (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
      {},
    );

    setGraphicOptionState(filteredOptions);

    onSearch({ graphicTypesFilter: filteredOptions });
  };

  const isActive = graphicTypesOptions?.some((option) => option.graphicKey && graphicOptionsState?.[option.graphicKey]);

  return (
    <Dropdown padding={{ all: 'none' }} componentRef={componentRef} onToggle={() => setOpen(!open)} open={open}>
      <Dropdown.Trigger>
        <Button data-testid="graphic-type-dropdown" tertiary icon={IconImageGallery} active={isActive}>
          Type
          <IconCaretDown style={{ marginLeft: 'var(--st-spacer-xs)' }} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        style={{
          boxSizing: 'border-box',
          width: '15rem',
        }}
        padding={{ all: 'none' }}
      >
        <Group gap="xs" margin={{ all: 'sm' }} direction="column">
          {graphicTypesOptions?.map(({ graphicKey, label }) => {
            return (
              <FormLabel data-testid="type-checkbox" label={label} labelPosition="right" fullWidth key={graphicKey}>
                <Checkbox
                  checked={graphicOptionsState?.[graphicKey] || false}
                  onChange={() => handleGraphicTypeOptionsSelected(graphicKey)}
                />
              </FormLabel>
            );
          })}
          <Group.Item fullWidth>
            <Button
              data-testid="clear-published-status"
              size="sm"
              tertiary
              onClick={() => {
                setGraphicOptionState({});
                onSearch({ graphicTypesFilter: {} });
              }}
            >
              Clear
            </Button>
          </Group.Item>
        </Group>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default GraphicTypeFilter;
