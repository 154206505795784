function Node(data) {
  this.data = data;
  this.next = null;
}

function Queue() {
  this.head = null;
  this.tail = null;
}

Queue.prototype.enqueue = function (data) {
  const newNode = new Node(data);
  if (this.head === null) {
    this.head = newNode;
    this.tail = newNode;
  } else {
    this.tail.next = newNode;
    this.tail = newNode;
  }
};

Queue.prototype.dequeue = function () {
  let newNode;
  if (this.head !== null) {
    newNode = this.head.data;
    this.head = this.head.next;
  }
  return newNode;
};

Queue.prototype.dequeueAll = function () {
  if (this.head !== null) {
    while (this.head) {
      this.head = this.head.next;
    }
    this.tail = null;
  }
};

export default Queue;
