import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@screentone/core';

import useImageDetail from '../../../../hooks/useImageDetail';
import useEditImage from '../../../../hooks/useEditImage';
import { slugNormalize } from '../../../../utils/helpers';

import styles from './Slug.module.css';

const Slug = ({ disabled, panelKey }: { disabled: boolean; panelKey: string }) => {
  const { publishedIdsObj, publishedId, localContext } = useImageDetail();
  const { setData } = useEditImage();

  const initialValue = (publishedId && publishedIdsObj[publishedId]?.label) || '';

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const localContextValue = localContext[`published_label_${publishedId}`];

    if (localContextValue === null || localContextValue === undefined) {
      setValue(initialValue);
    } else {
      setValue(localContextValue);
    }
  }, [publishedId, localContext, initialValue]);

  return (
    <>
      <Input
        data-testid="slug-input"
        componentRef={inputRef}
        className={styles.slug}
        value={value}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const normalizedValue = slugNormalize(event.target.value);
          setValue(normalizedValue);
          setData('slug', normalizedValue, panelKey);
        }}
      />
    </>
  );
};

export default Slug;
