import React, { useContext, useState, useReducer, createContext, useEffect } from 'react';
import { ImageType } from '../../types';
import useConfig from '../useConfig';
import { localStorageHelper } from '../../utils/helpers';

const AssetManagerContext = createContext<AssetManagerContextType>({
  getImage: function (imageId: string): ImageType {
    throw new Error('Function not implemented.');
  },
  setImage: function (image: ImageType): void {
    throw new Error('Function not implemented.');
  },
  setImages: function (images: ImageType[]): void {
    throw new Error('Function not implemented.');
  },
  setLatestUploads: function (images: ImageType[]): void {
    throw new Error('Function not implemented.');
  },
  getLastUploadedImages: function (): string[] {
    throw new Error('Function not implemented.');
  },
});

interface BaseConfigProviderProps {
  children: React.ReactNode;
}

interface AssetManagerContextType {
  getImage: (imageId: string) => ImageType;
  setImage: (image: ImageType) => void;
  setImages: (images: ImageType[]) => void;
  setLatestUploads: (images: ImageType[]) => void;
  getLastUploadedImages: () => string[];
}

export function AssetManagerProvider({ children }: BaseConfigProviderProps) {
  const {
    session: { property },
  } = useConfig();
  const [images, setImagesFn] = useState(new Map());

  const [latestUploads, setLatestUploadsFn] = useReducer((prev: any, ids: any) => {
    return { ...prev, ...{ [property]: ids } };
  }, []);

  const getImage = (imageId: string) => {
    let image = images.get(`${property}_${imageId}`);
    if (!image) {
      return null;
    }
    return image;
  };

  const setImage = (image: ImageType) => {
    setImagesFn((prev) => new Map(prev.set(`${property}_${image.asset_id}`, image)));
  };

  const setImages = (images: ImageType[]) => {
    images.map((image) => {
      setImage(image);
    });
  };

  const setLatestUploads = (images: ImageType[]) => {
    if (images.length === 0) return;

    const latestUploadIds = images.map((image) => {
      setImage(image);
      return image.asset_id;
    });

    localStorageHelper.createItem(property, latestUploadIds);
    setLatestUploadsFn(latestUploadIds);
  };

  const value: AssetManagerContextType = {
    getImage,
    setImage,
    setImages,
    setLatestUploads,
    getLastUploadedImages: () => latestUploads[property] || [],
  };
  return <AssetManagerContext.Provider value={value}>{children}</AssetManagerContext.Provider>;
}

export function useAssetManager() {
  const context = useContext(AssetManagerContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}

export default useAssetManager;
