// prefixes
export const ALL = 'all::';
export const RENDER_PREFIX = 'render::';
export const UPLOAD_FILES_PREFIX = 'uploadFile::';
export const UPLOAD_METADATA_PREFIX = 'uploadMetadata::';
export const UPLOAD_NAVIGATION_PREFIX = 'uploadNavigation::';
export const UPLOAD_PREFIX = 'upload::';
export const UPLOAD_BULK_METADATA_PREFIX = 'uploadBulkMetadata::';
export const VALIDATION_PREFIX = 'validation::';

const useUploadActions = Object.freeze({
  // all
  ALL: Object.freeze({
    REMOVE_IMAGES: `${ALL}removeImages`,
    RESET_UPLOAD: `${ALL}resetUpload`,
  }),

  // image files
  FILES: Object.freeze({
    FINISH_PARSING: `${UPLOAD_FILES_PREFIX}finishParsing`,
    REJECTION: `${UPLOAD_FILES_PREFIX}rejection`,
    REMOVE_IMAGE: `${UPLOAD_FILES_PREFIX}removeImage`,
    START_PARSING: `${UPLOAD_FILES_PREFIX}startParsing`,
  }),

  // metadata
  METADATA: Object.freeze({
    ADD_IMAGES: `${UPLOAD_METADATA_PREFIX}addImages`,
    EDIT: `${UPLOAD_METADATA_PREFIX}edit`,
    REMOVE_IMAGE: `${UPLOAD_METADATA_PREFIX}removeImage`,
    COLLAPSE: `${UPLOAD_METADATA_PREFIX}collapse`,
  }),

  // metadata
  BULK_METADATA: Object.freeze({
    ADD_IMAGE: `${UPLOAD_BULK_METADATA_PREFIX}addImages`,
    ADD_ALL: `${UPLOAD_BULK_METADATA_PREFIX}addAll`,
    EDIT: `${UPLOAD_BULK_METADATA_PREFIX}edit`,
    REMOVE_IMAGE: `${UPLOAD_BULK_METADATA_PREFIX}removeImage`,
    REMOVE_ALL: `${UPLOAD_BULK_METADATA_PREFIX}removeAll`,
    APPLY_METADATA: `${UPLOAD_BULK_METADATA_PREFIX}applyMetadata`,
    REFRESH_BULK_EDIT_FORM: `${UPLOAD_BULK_METADATA_PREFIX}refreshBulkEditForm`,
    VALIDATION: `${UPLOAD_BULK_METADATA_PREFIX}validate`,
  }),

  // navigation
  NAVIGATION: Object.freeze({
    BACK: `${UPLOAD_NAVIGATION_PREFIX}back`,
    FORWARD: `${UPLOAD_NAVIGATION_PREFIX}forward`,
  }),

  // rendering
  RENDER: Object.freeze({
    ADD_IMAGES: `${RENDER_PREFIX}addImages`,
    REMOVE_IMAGE: `${RENDER_PREFIX}removeImages`,
    UPDATE_IMAGE_DATA: `${RENDER_PREFIX}updateImageData`,
  }),

  // upload
  UPLOAD: Object.freeze({
    ERROR: `${UPLOAD_PREFIX}error`,
    FINISH_FETCH: `${UPLOAD_PREFIX}finishFetch`,
    INITIALIZE: `${UPLOAD_PREFIX}initialize`,
    START_FETCH: `${UPLOAD_PREFIX}startFetch`,
  }),

  // validation
  VALIDATION: Object.freeze({
    ADD_IMAGES: `${VALIDATION_PREFIX}addImages`,
    ALL: `${VALIDATION_PREFIX}all`,
    BLUR: `${VALIDATION_PREFIX}blur`,
    CUSTOM: `${VALIDATION_PREFIX}custom`,
    EDIT: `${VALIDATION_PREFIX}edit`,
    REMOVE_IMAGE: `${VALIDATION_PREFIX}removeImage`,
  }),
});

export default useUploadActions;
