import { ALL_USERS, MY_IMAGES } from '../../../utils/constants';

import type { User } from '@screentone/addon-auth-wrapper';
import type { ActivityUserType } from '../../../types';

export function isSessionUserInUsers(userList: ActivityUserType[], sessionUser: User) {
  return userList.some(
    (current: ActivityUserType) => current.dj_user_id?.toLowerCase() === sessionUser.dj_user_id?.toLowerCase()
  );
}

export function createDefaultOptions(userList: ActivityUserType[], sessionUser: User) {
  const showMyUploads = isSessionUserInUsers(userList, sessionUser);
  let returnArr: ActivityUserType[] = [
    {
      display_name: ALL_USERS,
      dj_user_id: ALL_USERS,
      email: '',
    },
  ];
  if (showMyUploads) {
    returnArr.push({
      display_name: MY_IMAGES,
      dj_user_id: sessionUser.dj_user_id.toLowerCase(),
      email: sessionUser?.email?.toLowerCase(),
    });
    returnArr = returnArr.reverse();
  }
  return returnArr;
}

export function getSessionUserInUsers(userList: ActivityUserType[], sessionUser: User) {
  return userList.find(
    (current: ActivityUserType) =>
      current.dj_user_id === sessionUser.dj_user_id?.toLowerCase()
  );
}

export function getUserFromQueryParam(userList: ActivityUserType[], userId?: string) {
  return userList.find((current: ActivityUserType) => current.dj_user_id === userId);
}

export function getSessionUserFromQueryParam(userQueryParam: ActivityUserType, sessionUserId?: string) {
  return userQueryParam.dj_user_id === (sessionUserId && sessionUserId.toLowerCase());
}

export function displayIfNotCurrentUserOrMyUploads(selectedUser: ActivityUserType | null, sessionUser: User) {
  return (
    selectedUser?.dj_user_id !== ALL_USERS &&
    selectedUser?.display_name !== MY_IMAGES &&
    selectedUser?.dj_user_id &&
    sessionUser.dj_user_id.toLowerCase() !== selectedUser?.dj_user_id
  );
}

export function selectedUserNotAllUsersNotMyUploads(selectedUser: ActivityUserType | null) {
  return (
    selectedUser?.dj_user_id !== ALL_USERS &&
    selectedUser?.display_name !== MY_IMAGES &&
    selectedUser?.dj_user_id &&
    selectedUser.dj_user_id.trim() !== ''
  );
}

export function filterUsers(
  userList: ActivityUserType[],
  selectedUser: ActivityUserType | null | undefined,
  query: string,
  sessionUser: User
) {
  let allUsers = [];

  allUsers = userList.filter(
    (user: ActivityUserType) => (sessionUser && sessionUser?.dj_user_id?.toLowerCase()) !== user.dj_user_id
  );

  if (selectedUser && selectedUserNotAllUsersNotMyUploads(selectedUser)) {
    allUsers = allUsers.filter(
      (user: ActivityUserType) => (selectedUser && selectedUser.dj_user_id) !== user.dj_user_id
    );
  }

  return query
    ? allUsers.filter((user: ActivityUserType) => user.display_name.toLowerCase().includes(query.toLowerCase()))
    : allUsers;
}
