import React, { useEffect } from 'react';
import { Typography } from '@screentone/core';

import MarkdownBlock from '../../../components/MarkdownBlock';

function Iframe() {
  return (
    <>
      <Typography variant="header1">iFrame - Integration</Typography>
      <MarkdownBlock
        md={`
Teams wishing to integrate with image manager via a iframe will need to reach out to
[the Image Manager team our dev slack channel](https://app.slack.com/client/T025QN6JG/CM49J817F) so we can white list there domains.

The \`entry\` query will hide the top Image Manager header when \`search\` is passed.

If the user is not logged in to image manager they will be prompted to login in. Once authenticated the user will be shown the image manager search page where they can use the tool to find or upload an image. If there image has not been cropped the user can go through the cropping flow.

Once the user finds their desired cropped image, there will be an \`Add to article\` button. This button is only visible within the iframe. Once clicked the following payload will be posted to the parent frame:

When editing an image you can use the \`imageDetailsHref\` provided in the initial payload with a query string of \`{BASE_URL}?ref={YOUR_REF}&entry=image...\`

### URLs

- **Base URL** - \`https://images.{(<environment>).?}.dowjones.io/{property}/\`

### Entry URLs

- **Search** - \`{BASE_URL}?{QUERY}\`
- **Upload** - \`{BASE_URL}upload?{QUERY}\`

### Query Options

- **ref** - a reference that will be returned with each image payload, if an array is returned \`_{index}\` will be appended to the ID.

- **source** - \`NP-Article\` || \`NP-GWS\` || \`NP-GALLERY\`

  - \`NP-Article\` - Will return a single image as an object and will show the default preview on the Image Details page.
  - \`NP-GWS\` - Will return an array of image objects and will update the Image Details preview to show the vertical Google Web Stories Crop.
  - \`NP-GALLERY\` - Will return an array of image objects and will update the Image Details preview to add images into a NewsPress Gallery.

_Please reach out to the Image Manager team to configure different options_
`}
      />
    </>
  );
}

export default Iframe;
