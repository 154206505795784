import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  Group,
  IconPlusCircle,
  Token,
  Tabs,
  Typography,
  useAnimatedModalState,
  useModalPortal,
  FormLabel,
  Input,
  FormHelperText,
  IconCheckCircle,
  useModalState,
} from '@screentone/core';
import { useParams, useNavigate } from 'react-router-dom';

import useImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';
import useAlert from '../../../hooks/useAppAlerts';

import { constants } from '../../../utils';
import { getImageIndexAndCount, getLastPublished, slugNormalize } from '../../../utils/helpers';
import { useAuth } from '@screentone/addon-auth-wrapper';
import useAssetManager from '../../../hooks/useAssetManager';
import useSearch from '../../../hooks/useSearch';
import { ImageType } from '../../../types';
import { getResizeOnlyFlag } from '../../../utils/helpers';
import useEditImage from '../../../hooks/useEditImage';

function ImageTabBar() {
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const { user, userAccess } = useAuth();
  const { getLastUploadedImages } = useAssetManager();
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const { mode, im: publishedIdFromUrl, type: pageType } = useParams();
  const isBatchFlow = pageType === 'batch';
  const {
    image,
    setImage: setDetailsImage,
    publishedIdsObj,
    setPublishedId,
    publishedId,
    transformationType,
  } = useImageDetail();
  const { isDirty, resetData, publishData, isSaving, setIsSaving } = useEditImage();
  const { setImage: setSearchImage } = useSearch();

  const { open, status, componentRef, openModal, closeModal } = useAnimatedModalState();
  const { open: openDirty, openModal: openModalDirty, closeModal: closeModalDirty } = useModalState();
  const { renderNode } = useModalPortal();

  const [publishedLabel, setPublishedLabel] = useState('');
  const [publishedDescription, setPublishedDescription] = useState('');

  const [isPublishDisabled, setPublishDisabled] = useState(false);
  const setImage = (image: ImageType) => {
    setDetailsImage(image);
    setSearchImage(image);
  };

  const pageMode =
    constants.IMAGE_PAGE_MODE[mode?.toUpperCase() as keyof typeof constants.IMAGE_PAGE_MODE] ||
    constants.IMAGE_PAGE_MODE.DETAILS;

  const lastUploadedImages = getLastUploadedImages();
  const isOneTimeUse = image.tags?.includes('one_time_use') || false;
  const [selectedTab, setSelectedTab] = useState(publishedIdFromUrl || 'original');
  const [tabToSelect, setTabToSelect] = useState('');

  useEffect(() => {
    setSelectedTab(publishedIdFromUrl || 'original');
  }, [publishedIdFromUrl]);

  useEffect(() => {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      setSelectedTabOnDismiss(selectedTab);
      if (isDirty()) {
        setLinkTo(`/${property}`);
        openModalDirty();
      } else {
        navigate(`/${property}`);
      }
    };
  }, [isDirty()]);

  const canAddImageSet =
    (transformationType === null || !!['cldDefault'].includes(transformationType)) &&
    image?.format !== 'gif' &&
    image?.format !== 'svg' &&
    (user?.dj_user_id.toLowerCase() === image?.metadata?.dj_user_id ||
      userAccess([`${property}.edit`, `${property}.edit.crop`]));

  const isResizeOnly = getResizeOnlyFlag(image, property);
  const { index, count } = getImageIndexAndCount(lastUploadedImages, image.asset_id, property);
  const [linkTo, setLinkTo] = useState('');
  const [openImageSetModal, setOpenImageSetModal] = useState(false);
  const [selectedTabOnDismiss, setSelectedTabOnDismiss] = useState('');

  return (
    <>
      {pageMode === constants.IMAGE_PAGE_MODE.EDIT && isBatchFlow ? (
        <>
          <Group.Item grow={1}>
            <Typography margin={{ all: 'none' }} variant="header3">
              Image Adjustments
            </Typography>
          </Group.Item>
          <Group.Item>
            <Typography variant="note" margin={{ right: 'xs' }}>
              Image {index} of {count}
            </Typography>
          </Group.Item>
        </>
      ) : (
        <>
          <Group.Item grow={1}>
            <Tabs
              role="tablist"
              value={selectedTab}
              onChange={(id: string) => {
                setSelectedTabOnDismiss(selectedTab);

                let link = '';
                if (id === 'original') {
                  link = `/${property}/image/${image.asset_id}`;
                } else {
                  link = `/${property}/image/${image.asset_id}/${constants.IMAGE_PAGE_MODE.DETAILS}/${id}`;
                }

                if (isDirty()) {
                  setLinkTo(link);
                  setTabToSelect(id);
                  openModalDirty();
                } else {
                  navigate(link);
                  setTabToSelect(id);
                  setPublishedId(id);
                }
              }}
            >
              {!image.isDynamic && (
                <Tabs.Item
                  data-testid="original-tab"
                  role="tab"
                  value="original"
                  aria-selected={selectedTab === 'original' ? 'true' : 'false'}
                  replace
                >
                  Original
                </Tabs.Item>
              )}
              {Object.keys(publishedIdsObj).map((id) => {
                return (
                  <Tabs.Item
                    data-testid="tab-name"
                    value={id}
                    role="tab"
                    key={id}
                    aria-selected={selectedTab === id ? 'true' : 'false'}
                    replace
                  >
                    {publishedIdsObj[id].label}
                    <Token
                      data-testid="tab-image-id"
                      color={selectedTab === id ? 'blurple' : undefined}
                      margin={{ left: 'sm' }}
                    >
                      {id}
                    </Token>
                  </Tabs.Item>
                );
              })}
            </Tabs>
          </Group.Item>

          <Group.Item style={{ borderBottom: '1px solid var(--st-border-color)', paddingBottom: '3px' }}>
            {!!publishedId && !(image.isChart || image.isDynamic) && (
              <>
                <Button
                  tertiary
                  icon={IconPlusCircle}
                  disabled={!canAddImageSet || isResizeOnly || isOneTimeUse}
                  onClick={() => {
                    if (isDirty()) {
                      setLinkTo('');
                      setOpenImageSetModal(true);
                      openModalDirty();
                    } else {
                      openModal();
                    }
                  }}
                  data-testid="add-image-set"
                >
                  Add Image Set
                </Button>
                {open && (
                  <Dialog
                    aria-labelledby="dialogTitle"
                    aria-describedby="dialogDesc"
                    onDismiss={closeModal}
                    renderNode={renderNode}
                    status={status}
                    componentRef={componentRef}
                  >
                    <Dialog.Title id="dialogTitle" data-testid="add-img-set-pop-up-title">
                      Add Image set
                    </Dialog.Title>
                    <Dialog.Content id="dialogDesc">
                      <FormLabel
                        label="Image set label / Slug"
                        fullWidth
                        required
                        labelPosition="top"
                        data-testid="img-set-label"
                      >
                        <Input
                          data-testid="img-set-input"
                          fullWidth
                          value={publishedLabel}
                          onChange={(e: any) => setPublishedLabel(slugNormalize(e.target.value))}
                        />
                        <FormHelperText data-testid="img-set-description">
                          A short description to identify this image set
                        </FormHelperText>
                      </FormLabel>
                      {/* <FormLabel
                        label="Reason for image set"
                        fullWidth
                        labelPosition="top"
                        margin={{ top: 'md' }}
                        data-testid="img-set-reason"
                      >
                        <Textarea
                          type="text"
                          fullWidth
                          value={publishedDescription}
                          onChange={(e: any) => setPublishedDescription(e.target.value)}
                        />
                        <FormHelperText data-testid="img-set-reason-description">
                          A brief description of why this image set is needed
                        </FormHelperText>
                      </FormLabel> */}
                    </Dialog.Content>
                    <Dialog.Actions>
                      <Button
                        data-testid="img-set-cancel-btn"
                        secondary
                        disabled={isPublishDisabled}
                        onClick={() => {
                          setPublishedLabel('');
                          setPublishedDescription('');
                          closeModal();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        data-testid="img-set-publish-btn"
                        primary
                        disabled={isPublishDisabled}
                        onClick={() => {
                          setPublishDisabled(true);
                          if (!publishedLabel) {
                            setAlert('Please enter a label', { type: 'error' });
                            setPublishDisabled(false);
                            return;
                          }
                          authFetch(`/api/:property/${encodeURIComponent(image.public_id)}/publish`, {
                            method: 'POST',
                            body: JSON.stringify({ published_label: publishedLabel }),
                          })
                            .then((res: any) => {
                              const error = res.error || res.data?.error;
                              if (error?.status) {
                                throw new Error(`${error.status} - Error publishing image`);
                              } else {
                                const publishedId = getLastPublished(res, property);
                                if (setImage) {
                                  setImage(res);
                                }
                                setPublishedId(publishedId as string);
                                setPublishDisabled(false);
                                closeModal();
                                setAlert('Image Published', { type: 'success', icon: IconCheckCircle });
                                navigate(
                                  `/${property}/image/${image.asset_id}/${constants.IMAGE_PAGE_MODE.DETAILS}/${publishedId}`,
                                );
                              }
                            })
                            .catch((error: Error) => {
                              console.error('publishImage error: ', error);
                              setAlert(error.message || 'Error publishing image', { type: 'error' });
                            });
                        }}
                      >
                        Publish
                      </Button>
                    </Dialog.Actions>
                  </Dialog>
                )}
              </>
            )}
          </Group.Item>
          {openDirty && (
            <Dialog
              renderNode={renderNode}
              status={openDirty ? 'open' : 'closed'}
              onDismiss={() => {
                if (selectedTabOnDismiss) {
                  setSelectedTab(selectedTabOnDismiss);
                  setPublishedId(selectedTabOnDismiss);
                }
                closeModalDirty();
              }}
              size="fluid"
            >
              <Dialog.Title>Edit Image</Dialog.Title>
              <Dialog.Content>
                <Typography margin={{ top: 'md' }}>
                  Do you want to save pending changes before leaving this page?
                </Typography>
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  secondary
                  type="reset"
                  disabled={isSaving}
                  onClick={() => {
                    if (openImageSetModal) {
                      openModal();
                    }
                    if (tabToSelect) {
                      setSelectedTab(tabToSelect);
                      setPublishedId(tabToSelect);
                    }
                    if (linkTo) {
                      navigate(linkTo);
                    }
                    resetData();
                    closeModalDirty();
                  }}
                >
                  Continue without saving
                </Button>
                <Button
                  data-testid="save-and-continue"
                  primary
                  disabled={isSaving}
                  onClick={() => {
                    setIsSaving(true);
                    publishData()
                      .then(() => {
                        setIsSaving(false);
                        if (openImageSetModal) {
                          openModal();
                        }
                        if (tabToSelect) {
                          setSelectedTab(tabToSelect);
                          setPublishedId(tabToSelect);
                        }
                        if (linkTo) {
                          navigate(linkTo);
                        }
                        setAlert('Image updated', { type: 'success', icon: IconCheckCircle });
                        closeModalDirty();
                      })
                      .catch(() => {
                        setIsSaving(false);
                        console.error('Failed to save image');
                        setAlert('Failed to save image', { type: 'error' });
                      });
                  }}
                >
                  Save and continue
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
        </>
      )}
    </>
  );
}

export default ImageTabBar;
