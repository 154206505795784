import React, { useState } from 'react';
import { Button, Group, Typography } from '@screentone/core';
import TwentyTwenty from 'react-twentytwenty';

function DeveloperDocs() {
  const [image, setImage] = useState({
    id: 'cldExample',
    left: 'https://res.cloudinary.com/demo/image/upload/v1688051275/ai/original.jpg',
    right: 'https://res.cloudinary.com/demo/image/upload/c_mpad,h_900,w_1100,b_gen_fill/v1687525630/ai/woman_model.jpg',
  });
  const [sliderPosition, setSliderPosition] = useState(1);

  // https://res.cloudinary.com/demo/image/upload/c_scale,w_4.0/v1674845506/pm/product_updates/2023/02/brooklyn_bridge.jpg
  // https://res.cloudinary.com/demo/image/upload/e_upscale/v1674845506/pm/product_updates/2023/02/brooklyn_bridge.jpg

  // https://images-wsj.dev.djtools.dowjones.io/image/upload/t_preview/f_auto,q_auto:eco/wsj/cld/aocbgom2g5tbt1elsty7
  // https://images-wsj.dev.djtools.dowjones.io/image/upload/e_upscale/t_preview/f_auto,q_auto:eco/wsj/cld/aocbgom2g5tbt1elsty7

  const sliderStyles = {
    height: '100%',
    width: '2px',
    background: '#FFF',
  };
  const imageStyles = {
    maxHeight: '400px',
    maxWidth: '800px',
  };
  return (
    <>
      <Typography variant="header1">AI : Generative Fill</Typography>
      <Group margin={{ bottom: 'md' }}>
        <Button
          secondary
          size="sm"
          active={image.id === 'cldExample'}
          onClick={() => {
            setImage({
              id: 'cldExample',
              left: 'https://res.cloudinary.com/demo/image/upload/v1688051275/ai/original.jpg',
              right:
                'https://res.cloudinary.com/demo/image/upload/c_mpad,h_900,w_1100,b_gen_fill/v1687525630/ai/woman_model.jpg',
            });
            setSliderPosition(1);
          }}
        >
          Cloudinary Example
        </Button>
        <Button
          secondary
          size="sm"
          active={image.id === 'starbucksExample'}
          onClick={() => {
            setImage({
              id: 'starbucksExample',
              left: 'https://images.wsjcommerce.net/image/upload/wsjcommerce/cld/runns5va2wiim3zszbxd',
              right:
                'https://images.wsjcommerce.net/image/upload/c_mpad,h_1000,w_2000,b_gen_fill/wsjcommerce/cld/runns5va2wiim3zszbxd',
            });
            setSliderPosition(sliderPosition);
          }}
        >
          BuySide Starbucks Sweatshirt
        </Button>

        <Button
          secondary
          size="sm"
          active={image.id === 'HeritageBlazer'}
          onClick={() => {
            setImage({
              id: 'HeritageBlazer',
              left: 'https://images.wsjcommerce.net/image/upload/wsjcommerce/cld/ocdsu4gogge7pjl4ngie',
              right:
                'https://images.wsjcommerce.net/image/upload/c_mpad,h_1000,w_2000,b_gen_fill/wsjcommerce/cld/ocdsu4gogge7pjl4ngie',
            });
            setSliderPosition(sliderPosition);
          }}
        >
          BuySide Heritage Blazer (im-09637525)
        </Button>
      </Group>
      <div style={imageStyles}>
        <TwentyTwenty
          defaultPosition={1}
          // eslint-disable-next-line @next/next/no-img-element
          left={<img style={imageStyles} src={image.left} alt="original" />}
          // eslint-disable-next-line @next/next/no-img-element
          right={<img style={imageStyles} src={image.right} alt="ai generated" />}
          slider={<div style={sliderStyles} />}
        />
      </div>

      <p>https://cloudinary.com/blog/generative-fill-ai-powered-outpainting</p>
    </>
  );
}

export default DeveloperDocs;
