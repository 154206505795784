import React, { useState, useEffect } from 'react';
import { Box, Group, Loader, Typography } from '@screentone/core';

import useImageDetail from '../../../../hooks/useImageDetail';
import PreviewThumbnail from '../../../ImageDetails/PreviewThumbnail';
import useEditImage from '../../../../hooks/useEditImage';

function ImagePreview({ skipOriginal }: { skipOriginal?: boolean }) {
  const { image, publishedId, hasFetchedImage, publishedIdsObj } = useImageDetail();
  const { previewType, setPreviewType } = useEditImage();
  const PREVIEW_SIZES = publishedIdsObj[publishedId as string]?.previewSizes || [];
  const previewKeys = Object.keys(PREVIEW_SIZES);

  // Update previewType when PREVIEW_SIZES changes
  useEffect(() => {
    if (!['custom', ...previewKeys].includes(previewType)) {
      setPreviewType(previewKeys[0]);
    }
  }, [PREVIEW_SIZES, hasFetchedImage]);

  return (
    <>
      {publishedId && !!previewKeys.length && (
        <Box padding={{ all: 'md' }} attached="bottom">
          <Group gap="md" valign="start" data-testid="crop-previews">
            {previewKeys.map((size) => (
              <PreviewThumbnail
                key={size}
                type={previewType}
                option={PREVIEW_SIZES[size]}
                sizeKey={size}
                onClick={() => setPreviewType(size)}
                skipOriginal={skipOriginal}
                // disabled={}
              ></PreviewThumbnail>
            ))}

            {image.isDynamic && !image.additional_resources && (
              <>
                <Loader size="md" />
                <Typography size="sm" weight="normal" inline>
                  Fetching preview sizes...
                </Typography>
              </>
            )}
          </Group>
        </Box>
      )}
    </>
  );
}

export default ImagePreview;
