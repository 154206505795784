/**
 * Example of a component with types and CSS
 * Use this as reference, but do not copy over any eslint-ignore comments!!!!
 */
import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Button, IconEye, Group, Input, IconSearch } from '@screentone/core';

import UserFilter from './UserFilter';
import DateFilter from './DateFilter/DateFilter';
import { ActivityUserStateType, SearchOptionsType } from '../../types';
import { useSearch } from '../../hooks/useSearch';
import SourcesFilter from './SourcesFilter';
import StatusFilter from './StatusFilter';
import AdvancedSearch from './AdvancedSearch';
import GraphicTypeFilter from './GraphicTypeFilter';

// Add this for all components that accept props
// For any optional props, you must define the defaults as well
// Anything with a ? is an optional prop and requires a default value
type SearchbarProps = {
  /**
   * State for user filter
   */
  // eslint-disable-next-line react/no-unused-prop-types
  userState?: ActivityUserStateType;
  /**
   * Ref to handle reset search input
   */
  searchInputRef?: React.RefObject<HTMLInputElement>;

  showOneTimeUse?: boolean;

  showAdvanced?: boolean;
};

function Searchbar({ searchInputRef, showOneTimeUse, showAdvanced }: SearchbarProps) {
  const { options: currentOptions, updateSearchOptions } = useSearch();
  const [inputSearchValue, setInputSearchValue] = useState(currentOptions.query);
  const handleUpdateQueryState = () => {
    if (!isEqual(currentOptions.query, inputSearchValue)) {
      updateSearchOptions({ ...currentOptions, query: inputSearchValue }, true);
    }
  };
  const detectEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      updateSearchOptions({ query: e.currentTarget.value }, true);
    }
  };
  const onSearch = (options?: SearchOptionsType) => {
    if (options) {
      updateSearchOptions({ ...currentOptions, ...options }, true);
    }
  };
  useEffect(() => {
    setInputSearchValue(currentOptions.query);
  }, [currentOptions.query]);

  return (
    <>
      <Group gap="md" margin={{ top: 'lg', bottom: 'smd' }}>
        <Group.Item flex>
          <Input
            ref={searchInputRef}
            data-testid="search-input"
            icon={IconSearch}
            type="search"
            placeholder="What are you looking for?"
            onBlur={handleUpdateQueryState}
            onKeyDown={detectEnter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputSearchValue(e.currentTarget.value)}
            value={inputSearchValue}
            aria-label="Search"
          />
        </Group.Item>
        <Group.Item>
          <Button
            data-testid="search-button"
            primary
            onClick={() => {
              onSearch({});
            }}
          >
            Search
          </Button>
        </Group.Item>
      </Group>
      <Group margin={{ bottom: 'lg' }} align="space-between" wrap={false} valign="start">
        <Group gap="md">
          <DateFilter />
          <UserFilter />
          <SourcesFilter currentOptions={currentOptions} onSearch={onSearch} />
          <StatusFilter currentOptions={currentOptions} onSearch={onSearch} />
          <GraphicTypeFilter currentOptions={currentOptions} onSearch={onSearch} />
          {showOneTimeUse && (
            <Button
              data-testid="include-one-time-use"
              active={currentOptions.oneTimeUse}
              tertiary
              icon={IconEye}
              onClick={() => {
                onSearch({ oneTimeUse: !currentOptions.oneTimeUse });
              }}
              title="Include One time use images within search"
            >
              Include One time use
            </Button>
          )}
        </Group>
        <Group align="end">
          {showAdvanced && <AdvancedSearch onSearch={onSearch} currentOptions={currentOptions} />}
        </Group>
      </Group>
    </>
  );
}

export default Searchbar;
