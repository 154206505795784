import {
  ALL,
  RENDER_PREFIX,
  UPLOAD_FILES_PREFIX,
  UPLOAD_METADATA_PREFIX,
  UPLOAD_NAVIGATION_PREFIX,
  UPLOAD_PREFIX,
  VALIDATION_PREFIX,
  UPLOAD_BULK_METADATA_PREFIX,
} from './actions';
import allReducer from './allReducer';
import uploadFilesReducer from './uploadFilesReducer';
import renderReducer from './renderReducer';
import uploadBulkMetadataReducer from './uploadBulkMetadataReducer';
import uploadMetadataReducer from './uploadMetadataReducer';
import uploadNavigationReducer from './uploadNavigationReducer';
import uploadReducer from './uploadReducer';
import validationReducer from './validationReducer';
import UseSearchType from './types';

/**
 * Combination reducer for all states related to useUpload
 */
const mergedUploadReducer = (
  state: UseSearchType.Merged.State,
  action: UseSearchType.Merged.Action
): UseSearchType.Merged.State => {
  switch (true) {
    /** pass action to reducer for updating all bits of state */
    case !!action.type.match(ALL): {
      return allReducer(state, action as UseSearchType.All.Action);
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(UPLOAD_FILES_PREFIX): {
      return {
        ...state,
        files: uploadFilesReducer(state.files, action as UseSearchType.Files.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(RENDER_PREFIX): {
      return {
        ...state,
        render: renderReducer(state.render, action as UseSearchType.Render.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(UPLOAD_METADATA_PREFIX): {
      return {
        ...state,
        metadata: uploadMetadataReducer(state.metadata, action as UseSearchType.Metadata.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(UPLOAD_NAVIGATION_PREFIX): {
      return {
        ...state,
        navigation: uploadNavigationReducer(state.navigation, action as UseSearchType.Navigation.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(UPLOAD_PREFIX): {
      return {
        ...state,
        upload: uploadReducer(state.upload, action as UseSearchType.Upload.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(VALIDATION_PREFIX): {
      return {
        ...state,
        // * NOTE: we are passing state as a whole for validation
        validation: validationReducer(state, action as UseSearchType.Validation.Action),
      };
    }

    /** pass action to reducer for upload metadata */
    case !!action.type.match(UPLOAD_BULK_METADATA_PREFIX): {
      return {
        ...state,
        ...uploadBulkMetadataReducer(state, action as UseSearchType.BulkMetadata.Action),
      };
    }

    /** unhandled actions */
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default mergedUploadReducer;
