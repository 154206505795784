// import React, { useState, useEffect } from 'react';
// import useConfig from '../../../hooks/useConfig';
// import { Typography, Loader, Token, Table } from '@screentone/core';
// import UserFilter from '../components/UserFilter';
// import { CldUserState } from '../../../types/search';
// import DateFilter from '../../../components/Searchbar/DateFilter/DateFilter';

// import {
//   ColumnDef,
//   flexRender,
//   getCoreRowModel,
//   getSortedRowModel,
//   SortingFn,
//   SortingState,
//   useReactTable,
// } from '@tanstack/react-table';

// //custom sorting logic for one of our enum columns
// const sortStatusFn: SortingFn = (rowA, rowB, _columnId) => {
//   const statusA = rowA.original.status;
//   const statusB = rowB.original.status;
//   const statusOrder = ['single', 'complicated', 'relationship'];
//   return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
// };

// type TUserSearchLog = {
//   cldTime: number;
//   error: string;
//   expression: string;
//   id: string;
//   options: {
//     advancedFilter: Record<string, unknown>;
//     graphicTypesFilter: any[];
//     nextCursor: null | string;
//     oneTimeUse: boolean;
//     pageSize: number;
//     query: string;
//     requestId: string;
//     since: string;
//     sourceFilter: any[];
//     statusFilter: null | string;
//     until: string;
//     user: null | string;
//   };
//   property: string;
//   time_stamp: number;
//   totalTime: number;
//   userId: string;
// };

// const initialCldUsersState: CldUserState = {
//   selectedUser: null,
//   userList: [],
//   filteredUsers: [],
// };

// const UserSearchLogs = () => {
//   const { authFetch } = useConfig();
//   const [logs, setLogs] = useState<TUserSearchLog[]>([]);
//   const [cldUsers, setCldUsers] = useState(initialCldUsersState);
//   const [selectedDate, setSelectedDate] = useState<{ since: string; until: string }>({ since: '', until: '' });
//   const { selectedUser } = cldUsers;
//   const [isLoading, setIsLoading] = useState(false);
//   const { since, until } = selectedDate;

//   const [sorting, setSorting] = React.useState<SortingState>([]);
//   const columns = React.useMemo(
//     () => [
//       {
//         accessorKey: 'timestamp',
//         header: 'TimeStamp',
//         // cell: (info) => info.getValue(),
//         //this column will sort in ascending order by default since it is a string column
//       },
//       // {
//       //   accessorFn: (row) => row.totalTime,
//       //   id: 'time',
//       //   cell: (info) => info.getValue(),
//       //   header: () => <span>TOTAL TIME</span>,
//       //   sortUndefined: 'last', //force undefined values to the end
//       //   sortDescFirst: false, //first sort order will be ascending (nullable values can mess up auto detection of sort order)
//       // },
//       {
//         accessorKey: 'totalTime',
//         header: 'TOTAL TIME',
//         //this column will sort in descending order by default since it is a number column
//       },
//       {
//         accessorKey: 'cldTime',
//         header: 'CLD TIME',
//         //this column will sort in descending order by default since it is a number column
//       },
//       {
//         accessorKey: 'userId',
//         header: 'USER ID',
//         sortUndefined: 'last', //force undefined values to the end
//       },
//       // {
//       //   accessorKey: 'status',
//       //   header: 'Status',
//       //   sortingFn: sortStatusFn, //use our custom sorting function for this enum column
//       // },
//       // {
//       //   accessorKey: 'progress',
//       //   header: 'Profile Progress',
//       //   // enableSorting: false, //disable sorting for this column
//       // },
//       // {
//       //   accessorKey: 'rank',
//       //   header: 'Rank',
//       //   invertSorting: true, //invert the sorting order (golf score-like where smaller is better)
//       // },
//       // {
//       //   accessorKey: 'createdAt',
//       //   header: 'Created At',
//       //   // sortingFn: 'datetime' //make sure table knows this is a datetime column (usually can detect if no null values)
//       // },
//     ],
//     []
//   );

//   const [data, setData] = React.useState(() => makeData(1_000));
//   // const refreshData = () => setData(() => makeData(100_000)); //
//   // // 1. Fetch all users when the component mounts
//   // useEffect(() => {
//   //   authFetch(`/api/allUsers`)
//   //     .then((users) => {
//   //       setCldUsers((prevState) => ({
//   //         ...prevState,
//   //         userList: users,
//   //         filteredUsers: users,
//   //       }));
//   //     })
//   //     .catch((err: Error) => {
//   //
//   //       throw err;
//   //     });
//   // }, []);

//   // 2. Fetch user logs when `selectedUser` or `selectedDate` changes
//   useEffect(() => {
//
//     if (selectedUser && selectedDate) {
//       const newDate = `${since} ${until} ` || '';
//       setIsLoading(true);
//   const { authFetch } = useConfig();
//       authFetch(`/api/searchUserLog`, {
//         method: 'POST',
//         body: JSON.stringify({ date: newDate }),
//         // body: JSON.stringify({ userID: selectedUser, date: newDate }),
//       })
//         .then((logs) => {
//
//           setLogs(logs);
//           setIsLoading(false);
//         })
//         .catch((err: Error) => {
//
//           setIsLoading(false);
//           throw err;
//         });
//     }
//   }, [selectedUser, selectedDate]);

//   // // 3. Update `selectedUser` and `selectedDate` from local storage when the component mounts
//   // useEffect(() => {
//   //   const user = localStorage.getItem('filteredUser');
//   //   user && setCldUsers((prevState: any) => ({ ...prevState, selectedUser: JSON.parse(user) }));

//   //   const date = localStorage.getItem('filteredDate');
//   //   date && setSelectedDate(JSON.parse(date));
//   // }, []);

//   // // 4. Update local storage when `selectedUser` or `selectedDate` changes
//   // useEffect(() => {
//   //   if (selectedUser) {
//   //     localStorage.setItem('filteredUser', JSON.stringify(selectedUser));
//   //   }
//   //   if (since.length || until.length) {
//   //     localStorage.setItem('filteredDate', JSON.stringify(selectedDate));
//   //   }
//   // }, [selectedUser, selectedDate]);

//   const getColorCode = (totalTime: number) => {
//     if (totalTime < 2) return 'emerald';
//     if (totalTime >= 2 && totalTime < 5) return 'sunshine';
//     if (totalTime >= 5 && totalTime < 10) return 'tangerine';
//     return 'gray';
//   };

//   return (
//     <>
//       <Typography variant="header1">User Search Logs</Typography>
//       {/* <UserFilter cldUsers={cldUsers} setCldUsers={setCldUsers} /> */}
//       <DateFilter dateFiltered={selectedDate} setSelectedDate={setSelectedDate} numberOfMonths={1} />
//       <Table id="search-table">
//         <Table.Head>
//           <Table.Row>
//             <Table.Cell scope="col">Time Stamp</Table.Cell>
//             <Table.Cell scope="col">Total Time</Table.Cell>
//             <Table.Cell scope="col">CldTime</Table.Cell>
//             <Table.Cell scope="col">User Id</Table.Cell>
//             <Table.Cell scope="col">Expression</Table.Cell>
//           </Table.Row>
//         </Table.Head>
//         <Table.Body>
//           {isLoading && !logs.length ? (
//             <Loader />
//           ) : (
//             logs.map((log) => {
//               const colorCode = getColorCode(log.totalTime);
//               const cldTimeInSec = Number((log.cldTime / 1000).toFixed(2));
//               const colorCodeCld = getColorCode(cldTimeInSec);

//               const date = new Date(log.time_stamp);
//               const timeStamp = `${date.toDateString()} ${date.toLocaleTimeString()}`;

//               return (
//                 <Table.Row key={log.id}>
//                   <Table.Cell>
//                     <Token data-sort={log.time_stamp}>
//                       <code>{timeStamp}</code>
//                     </Token>
//                   </Table.Cell>
//                   <Table.Cell>
//                     <Token color={colorCode}>{log.totalTime}</Token>
//                   </Table.Cell>
//                   <Table.Cell>
//                     {isNaN(cldTimeInSec) ? '-' : <Token color={colorCodeCld}>{cldTimeInSec}</Token>}
//                   </Table.Cell>
//                   <Table.Cell>{log.userId}</Table.Cell>
//                   <Table.Cell>{log.expression}</Table.Cell>
//                 </Table.Row>
//               );
//             })
//           )}
//           {!logs.length && !isLoading && (
//           <Table.Body>   <Table.Row>
//               <Table.Cell colSpan={3}>No logs found</Table.Cell>
//             </Table.Row>
//           )}
//         </Table.Body>
//       </Table>
//     </>
//   );
// };;

// export default UserSearchLogs;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  IconCopy,
  Loader,
  Token,
  Table,
  IconSort,
  IconSortReverse,
  IconBarrons,
  IconMansionglobal,
  IconMarketwatch,
  IconFnlondon,
  IconWsj,
  IconWsjopinion,
  IconWsjcommerce,
  IconDowjones,
  IconPlusCircle,
} from '@screentone/core';
import useConfig from '../../../hooks/useConfig';

import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

// import './index.css';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

type SortRow = {
  time_stamp: number;
  totalTime: number;
  cldTime: number;
  userId: string;
  expression: string;
};

const getData = (rowCount: number): SortRow[] => {
  const data: SortRow[] = [];
  for (let i = 0; i < rowCount; i++) {
    data.push({
      time_stamp: Date.now(),
      totalTime: Math.random() * 100,
      cldTime: Math.random() * 100,
      userId: `userId-${i}`,
      expression: `expression-${i}`,
    });
  }
  return data;
};
// //custom sorting logic for one of our enum columns
// const sortStatusFn: SortingFn<SortRow> = (rowA, rowB, _columnId) => {
//   const statusA = rowA.original.timeStamp;
//   const statusB = rowB.original.status;
//   const statusOrder = ['single', 'complicated', 'relationship'];
//   return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
// };

const UserSearchLogs = () => {
  // const rerender = React.useReducer(() => ({}), {})[1];

  const [sorting, setSorting] = useState<SortingState>([{ id: 'time_stamp', desc: true }]);

  const { authFetch } = useConfig();

  const getColorCode = (totalTime: number) => {
    if (totalTime < 2) return 'emerald';
    if (totalTime >= 2 && totalTime < 5) return 'sunshine';
    if (totalTime >= 5 && totalTime < 10) return 'tangerine';
    return 'gray';
  };
  const columns = React.useMemo<ColumnDef<SortRow>[]>(
    () => [
      {
        accessorKey: 'property',
        header: '',
        cell: ({ cell, row }) => {
          const property = row.original.property;
          switch (property) {
            case 'barrons':
              return <IconBarrons size="mlg" />;
            case 'mansionglobal':
              return <IconMansionglobal size="mlg" />;
            case 'marketwatch':
              return <IconMarketwatch size="mlg" />;
            case 'fnlondon':
              return <IconFnlondon size="mlg" />;
            case 'wsj':
              return <IconWsj size="mlg" />;
            case 'wsjopinion':
              return <IconWsjopinion size="mlg" />;
            case 'wsjcommerce':
              return <IconWsjcommerce size="mlg" />;
            default:
              return <IconDowjones size="mlg" />;
          }
        },
      },
      {
        accessorKey: 'time_stamp',
        header: 'timeStamp',
        cell: ({ cell, row }) => {
          const date = new Date(row.original.time_stamp);
          return (
            <>
              <Token>{date.toDateString()}</Token>
              <Token>{date.toLocaleTimeString()}</Token>
            </>
          );
        },
      },
      {
        accessorKey: 'totalTime',
        header: 'Total Time',
        cell: ({ cell, row }) => {
          return <Token color={getColorCode(row.original.totalTime)}>{row.original.totalTime}</Token>;
        },
      },
      {
        accessorKey: 'cldTime',
        header: 'CLD Time',
        cell: ({ cell, row }) => {
          const time = Number((row.original.cldTime / 1000).toFixed(2));
          return <Token color={getColorCode(time)}>{time}</Token>;
        },
      },
      {
        accessorKey: 'userId',
        header: 'userId',
      },

      {
        accessorKey: 'expression',
        header: 'expression',
        cell: ({ cell, row }) => {
          const exp = row.original.expression.replace('  ', ' ').split(') AND (');
          const searchOptions = exp.map((e, i) => {
            let headlineCount = 0;
            e = e.replace('(', '').replace('(', '');
            // if (e.startsWith(`folder`)) {
            //   const folders = e.split(' OR ');
            //   return (
            //     <Typography variant="label1">
            //       Folders:{' '}
            //       {folders.map((folder) => (
            //         <Token>{folder.replace('folder:', '')}</Token>
            //       ))}
            //     </Typography>
            //   );
            // } else
            if (e.startsWith(`metadata.dj_user_id`)) {
              const metadata = e.replace('metadata.dj_user_id:"', '').split('" OR ');
              return (
                <Typography variant="label1">
                  USER: <Token> {metadata[0]}</Token>
                </Typography>
              );
            } else if (e.startsWith(`metadata.headline`)) {
              const metadata = e.replace('metadata.headline:"', '').split('" OR ');
              const label = headlineCount === 0 ? 'Text' : 'AND';
              console.log('label: ', label);

              headlineCount = i;
              console.log('i: ', i);
              console.log('headlineCount: ', headlineCount);

              return (
                <Typography variant="label1">
                  {label}: <Token>{metadata[0]}</Token>
                </Typography>
              );
            }

            // (folder:wires/AP/* OR folder:wires/EPA/*)
            // (resource_type: image)
            // (metadata.dj_user_id: "osbornp" OR context."published_by_wsj": "osbornp")
            // (context.wsj_published_id: im * OR context.published_id_wsj: im * OR metadata.published_id: im *)
            // (metadata.graphic_type = Photo)
            // (NOT tags: WSJSelects)
            // (metadata.region: GNY AND tags: TopWires AND metadata.category: Sport)
            // (-tags=im-search-exclude)
            // ((metadata.headline: "Kangaroo*" OR metadata.caption: "Kangaroo*" OR metadata.credit: "Kangaroo*" OR metadata.contact: "Kangaroo*" OR context."tags_wsj": "Kangaroo*")
            // (metadata.headline: "Mothers*" OR metadata.caption: "Mothers*" OR metadata.credit: "Mothers*" OR metadata.contact: "Mothers*" OR context."tags_wsj": "Mothers*")
            // (metadata.headline: "Unity*" OR metadata.caption: "Unity*" OR metadata.credit: "Unity*" OR metadata.contact: "Unity*" OR context."tags_wsj": "Unity*"))
          });

          const [value, copy, supported, error] = useCopyToClipboard();

          return (
            <>
              {searchOptions}
              <Button
                tertiary
                icon={IconCopy}
                onClick={(e: Event) => {
                  e.preventDefault();
                  copy(row.original.expression);
                }}
              >
                Copy full expression
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  const [data, setData] = useState(() => getData(1_000));
  const [fetchedData, setFetchedData] = useState(false);
  // const refreshData = () => setData(() => getData(100_000)); //stress test with 100k rows

  useEffect(() => {
    authFetch(`/api/searchUserLog`, {
      method: 'POST',
      // body: JSON.stringify({ userID: selectedUser, date: newDate }),
    })
      .then((logs) => {
        setFetchedData(true);
        setData(logs);
      })
      .catch((err: Error) => {
        throw err;
      });
  }, []);

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    // sortingFns: {
    //   sortStatusFn, //or provide our custom sorting function globally for all columns to be able to use
    // },
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      sorting,
    },
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering - default on/true
    // enableMultiSort: false, //Don't allow shift key to sort multiple columns - default on/true
    // enableSorting: false, // - default on/true
    // enableSortingRemoval: false, //Don't allow - default on/true
    // isMultiSortEvent: (e) => true, //Make all clicks multi-sort - default requires `shift` key
    // maxMultiSortColCount: 3, // only allow 3 columns to be sorted at once - default is Infinity
  });

  if (!fetchedData) {
    return <Loader />;
  }

  //access sorting state from the table instance

  return (
    <div className="p-2">
      <div className="h-2" />
      <Table>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Cell scope="col" key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <IconSort color="blurple" />,
                          desc: <IconSortReverse color="blurple" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table
            .getRowModel()
            .rows.slice(0, 100)
            .map((row) => {
              return (
                <Table.Row key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      {/* <div>{table.getRowModel().rows.length.toLocaleString()} Rows</div>
      
      <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div>
      <div> <button onClick={() => refreshData()}>Refresh Data</button></div>
      <pre>{JSON.stringify(sorting, null, 2)}</pre> */}
    </div>
  );
};

export default UserSearchLogs;
