import React, { useEffect, useState } from 'react';
import { Group, IconArticle, IconDisabled, IconMinimize, Token, Tooltip, Typography } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { useConfig } from '../../hooks/useConfig';
// import useAlert from '../../hooks/useAppAlerts';

import AddKeywords from './AddKeyword';
import {
  getTransformationType,
  getPlaced,
  getRelatedProperties,
  getAllPublishedIds,
  getKeywords,
  getResizeOnlyFlag,
} from '../../utils/helpers';
import { constants } from '../../utils';

import type { ImageType } from '../../types';
import useAlert from '../../hooks/useAppAlerts';

type ImageTokenListProps = {
  /** image */
  image: ImageType;
  /** mode */
  mode?: 'upload' | 'details';
  setImage?: (image: ImageType) => void;
  hideLabels?: boolean;
};

const ImageTokenList = ({ image, mode, setImage, hideLabels }: ImageTokenListProps) => {
  const { userAccess } = useAuth();
  const { setAlert } = useAlert();
  const {
    authFetch,
    session: { property },
  } = useConfig();

  const allPublishedIds = getAllPublishedIds(image, property);
  const transformationType = getTransformationType(
    image,
    property,
    allPublishedIds[allPublishedIds.length - 1] as string
  );

  const placed = getPlaced(image, property);
  const relatedProperties = getRelatedProperties(property);
  const oneTimeUse = image.tags?.includes('one_time_use') || false;
  const isResizeOnly = getResizeOnlyFlag(image, property);
  const [keywords, setKeywords] = useState<any>(getKeywords(property));

  useEffect(() => {
    setKeywords(getKeywords(property));
  }, [image]);

  return (
    <Group gap="sm">
      {isResizeOnly && (
        <Token
          icon={IconMinimize}
          data-testid="resize-only-token-tag"
          color="lava"
          aria-label="Resize Only"
          title="Resize Only can not be cropped"
        >
          RESIZE ONLY
        </Token>
      )}
      {oneTimeUse && (
        <Token
          icon={IconDisabled}
          data-testid="onetime-use-token"
          color="lava"
          aria-label="One Time Use"
          title="This image has been fagged as One Time Use and can not be used again"
        >
          One Time Use
        </Token>
      )}
      {!hideLabels &&
        keywords.TAGS &&
        Object.keys(keywords.TAGS).map((tagKey) => {
          const tag = keywords.TAGS[tagKey];
          if (image.tags?.includes(tag.key)) {
            return (
              <Token
                color={tag.color}
                aria-label={tag.label}
                title={tag.title}
                icon={keywords.ICONS[tag.icon as keyof typeof keywords.ICONS]}
                // TODO: Add delete tag functionality
                //  -- This out of scope for this task but would be a good feature to add
                onDismiss={
                  userAccess('edit.wires') && tag.editable
                    ? () => {
                        const encodedPublicId = encodeURIComponent(image.public_id);
                        authFetch(`/api/:property/${encodedPublicId}/tags`, {
                          method: 'DELETE',
                          body: JSON.stringify({
                            tags: [tag.key],
                          }),
                        })
                          .then((image: any) => {
                            setImage && setImage(image);
                          })
                          .catch((err: Error) => {
                            console.error('handleDeleteTags Error: ', err);
                            setAlert(err.message || 'Error removing keywords', { type: 'error' });
                          });
                      }
                    : undefined
                }
              >
                {tag.label}
              </Token>
            );
          }
          return null;
        })}

      {placed &&
        (relatedProperties.length > 0 ? (
          <Tooltip>
            <Tooltip.Content position="bottom" id="tooltipContent">
              <Typography weight="bold" size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                Used By
              </Typography>
              {Object.keys(placed).map((item: string) => {
                const [value] = item.split(':');
                return (
                  <Typography key={item} size="sm" style={{ color: 'var(--st-color-cool)' }} whiteSpace="nowrap">
                    {constants.PROPERTY_LABELS[value as keyof typeof constants.PROPERTY_LABELS] || value}
                  </Typography>
                );
              })}
            </Tooltip.Content>
            <Tooltip.Trigger aria-describedby="tooltipContent" style={{ cursor: 'pointer' }}>
              <Token icon={IconArticle} color="gray" aria-label="Used in Article">
                In Use
              </Token>
            </Tooltip.Trigger>
          </Tooltip>
        ) : (
          <Token icon={IconArticle} color="gray" aria-label="Used in Article" title="Used in Article">
            In Use
          </Token>
        ))}
      {!hideLabels && mode !== 'upload' && setImage && keywords.TAGS && (
        <AddKeywords image={image} setImage={setImage} keywords={keywords} />
      )}
    </Group>
  );
};

export default ImageTokenList;
