import React, { useEffect, useState, useCallback } from 'react';
import { BlockPicker } from 'react-color';

import { constants } from '../../utils';

import styles from './styles';
import ColorPickerCSS from './ColorPicker.module.css';
import { useConfig } from '../../hooks/useConfig';

type ColorPickerTypes = {
  /** object representing image's data */
  color?: string;
  /** event handler when "add to article" is clicked, also controls whether it's shown or not */
  onChange: ({ background }: { background: string }) => void;
  /** cloudinary list of colors */
  cldColors?: string[];
};

function ColorPicker({ color, onChange, cldColors }: ColorPickerTypes) {
  const {
    session: { property },
  } = useConfig();
  const [backgroundColor, setBackgroundColor] = useState(color || 'transparent');
  const colors = [...(constants.PROPERTIES.getColors(property) || []), ...(cldColors || [])] || [];

  // Resets background color on bulk edit
  useEffect(() => {
    if (color) {
      setBackgroundColor(color);
    }
  }, [color]);

  const onChangeCompleteHandler = useCallback(
    ({ hex }: { hex: string }) => {
      setBackgroundColor(hex);
      onChange({ background: hex });
    },
    [onChange]
  );

  return (
    <BlockPicker
      triangle="hide"
      colors={[...colors, ...['#FFFFFF', 'transparent']]}
      color={backgroundColor}
      onChange={({ hex }) => setBackgroundColor(hex)}
      onChangeComplete={onChangeCompleteHandler}
      className={ColorPickerCSS['color-picker']}
      styles={styles}
    />
  );
}

export default ColorPicker;
