import React from 'react';
import { Button, IconPlusCircle, Group } from '@screentone/core';
import VariantButton, { generateItems } from '../Variant';

/** add to lightbox button */
const variantChildButtons = (item: any, type: 'dropdown' | 'dialog') => {
  if (type === 'dialog') {
    return item.id;
  }
  return (
    <Group wrap={false} gap="sm" align="space-between" fullWidth>
      <Group.Item>{item.label}</Group.Item>
      <Group.Item>{item.id}</Group.Item>
    </Group>
  );
};
function ButtonAddToLightbox(props: any) {
  const { onClick, publishedIdsObj = {} } = props;
  // for when image is not published
  const placeholder = {
    temp: {
      id: 'temp',
      label: '',
      url: '',
    },
  };
  return (
    <VariantButton
      {...props}
      ButtonComponentEl={Button}
      size="sm"
      tertiary
      label="Add to Lightbox"
      data-testid="add-to-lightbox-btn"
      icon={IconPlusCircle}
      items={generateItems({
        items: Object.keys(publishedIdsObj).length ? publishedIdsObj : placeholder,
        iconTemplate: () => IconPlusCircle,
        onClickHandler: onClick,
        childrenTemplate: variantChildButtons,
      })}
    />
  );
}

export default ButtonAddToLightbox;
