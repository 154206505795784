import React from 'react';
import { Button, IconMaximize, IconExternalLink, IconLink, Overlay, IconCross, Tooltip } from '@screentone/core';

import { useModalState, useModalPortal } from '@screentone/core';
import { Group } from '@screentone/core';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';

import styles from './InnerBrowserModel.module.css';

const InnerBrowserButton = ({
  icon: Icon,
  href,
  tooltip,
  disabled,
  ...linkProps
}: {
  icon: any;
  tooltip: string;
  href?: string;
  disabled?: boolean;
  target?: string;
  rel?: string;
  onClick?: (e: Event) => void;
}) => {
  const Component = href ? 'a' : 'button';
  if (Component === 'button') linkProps.type = 'button';
  if (href) linkProps.href = href;

  return (
    <Tooltip>
      <Tooltip.Content position="left" id={`tooltipContent_${tooltip.replace(/ /g, '-')}`}>
        {tooltip}
      </Tooltip.Content>
      <Tooltip.Trigger aria-describedby={`tooltipContent_${tooltip.replace(/ /g, '-')}`}>
        <Component className={styles['override__st_btn--ibm']} disabled={disabled} {...linkProps}>
          <Icon size="md" />
        </Component>
      </Tooltip.Trigger>
    </Tooltip>
  );
};

const InnerBrowserModel = ({
  href,
  icon,
  trigger,
  children,
}: {
  href: string;
  icon?: string;
  trigger: any;
  children?: any;
}) => {
  const [value, copy, supported, error] = useCopyToClipboard();

  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();

  const isTriggerString = typeof trigger === 'string';
  const TriggerComponent = isTriggerString ? Button : trigger;

  const triggerProps: {
    tertiary?: boolean;
    children?: string;
    icon?: string;
  } = {};

  if (icon) triggerProps.icon = icon;
  if (isTriggerString) {
    triggerProps.children = trigger;
    triggerProps.tertiary = true;
  }

  return (
    <>
      <TriggerComponent {...triggerProps} onClick={() => openModal()} />
      {open && (
        <Overlay renderNode={renderNode} status={'open'} className={styles['st_overlay__content--ibm_wrapper']}>
          <Group valign="start" className={styles['st_overlay__content--ibm_wrapper_group']} gap="sm">
            {children ? (
              <div className={styles['st_overlay__content--ibm_content']}>{children}</div>
            ) : (
              <iframe src={href} className={styles['st_overlay__content--ibm_content']} />
            )}

            <Group direction="column" gap="sm">
              {/* Close the modal */}
              <InnerBrowserButton onClick={() => closeModal()} icon={IconCross} tooltip="Close" />

              {/* Navigate to modal URL inside current tab */}
              <InnerBrowserButton href={href} icon={IconMaximize} tooltip="Open in current window" />

              {/* Open modal URL in new tab */}
              <InnerBrowserButton
                href={href}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  closeModal();
                }}
                icon={IconExternalLink}
                tooltip="Open in new tab"
              />

              {/* Copy modal URL to clipboard */}
              <InnerBrowserButton
                onClick={(e: Event) => {
                  e.preventDefault();
                  copy(href);
                }}
                icon={IconLink}
                tooltip="Copy url to clipboard"
                disabled={!supported}
              />
            </Group>
          </Group>
        </Overlay>
      )}
    </>
  );
};

export default InnerBrowserModel;
