import React, { useEffect } from 'react';
import { Helmet as Head } from 'react-helmet';
import { redirect, useNavigate } from 'react-router-dom';

import { useConfig } from '../../../hooks/useConfig';
import SearchLayout from '../../../layouts/Search';
import { handleDragAndDrop } from '../../../utils/helpers';

// the wrapper component with all the providers
function SearchPage() {
  // const router = useRouter();
  const {
    session: { property },
  } = useConfig();
  // navigate to upload page if the user drags in an image for upload
  // TODO:REFACTOR -re-enable drag and drop
  const navigate = useNavigate();
  const router = {
    redirect: (url: string) => {
      redirect(url);
    },
    navigate: (url: string) => {
      navigate(url);
    },
  };
  useEffect(() => {
    // speed up rerouting to upload page **ONLY WORKS IN PRODUCTION**
    if (!!property && !!router) {
      handleDragAndDrop(property, router);
    }
  }, [property, router]);

  return (
    <>
      <Head>
        <title>Images - Search</title>
      </Head>
      <SearchLayout />
    </>
  );
}

export default SearchPage;
