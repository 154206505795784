import React, { useEffect, useState } from 'react';
import { Navigate, redirect, useLocation, useNavigate, useParams } from 'react-router-dom';

import ImageDetailsLayout from '../../../../layouts/ImageDetails';

import { useConfig } from '../../../../hooks/useConfig';
import { ImageDetailProvider } from '../../../../hooks/useImageDetail';
import useSearch from '../../../../hooks/useSearch';
import { EditImageProvider } from '../../../../hooks/useEditImage';

import { handleDragAndDrop } from '../../../../utils/helpers';

const ImageDetails = () => {
  const {
    session: { property },
  } = useConfig();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const cropId = params.get('cropset');
  if (cropId) {
    return <Navigate to={`/${property}/image/im-${cropId}`} replace />;
  }
  const navigate = useNavigate();
  const { id: imageIdFromUrl, im: publishedIdFromUrl } = useParams();
  const image = state?.image || state?.upload?.response[0];
  const [restScroll, setRestScroll] = useState(!!image);
  const { setImScrollToId } = useSearch();

  if (restScroll) {
    setImScrollToId(image.asset_id);
    setRestScroll(false);
    window.scrollTo(0, 0);
  }

  const router = {
    redirect: (url: string) => {
      redirect(url);
    },
    navigate: (url: string) => {
      navigate(url);
    },
  };

  // navigate to upload page if the user drags in an image for upload
  useEffect(() => {
    // speed up rerouting to upload page **ONLY WORKS IN PRODUCTION**
    if (!!property && !!router) {
      handleDragAndDrop(property, router);
    }
  }, [property, router]);

  return (
    <ImageDetailProvider initialImage={image} imageId={imageIdFromUrl} publishedId={publishedIdFromUrl}>
      <EditImageProvider>
        <ImageDetailsLayout />
      </EditImageProvider>
    </ImageDetailProvider>
  );
};

export default ImageDetails;
