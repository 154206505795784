import { constants, helpers } from '../../utils';
import actions from './actions';
import UseSearchType from './types';

export const uploadDefaultState: UseSearchType.Upload.State = {
  error: [],
  errorStatus: [],
  payload: [],
  response: [],
  status: [],
};

/**
 * Initialize the statuses of each uploading image with this function
 */
const fillArray = (value: any, length: number) => {
  const arr = [];

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < length; i++) {
    arr.push(value);
  }

  return arr;
};

const uploadReducer = (
  state: UseSearchType.Upload.State,
  action: UseSearchType.Upload.Action
): UseSearchType.Upload.State => {
  switch (action.type) {
    /** return state for when the API throws an error */
    case actions.UPLOAD.ERROR: {
      return {
        ...state,
        error: helpers.replaceElementInArray(state.error, action.payload.error, action.payload.index),
        errorStatus: helpers.replaceElementInArray(state.errorStatus, action.payload.errorStatus, action.payload.index),
        response: helpers.replaceElementInArray(state.response, null, action.payload.index),
        status: helpers.replaceElementInArray(state.status, constants.LOADING_STATES.ERROR, action.payload.index),
      };
    }

    /** return state for when we finish fetch */
    case actions.UPLOAD.FINISH_FETCH: {
      return {
        ...state,
        error: helpers.replaceElementInArray(state.error, null, action.payload.index),
        errorStatus: helpers.replaceElementInArray(state.errorStatus, null, action.payload.index),
        response: helpers.replaceElementInArray(state.response, action.payload.response, action.payload.index),
        status: helpers.replaceElementInArray(state.status, constants.LOADING_STATES.COMPLETE, action.payload.index),
      };
    }

    /** get ready for uploading */
    case actions.UPLOAD.INITIALIZE: {
      return {
        ...state,
        error: fillArray(null, action.payload.length),
        errorStatus: fillArray(null, action.payload.length),
        payload: fillArray(null, action.payload.length),
        response: fillArray(null, action.payload.length),
        status: fillArray(constants.LOADING_STATES.READY, action.payload.length),
      };
    }

    /** return state for when we start fetching from the API */
    case actions.UPLOAD.START_FETCH: {
      return {
        ...state,
        error: helpers.replaceElementInArray(state.error, null, action.payload.index),
        errorStatus: helpers.replaceElementInArray(state.errorStatus, null, action.payload.index),
        payload: helpers.replaceElementInArray(state.payload, action.payload.payload, action.payload.index),
        status: helpers.replaceElementInArray(state.status, constants.LOADING_STATES.LOADING, action.payload.index),
      };
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default uploadReducer;
