import UseUploadType from '../types';
import uploadImages from './uploadImages';

type RetryUploadsParams = {
  /** the action to fetch with authorization header */
  authFetch(resource: string, options?: RequestInit): Promise<unknown>;
  /** the action dispatcher from the reducer hook */
  dispatch: UseUploadType.Merged.Dispatch;
  /** current property/brand */
  property: string;
  /** state of useUpload hook */
  state: UseUploadType.Merged.State;
};

/**
 * Handle image file(s) being added to the uploader
 */
export const retryUploads = async ({ authFetch, dispatch, property, state }: RetryUploadsParams) => {
  return new Promise(() => {
    uploadImages({ authFetch, dispatch, property, state, type: 'single' });
  });
};

/**
 * Handle promo image file(s) being added to the uploader
 */
export const retryUploadsPromo = async ({ authFetch, dispatch, property, state }: RetryUploadsParams) => {
  return new Promise(() => {
    uploadImages({ authFetch, dispatch, property, state, type: 'dynamic' });
  });
};

export default retryUploads;
