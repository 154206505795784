import React from 'react';
import { createContext, useContext, useState } from 'react';
import { Alert } from '@screentone/core';

type AlertObjType = {
  message: string;
  autoDismissTime?: number;
  type?: 'primary' | 'success' | 'warning' | 'error' | 'gray';
  autoDismiss?: boolean;
  icon?: any;
};
const AlertContext = createContext<
  | {
      alertComponent: React.ReactNode;
      alert: AlertObjType;
      setAlert(alertMessage: string, options?: any): void;
    }
  | undefined
>(undefined);

/** Arguments for UploadProvider */
type AppAlertProviderArguments = {
  children?: React.ReactNode;
};

/** Provider for context */
export const AlertProvider = ({ children }: AppAlertProviderArguments) => {
  const [alert, setAlertObj] = useState({
    message: '',
  } as AlertObjType);
  const setAlert = (alertMessage: string, options: any = {}) => {
    let optionsObj = { ...options };
    if (alertMessage === '') {
      optionsObj = {};
    }
    setAlertObj({ message: alertMessage, ...optionsObj });
  };

  const alertComponent = alert.message ? (
    <Alert
      autoDismiss={'autoDismiss' in alert ? alert.autoDismiss : true}
      autoDismissTime={alert.autoDismissTime || 3}
      type={alert.type || 'gray'}
      onDismiss={() => {
        setAlert('');
      }}
      floating
      icon={alert.icon || null}
      className="alert_message"
    >
      {alert.message}
    </Alert>
  ) : null;

  return <AlertContext.Provider value={{ alertComponent, alert, setAlert }}>{children}</AlertContext.Provider>;
};

const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
};

export default useAlert;
