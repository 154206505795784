import React from 'react';
import { Group } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';
import { getColors } from '../../../../utils/helpers';
import PaddingColor from '../../../PaddingColor/PaddingColor.component';

const BackgroundColor = ({ panelKey }: { panelKey: string }) => {
  const { image, setData, getData } = useEditImage();
  const background = getData('background', panelKey);
  const cldColors = getColors(image);

  return (
    <PaddingColor
      hideLabel
      background={background}
      onChange={(target: any) => {
        setData('background', target.background.trimStart(), panelKey);
      }}
      cldColors={cldColors}
    />
  );
};

export default BackgroundColor;
