/* eslint-disable prettier/prettier */

// import startCase from 'lodash/startCase';

import { helpers } from '../../utils';
import actions from './actions';
import UseUploadType from './types';
import validate, { ValidateType } from './validate';

export const validationDefaultState: UseUploadType.Validation.State = [];

const validationReducer = (
  state: UseUploadType.Merged.State,
  action: UseUploadType.Validation.Action
): UseUploadType.Validation.State => {
  switch (action.type) {
    /** add validation for metadata */
    case actions.VALIDATION.ADD_IMAGES: {
      return [...state.validation, ...action.payload.map((metadata) => validate.all(metadata))];
    }

    /** add validation for metadata */
    case actions.VALIDATION.ALL: {
      return action.payload;
    }

    /** add validation for a single field on metadata form fields */
    case actions.VALIDATION.BLUR: {
      const { fieldName, index } = action.payload;
      const currentValidationValue = state.validation[index][fieldName as keyof UseUploadType.Validation.Validation];

      // datePhotographed may be empty on state, but not on the calendar input itself
      // const validationText = fieldName === 'datePhotographed' ? 'Invalid Date Photographed' : `${startCase(fieldName)} cannot be empty.`;
      const validation = {
        ...state.validation[index],
        [fieldName]:
          fieldName === 'headline' && !state.metadata[index][fieldName]
            ? 'Headline cannot be empty'
            : currentValidationValue,
        // [fieldName]: state.metadata[index][fieldName]
        //   ? currentValidationValue
        //   : validationText,
      };

      return helpers.replaceElementInArray(state.validation, validation, index);
    }

    /** add validation for a single field on metadata form fields */
    case actions.VALIDATION.CUSTOM: {
      return helpers.replaceElementInArray(
        state.validation,
        { ...state.validation[action.payload.index], ...action.payload.validation },
        action.payload.index
      );
    }

    /** update validation for metadata */
    case actions.VALIDATION.EDIT: {
      const { fieldNames, index } = action.payload;

      // for each field in field names validation
      const newValidation = fieldNames.reduce((validation, fieldName) => {
        /* eslint-disable-next-line no-param-reassign */
        validation[fieldName as keyof UseUploadType.Validation.Validation] =
          // not all fields need validation
          validate[fieldName as keyof ValidateType]
            ? validate[fieldName as keyof ValidateType](state.metadata[index])
            : false;
        return validation;
      }, {} as Partial<UseUploadType.Validation.Validation>);

      return helpers.replaceElementInArray(
        state.validation,
        {
          ...state.validation[index],
          ...(newValidation as {}),
        },
        action.payload.index
      );
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

export default validationReducer;
