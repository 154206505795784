import React from 'react';
import { Textblock, Alert, Typography, Table } from '@screentone/core';
import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/cjs/styles/prism';

function MarkdownBlock({ md }: any) {
  const highlighterStyle = vs;
  highlighterStyle['code[class*="language-"]'] = { display: 'block' };
  highlighterStyle['pre[class*="language-"]'] = {};

  const codeFn = ({ node, inline, className, children, ...props }: any) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={highlighterStyle}
        PreTag="pre"
        className="SyntaxHighlighter"
        language="javascript"
        wrapLongLines={true}
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  };

  const aFn = ({ node, inline, className, href, children, ...props }: any) => {
    if (/^((http|https):\/\/)/.test(href)) {
      return (
        <a href={href} target="_blank" rel="noreferrer" {...props}>
          {children}
        </a>
      );
    }
    return <Link to={href}>{children}</Link>;
  };

  const blockquoteFn = ({ node, inline, className, href, children, ...props }: any) => {
    if (node.children[1].tagName === 'p') node.children[1].tagName = 'em';
    return (
      <Alert className="blockquote" margin={{ bottom: 'md' }}>
        {children}
      </Alert>
    );
  };
  const pFn = ({ children, ...props }: any) => {
    if (props.node.tagName === 'em') {
      return <em>{children}</em>;
    }
    return <Typography variant="bodytext">{children}</Typography>;
  };

  return (
    <Textblock className="markdown_block">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: aFn,
          code: codeFn,
          blockquote: blockquoteFn,
          p: pFn,
          h1: ({ children }) => <Typography variant="header1">{children}</Typography>,
          h2: ({ children }) => <Typography variant="header2">{children}</Typography>,
          h3: ({ children }) => <Typography variant="header3">{children}</Typography>,
          h4: ({ children }) => <Typography variant="header4">{children}</Typography>,
          table: ({ children }) => (
            <Table fullWidth margin={{ bottom: 'md' }}>
              {children}
            </Table>
          ),
          thead: ({ children }) => <Table.Head>{children}</Table.Head>,
          tbody: ({ children }) => <Table.Body>{children}</Table.Body>,
          tr: ({ children, isHeader }) => (
            <Table.Row isHeader={isHeader} hoverable={!isHeader}>
              {children}
            </Table.Row>
          ),
          th: ({ children, isHeader }) => (
            <Table.Cell isLabel={isHeader} scope="col">
              {children}
            </Table.Cell>
          ),
          td: ({ children, ...props }) => <Table.Cell>{children}</Table.Cell>,

          // table: Table, // This is a custom component that wraps table, thead, tbody, td, th, thead, and tr
          // , tbody, td, th, thead, and tr
        }}
      >
        {md}
      </ReactMarkdown>
    </Textblock>
  );
}

export default MarkdownBlock;
