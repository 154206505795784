import { Group, Typography, Illustration } from '@screentone/core';
import { ErrorMessage } from '@screentone/addon-auth-wrapper';
import React from 'react';
import styles from './CustomErrorMessage.module.css';

type CustomErrorMessageProps = {
  /** title of page */
  title: string;
  /** message underneath illustration */
  message: string;
  /** illustration to display */
  illustration: 'empty' | 'blank';
  /** action button */
  Action?: React.ReactElement;
};

function CustomErrorMessage({ title, message, illustration, Action }: CustomErrorMessageProps) {
  return (
    <Group direction="column" align="center" margin={{ vertical: 'xl' }}>
      <Typography size="xxl" data-testid="delete-confirmation-message">
        {title}
      </Typography>
      {Action}
      <Illustration name={illustration} className={styles.illustration} />
      <Typography align="center">
        <ErrorMessage message={message} />
      </Typography>
    </Group>
  );
}

export default CustomErrorMessage;
