import { Button, useModalState, useModalPortal, Dialog, IconTrash, Typography } from '@screentone/core';
import React, { useState } from 'react';

type DeleteButtonProps = {
  /** function to delete image */
  onDelete(): Promise<void | unknown>;
  /** image data object */
  imageId: string;
  /** isDisabled */
  isDisabled: boolean;
};

function DeleteButton({ onDelete, imageId, isDisabled }: DeleteButtonProps) {
  const { open, openModal, closeModal } = useModalState();
  const { renderNode } = useModalPortal();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setDeleting(true);
    onDelete().then(() => {
      closeModal();
      setDeleting(false);
    });
  };

  return (
    <>
      <Button
        data-testid="delete-img-btn"
        tertiary
        icon={IconTrash}
        color="lava"
        onClick={openModal}
        disabled={isDisabled}
      >
        Delete Image
      </Button>
      {open && (
        <Dialog onDismiss={closeModal} renderNode={renderNode} status={open ? 'open' : 'closed'} size="fluid">
          <Dialog.Title id="dialogTitle">Confirm Delete</Dialog.Title>
          <Dialog.Content>
            <Typography variant="header3">
              <strong>Delete Image?</strong>
            </Typography>
            <Typography size="sm">
              Are you sure you want to delete <strong>{`${imageId}`}</strong>?
              <br />
              You cannot undo this action.
            </Typography>
          </Dialog.Content>
          <Dialog.Actions>
            <Button secondary onClick={closeModal} disabled={deleting}>
              Cancel
            </Button>
            <Button data-testid="delete-confirmation-btn" primary onClick={handleDelete} disabled={deleting}>
              Delete
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
}

export default DeleteButton;
