import { Group, Dialog, Box, Token, Table, Alert, IconInfo, Loader } from '@screentone/core';
import React, { useEffect } from 'react';

import Derived from './Derived';
import useImageDetail from '../../hooks/useImageDetail';

import { filterTags, getLastPublished, roundRatio } from '../../utils/helpers';

import styles from './Metadata.module.css';
import type { PropertyType } from '../../types';

type MetadataProps = {
  /** callback func when user clicks "X" on Dialog */
  onDismiss: () => void;
  isAdmin: boolean;
  property: PropertyType;
};

/** given a crazy comma-separated string
 * add a space after every comma
 * so it's easier to read/doesn't break UI * */
function addSpaceAfterComma(str: string) {
  return str.split(',').join(', ');
}

/** given key/value pair,
 * adds formatting to text as needed
 * */
function formatMetadata(key: string, value: string | string[]) {
  if (key.startsWith('published_date')) {
    return Number(value).toString().length >= 10
      ? new Date(Number(Number(value).toString().substring(0, 10)) * 1000).toLocaleString()
      : '';
  }

  switch (key) {
    case 'published_id':
    case 'category':
      return typeof value === 'string' ? addSpaceAfterComma(value) : value;
    case 'Keywords':
      return (
        <Group direction="row" gap="xs">
          {Array.isArray(value) && value.map((k) => <Token key={`${key}_${k}`}>{k}</Token>)}
        </Group>
      );
    case 'Subject':
      return Array.isArray(value) ? value.join(', ') : value;
    case 'keywords':
      return (
        <Group direction="row" gap="xs">
          {typeof value === 'string' && value.split(',').map((k) => <Token key={`${key}_${k}`}>{k}</Token>)}
        </Group>
      );
    case 'date_uploaded': {
      return Number(value).toString().length >= 10
        ? new Date(Number(Number(value).toString().substring(0, 10)) * 1000).toLocaleString()
        : '';
    }
    case 'date_published': {
      return Number(value).toString().length >= 10
        ? new Date(Number(Number(value).toString().substring(0, 10)) * 1000).toLocaleString()
        : '';
    }
    case 'dynamic_scale': {
      const values = typeof value === 'string' ? value.split(',') : value;
      return (
        <Group direction="column" gap="xs">
          {values.map((k) => (
            <Token key={`${key}_${k}`}>{k}</Token>
          ))}
        </Group>
      );
    }
    default:
      if (typeof value === 'string') {
        return value;
      }
      if (Array.isArray(value)) {
        return (
          <>
            {value.map((k) => {
              if (typeof k === 'string') return <Token key={`${key}_${k}_${Math.random()}`}>{k}</Token>;
              return JSON.stringify(value, null, 4);
            })}
          </>
        );
      }

      return <>{JSON.stringify(value, null, 4)}</>;
  }
}

/**
 *
 * Given an object with metadata
 * builds the JSX for the table
 */
function generateMetadataTable({
  meta,
  property,
  assetId,
  derived,
}: {
  meta: any;
  property: PropertyType;
  assetId: string;
  derived?: boolean;
}) {
  if (!meta || Object.keys(meta).length === 0) return null;

  if (derived) {
    return <Derived meta={meta} assetId={assetId} />;
  }

  if (Array.isArray(meta)) {
    const filteredTags = filterTags(meta, property);
    return (
      <Table fullWidth border="horizontal" styles={{ tableLayout: 'fixed' }}>
        <Table.Body>
          <Table.Cell>
            {filteredTags.map((item: string) => {
              return (
                <Token key={`${item}`} color="blurple" margin={{ right: 'sm' }}>
                  {item}
                </Token>
              );
            })}
          </Table.Cell>
        </Table.Body>
      </Table>
    );
  }
  return (
    <Table fullWidth border="horizontal" styles={{ tableLayout: 'fixed' }}>
      <Table.Body>
        {Object.keys(meta).map((key) => {
          return (
            <Table.Row key={key} hoverable>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell style={{ overflowWrap: 'anywhere' }}>{formatMetadata(key, meta[key])}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

function Metadata({ onDismiss, isAdmin, property }: MetadataProps) {
  const { image, getImageEmbeddedMetadata } = useImageDetail();

  useEffect(() => {
    if (!image.image_metadata) {
      getImageEmbeddedMetadata();
    }
  }, [image.image_metadata]);
  
  // if (!image.image_metadata) {
  //   return <Loader />;
  // }

  if (isAdmin) {
    console.groupCollapsed('Image Data'); // eslint-disable-line no-console
    console.log('JSON: ', image); // eslint-disable-line no-console
    console.groupCollapsed('STRING:'); // eslint-disable-line no-console
    console.log(JSON.stringify(image, null, 2)); // eslint-disable-line no-console
    console.groupEnd(); // eslint-disable-line no-console
    console.groupEnd(); // eslint-disable-line no-console
  }
  const boxes = [];
  const adminMetadata: { [key: string]: any } = {};
  const derivedMetadata: { [key: string]: any } = {};
  const tags = [];
  const titles: { [key: string]: string } = {
    metadata: 'Structured Metadata',
    context: 'Contextual Metadata',
    context_admin: 'Contextual Metadata (full)',
    image_metadata: 'Image Metadata',
    tags: 'Tags',
  };

  const adminFields = [
    'coordinates',
    'faces',
    'illustration_score',
    'semi_transparent',
    'grayscale',
    'pages',
    'usage',
    'original_filename',
    'asset_id',
    'public_id',
    'format',
    'version',
    'resource_type',
    'type',
    'created_at',
    'bytes',
    'width',
    'height',
    'aspect_ratio',
    'pixels',
    'folder',
    'access_mode',
    'secure_url',
    'tags',
  ];

  const filterPropertyItems = (items: any, key: string, property: PropertyType) => {
    if (key === 'context') {
      return Object.keys(items).reduce((acc: any, k: string) => {
        if (k.endsWith(`_${property}`) || k.endsWith(`_${items[`published_id_${property}`]}`)) {
          acc[k] = items[k];
        }
        return acc;
      }, {});
    }
    return items;
  };

  const getContent = (key: string, image: any, property: string) => {
    const content = image[key];

    if (key === 'tags') {
      const publishedId = getLastPublished(image, property as PropertyType);
      return [content?.filter((tag: string) => !tag.startsWith('im-') || tag === publishedId)];
    }

    return [content];
  };

  Object.keys(image).forEach((key: string) => {
    if (key === 'context') {
      boxes.push({
        key,
        title: titles[key],
        content: [filterPropertyItems(image[key], key, property)],
      });
      if (isAdmin) {
        boxes.push({
          key: `${key}_admin`,
          title: titles[`${key}_admin`],
          content: [image[key]],
        });
      }
    } else if (key === 'tags' || key === 'metadata' || key === 'image_metadata' || key === 'media_metadata') {
      boxes.push({
        key,
        title: titles[key === 'media_metadata' ? 'image_metadata' : key],
        content: getContent(key, image, property),
      });
    } else if (key === 'derived') {
      derivedMetadata[key] = image[key];
    } else if (key === 'tags') {
      tags.push(image[key]);
    } else if (adminFields.includes(key)) {
      let value = image[key];
      if (key === 'aspect_ratio') {
        value = `${value} (${roundRatio(value)})`;
      }

      adminMetadata[key] = value;
    }
  });

  if (image.isDynamic && image.additional_resources) {
    image.additional_resources.forEach((resource: any) => {
      // titles[`resource_${resource.metadata.import_source_name}`] = `Resource ${resource.metadata.import_source_name}`;
      boxes.push({
        key: `resource_${resource.metadata.import_source_name}`,
        title: `Resource ${resource.metadata.import_source_name}`,
        content: [resource.context],
      });
      // Object.keys(resource.context).forEach((key: string) => {
      //   console.groupCollapsed('Resource Data', resource.metadata.import_source_name);
      //   console.log('key: ', key);
      //   console.log('resource.context: ', resource.context);
      //   console.log('resource.context[key]: ', resource.context[key]);
      //   console.log(
      //     'filterPropertyItems(resource.context[key], key, property): ',
      //     filterPropertyItems(resource.context[key], key, property)
      //   );
      //   console.groupEnd();

      //   boxes.push({
      //     key,
      //     title: `Resource ${resource.metadata.import_source_name}`,
      //     content: [resource.context],
      //   });
      // });
    });
  }

  if (adminMetadata && isAdmin) {
    boxes.push({
      key: 'admin',
      title: 'Admin Metadata',
      content: [adminMetadata],
    });
  }
  if (derivedMetadata && isAdmin) {
    boxes.push({
      key: 'derived',
      title: 'derived Metadata',
      content: derivedMetadata.derived,
    });
  }
  return (
    <>
      <Dialog
        data-testid="metadata-popup"
        aria-labelledby="metadialogTitle"
        aria-describedby="metadialogDescription"
        onDismiss={onDismiss}
        status="open"
        size="xl"
      >
        <Dialog.Title id="metadialogTitle" data-testid="metadata-title">
          Metadata
        </Dialog.Title>
        <Dialog.Content
          padding={{ bottom: 'lg', horizontal: 'mlg', top: 'mlg' }}
          style={{ backgroundColor: 'var(--st-page-bg)' }}
        >
          <Group direction="column" gap="xl">
            <Group.Item fullWidth>
              {boxes.map((box) => (
                <Box key={box.key} className={styles.box} margin={{ top: 'md' }} data-testid="section-title">
                  <Box.Title data-testid="box-title">{box.title}</Box.Title>
                  {box?.content?.map((item: { key: string; title: string; content: any }) => (
                    <div data-testid="table-content">
                      {box.key === 'image_metadata' && (
                        <Alert icon={IconInfo} margin={{ bottom: 'md' }}>
                          This section shows the metadata embed in the original image for reference only and is not
                          published. If you have edited metadata, your updated changes will not show below.
                        </Alert>
                      )}
                      {generateMetadataTable({
                        meta: item,
                        property,
                        assetId: image.asset_id,
                        derived: box.key === 'derived',
                      })}
                    </div>
                  ))}
                </Box>
              ))}
            </Group.Item>
          </Group>
        </Dialog.Content>
      </Dialog>
    </>
  );
}

export default Metadata;
