// import process from 'process';
// import type { Response } from 'express';

export type UnknownError = {
  http_code?: number;
  status?: number;
  error?: any;
  message?: string;
  failed?: {
    message?: string;
  };
  statusText?: string;
};

/**
 * Given an unknown that likely contains information about an error, extract that information and
 * format it.
 */
export const extractError = (err: UnknownError = {}) => {
  const message: string =
    err?.message ||
    err?.failed?.message ||
    err?.statusText ||
    err?.error?.message ||
    err?.error ||
    err ||
    'Unknown Error';

  const status: number = err?.http_code || err?.status || err?.error?.http_code || 500;
  return { message, status };
};

// /**
//  * Error handling function for API endpoint external calls.
//  *
//  * **NOTE:** This function should only be used server-side as it utilizes `process`.
//  */
// export const handleError = (error: unknown, res: Response, requestMethod: string = 'GET') => {
//   const { message, status } = extractError(error as UnknownError);

//   // Check if the error is HTML
//   if (error instanceof Error && error.message.startsWith('<')) {
//     // Convert HTML to JSON
//     const jsonError = {
//       error: {
//         message: 'Internal Server Error',
//         status: 504,
//         html: error.message,
//       },
//     };
//     res.status(504).json(jsonError.error);
//   }
//   console.error(`${requestMethod} - ${__dirname.replace(process.cwd(), '')} -`, error);

//   res.status(status).json({ error: { message, status } });
// };
