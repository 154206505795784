import React, { useState } from 'react';
import { Group, Button, Typography } from '@screentone/core';
import TwentyTwenty from 'react-twentytwenty';

function DeveloperDocs() {
  const [image, setImage] = useState({
    id: 'cldExample',
    query: 'the person',
    left: 'https://res.cloudinary.com/demo/image/upload/docs/horse-with-rider.jpg',
    right: 'https://res.cloudinary.com/demo/image/upload/e_gen_remove:prompt_the%20person/docs/horse-with-rider.jpg',
  });
  const [sliderPosition, setSliderPosition] = useState(1);

  const sliderStyles = {
    height: '100%',
    width: '2px',
    background: '#FFF',
  };
  const imageStyles = {
    width: '800px',
  };
  return (
    <>
      <Typography variant="header1">AI : Object Removal</Typography>
      <Group margin={{ bottom: 'md' }}>
        <Button
          secondary
          size="sm"
          active={image.id === 'cldExample'}
          onClick={() => {
            setImage({
              id: 'cldExample',
              query: 'left phone; right phone',
              left: 'https://res.cloudinary.com/demo/image/upload/pm/product_updates/2023/02/brooklyn_bridge.jpg',
              right:
                'https://res.cloudinary.com/demo/image/upload/e_upscale/v1674845506/pm/product_updates/2023/02/brooklyn_bridge.jpg',
            });
            setSliderPosition(1);
          }}
        >
          Cloudinary Example
        </Button>
        <Button
          secondary
          size="sm"
          active={image.id === 'dogExample'}
          onClick={() => {
            setImage({
              id: 'buyside',
              query: 'left phone;right phone',
              left: 'https://images-buyside.dev.djtools.dowjones.io/image/upload/t_preview/f_auto,q_auto:eco/wsjcommerce/cld/b2ebbvbf59qeph80mu9z',
              right:
                'https://images-buyside.dev.djtools.dowjones.io/image/upload/e_gen_remove:prompt_(left%20phone;right%20phone)/t_preview/f_auto,q_auto:eco/wsjcommerce/cld/b2ebbvbf59qeph80mu9z',
            });
            setSliderPosition(1);
          }}
        >
          BuySide Phone
        </Button>
      </Group>
      <Typography margin={{ bottom: 'md' }}>
        Prompt:{' '}
        <Typography inline variant="code">
          {image.query}
        </Typography>
      </Typography>

      <div style={imageStyles}>
        <TwentyTwenty
          defaultPosition={sliderPosition}
          // eslint-disable-next-line @next/next/no-img-element
          left={<img style={imageStyles} src={image.left} alt="original" />}
          // eslint-disable-next-line @next/next/no-img-element
          right={<img style={imageStyles} src={image.right} alt="ai generated" />}
          slider={<div style={sliderStyles} />}
        />
      </div>

      <p>https://cloudinary.com/documentation/transformation_reference#e_gen_remove</p>
    </>
  );
}

export default DeveloperDocs;
