import React, { useState, useRef, useLayoutEffect, useCallback, MouseEvent } from 'react';

import { PageWrapper, Loader } from '@screentone/core';
import debounce from 'lodash/debounce';

import CardDragLayer from './CardDragLayer';
import * as utils from './Gallery.utils';
import GalleryItem from './GalleryItem';
import DraggableGalleryItem from './DraggableGalleryItem';
import ImageCard from '../ImageCard';
import ButtonAddToLightbox from '../Buttons/AddToLightbox';
import ButtonRemoveFromLightbox from '../Buttons/RemoveFromLightbox';
import AddToSourceButton from '../Buttons/AddToSource';
import { iframe } from '../../utils';
import { ActionsType, Dispatch, State } from '../../hooks/useLightbox';
import { useConfig } from '../../hooks/useConfig';
import { checkIfPublished, createPublishedIdsObj } from '../../utils/helpers';

import type { ImageType, PropertyType } from '../../types';

import styles from './Gallery.module.css';

type GalleryProps = {
  /** Array of images. TODO: need to pull from standard type def once API is finalized */
  images: ImageType[];
  /** whether this takes up all available width at wider breakpoints */
  fullWidth?: boolean;
  /** callback for adding to lightbox */
  onAddToLightbox?: (image?: ImageType | null, e?: MouseEvent, id?: string) => void;
  /** callback for removing from lightbox */
  onRemoveFromLightbox?: (img: ImageType) => void;
  /** values exported from the useLightbox hook */
  lightboxResources: {
    actions: ActionsType;
    dispatch: Dispatch;
    state: State;
  };
  /** callback when publish button is pressed, also controls whether it appears */
  setImage(img: ImageType): void;
  /** Id for the image card being open */
  openImage: string | null;
  /** Setter for the image card */
  setOpenImage: (id: string | null) => void;
  publishedId?: string;
};

function Gallery({
  images,
  fullWidth = false,
  onAddToLightbox,
  onRemoveFromLightbox,
  lightboxResources,
  setImage,
  openImage,
  setOpenImage,
  publishedId,
}: GalleryProps) {
  const {
    session: { property, env },
  } = useConfig();
  const lastColumnCountRef = useRef(0);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  // css class for container
  const galleryClasses = [styles.gallery];
  if (fullWidth) galleryClasses.push(styles['gallery--fullwidth']);

  // keep track of image widths, which depends on breakpoint and also having images add up to 100% width
  const [grid, setGrid] = useState<{ width: number }[]>([]);

  // keep track of current number of columns, which depends on breakpoint
  const [columnCount, setColumnCount] = useState<number>(0);

  const handleOpeningImageCard = useCallback(
    (image: ImageType) => {
      setOpenImage(null);
      if (image.asset_id !== openImage) {
        setOpenImage(image.asset_id);
      }
    },
    [openImage]
  );

  const onImageClick = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      const { actions, dispatch, state } = lightboxResources;

      const clickedImage = images[index];

      let imagesSelectedForDrag: ImageType[] = [];

      // deselect image that has already been selected for drag
      if (state.selectedForDrag.find((image) => image.asset_id === clickedImage.asset_id)) {
        imagesSelectedForDrag = state.selectedForDrag.filter((image) => image.asset_id !== clickedImage.asset_id);
        if (clickedImage.asset_id === openImage) setOpenImage(null);

        // image has been clicked without modifiers
      } else if (!e.metaKey && !e.shiftKey) {
        imagesSelectedForDrag = [clickedImage];

        // handle opening the image card for the clicked on image
        handleOpeningImageCard(clickedImage);

        // shift key was held on click - select range of images for drag
      } else if (e.shiftKey) {
        if (!state.selectedForDrag.length) {
          imagesSelectedForDrag = [clickedImage];
        } else if (state.lastSelectedDragIndex >= index) {
          imagesSelectedForDrag = [...state.selectedForDrag, ...images.slice(index, state.lastSelectedDragIndex)];
        } else {
          imagesSelectedForDrag = [
            ...state.selectedForDrag,
            ...images.slice(state.lastSelectedDragIndex + 1, index + 1),
          ];
        }

        // cmd/ctrl key was held on click - select only the clicked image for drag
      } else if (e.metaKey) {
        const foundIndex = state.selectedForDrag.findIndex((f) => f.asset_id === clickedImage.asset_id);

        // the image is already selected for drag - remove it
        if (foundIndex >= 0) {
          imagesSelectedForDrag = [
            ...state.selectedForDrag.slice(0, foundIndex),
            ...state.selectedForDrag.slice(foundIndex + 1),
          ];

          // the image isn't already selected for drag - add it
        } else {
          imagesSelectedForDrag = [...state.selectedForDrag, clickedImage];
        }
      }

      // filter out any images that have already been added to the lightbox
      imagesSelectedForDrag = imagesSelectedForDrag.filter(
        (image) => !state.images.some((img) => img.asset_id === image.asset_id)
      );

      dispatch({
        type: actions.ADD_TO_SELECTED_FOR_DRAG,
        payload: {
          selectedForDrag: imagesSelectedForDrag,
          lastSelectedDragIndex: imagesSelectedForDrag.length ? index : -1,
        },
      });
    },
    [handleOpeningImageCard, images, lightboxResources, openImage]
  );

  const onImageDismiss = useCallback(() => {
    setOpenImage(null);
  }, [setOpenImage]);

  useLayoutEffect(() => {
    if (images.length === 0) {
      setGrid([]);
      return;
    }

    function getColumnCount() {
      const containerWidth = gridContainerRef?.current?.clientWidth || 0;
      let maxImageWidth = 250;

      if (containerWidth < 500) {
        maxImageWidth = 200;
      } else if (containerWidth > 2560) {
        maxImageWidth = 300;
      }

      const cols = Math.floor(containerWidth / maxImageWidth);
      setColumnCount(cols);

      return cols;
    }

    function buildGrid(cols: number, property: PropertyType) {
      lastColumnCountRef.current = cols;
      const containerWidth = gridContainerRef?.current?.clientWidth || 0;
      const layoutGrid = utils.buildGrid({ images, columnCount: cols, containerWidth }, property);
      setGrid(layoutGrid);
    }

    const debouncedResize = debounce(() => {
      const cols = getColumnCount();
      if (cols !== lastColumnCountRef.current) {
        buildGrid(cols, property);
      }
    }, 500);

    function onResize() {
      debouncedResize();
    }

    window.addEventListener('resize', onResize, { passive: true });
    buildGrid(getColumnCount(), property);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [images, setColumnCount, property]);

  const wasGridComputed = grid.length === images.length;
  const imageJSX = [];

  const sendImageArray = onAddToLightbox && onRemoveFromLightbox;

  if (wasGridComputed) {
    for (let i = 0; i < images.length; i += columnCount) {
      const batch = images.slice(i, i + columnCount);
      let activeImage: ImageType | undefined;
      let isSelected = false;
      let isActiveSelected = false;
      const additionalLightboxActions = (image: ImageType, isImageSelected: boolean) => {
        const publishedIdsObj = createPublishedIdsObj(image as ImageType, property, env);
        return (
          sendImageArray &&
          image &&
          (isImageSelected ? (
            <ButtonRemoveFromLightbox onClick={() => onRemoveFromLightbox(image as ImageType)} />
          ) : (
            <ButtonAddToLightbox
              publishedIdsObj={publishedIdsObj}
              onClick={(e: any, info: any) => {
                onAddToLightbox(image, e, info?.id);
              }}
              placeholder={{ temp: { id: '', label: 'Default' } }}
            />
          ))
        );
      };
      const additionalIframeActions = (image: ImageType) => {
        const isPublished = checkIfPublished(image, property);

        if (!sendImageArray && iframe.isFrame() && isPublished) {
          return (
            <AddToSourceButton image={image} />
          );
        }

        return null;
      };

      imageJSX.push(
        <React.Fragment key={i}>
          <CardDragLayer
            columnCount={columnCount}
            grid={grid}
            gridContainerRef={gridContainerRef}
            lightboxResources={lightboxResources}
            property={property}
            env={env}
          />
          <div className={styles.row}>
            {batch.map((image, index) => {
              const dimensions = grid[index + i];
              isSelected = !!lightboxResources.state.images?.find((s) => s.asset_id === image?.asset_id);

              if (openImage === image.asset_id) {
                activeImage = image;
                isActiveSelected = isSelected;
              }

              return sendImageArray ? (
                <DraggableGalleryItem
                  key={image.asset_id}
                  active={lightboxResources.state.selectedForDrag.some((d) => d.asset_id === image.asset_id)}
                  image={image}
                  index={index + i}
                  options={{
                    env,
                    property,
                  }}
                  dimensions={dimensions}
                  onClick={onImageClick(index + i)}
                  isSelected={isSelected}
                  lightboxResources={lightboxResources}
                  onSelect={onAddToLightbox}
                  onDeselect={onRemoveFromLightbox}
                />
              ) : (
                <GalleryItem
                  key={image.asset_id}
                  active={lightboxResources.state.selectedForDrag.some((d) => d.asset_id === image.asset_id)}
                  image={image}
                  dimensions={dimensions}
                  onClick={() => handleOpeningImageCard(image)}
                  isSelected={isSelected}
                  isActiveSelected={openImage === image.asset_id}
                  onSelect={onAddToLightbox}
                  onDeselect={onRemoveFromLightbox}
                  options={{
                    property,
                    env,
                  }}
                />
              );
            })}
          </div>
          {activeImage && (
            <PageWrapper>
              <ImageCard
                additionalActions={
                  additionalLightboxActions(activeImage, isActiveSelected) || additionalIframeActions(activeImage)
                }
                image={activeImage}
                onDismiss={onImageDismiss}
                selected={isSelected}
                setImage={setImage}
                publishedId={publishedId}
              />
            </PageWrapper>
          )}
        </React.Fragment>
      );
    }
  }

  return (
    <div className={galleryClasses.join(' ')} ref={gridContainerRef}>
      {wasGridComputed ? (
        imageJSX
      ) : (
        <div className={styles.loading}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Gallery;
