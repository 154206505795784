import { format } from '@screentone/addon-calendar';

// import Calendar, { calendarHooks, format } from '@screentone/addon-calendar';
import {
  Input,
  FormLabel,
  Select,
  IconPlusCircle,
  Group,
  Textarea,
  Button,
  Wrapper,
  Box,
  Responsive,
  IconTrash,
  Typography,
  Grid,
  FormHelperText,
} from '@screentone/core';
import React, { useEffect } from 'react';

import { useConfig } from '../../../../hooks/useConfig';
import useUpload from '../../../../hooks/useUpload';
import { constants, helpers } from '../../../../utils';
// import type { EditImageMeta, DisableEditFieldEditImageMeta, DisableEditField, GraphicType, PropertyType } from '../../../../types';
import { requiredFieldsForPublisher } from '../../../../utils/helpers';

import type { GraphicType } from '../../../../types';
import type UseUploadType from '../../../../hooks/useUpload/types';

import PaddingColor from '../../../PaddingColor/PaddingColor.component';

const IMAGE_WIDTH = 340;

type UploadFormProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles: React.MouseEventHandler<HTMLElement> | undefined;
};

const UploadDynamicForm = ({ handleBrowseFiles }: UploadFormProps) => {
  const { actions, dispatch, state, type } = useUpload();
  const {
    session: { property },
  } = useConfig();

  const fieldValidation = state.validation.find((fields) => typeof fields === 'object');
  const onChange = (data: any = {}) => {
    const metadata: {
      metadata: UseUploadType.BulkMetadata.BulkMetadata;
    } = { metadata: { ...state.bulk.metadata, ...{ [data.key]: data.value } } };

    dispatch({ type: actions.BULK_METADATA.EDIT, payload: metadata });
    dispatch({ type: actions.BULK_METADATA.APPLY_METADATA });
    dispatch({ type: actions.BULK_METADATA.VALIDATION, payload: metadata });
  };

  useEffect(() => {
    if (state.bulk.metadata.datePhotographed === '') {
      const date = format(Date.now(), constants.DATE_FORMATS.CLOUDINARY);
      const metadata: {
        metadata: UseUploadType.BulkMetadata.BulkMetadata;
      } = { metadata: { ...state.bulk.metadata, ...{ datePhotographed: date } } };

      dispatch({ type: actions.BULK_METADATA.EDIT, payload: metadata });
    }
  }, [actions.BULK_METADATA.EDIT, dispatch, state.bulk.metadata, state.bulk.metadata.datePhotographed]);

  useEffect(() => {
    if (state.bulk.metadata.graphicType === '') {
      const metadata: {
        metadata: UseUploadType.BulkMetadata.BulkMetadata;
      } = { metadata: { ...state.bulk.metadata, ...{ graphicType: 'Illustration' } } };

      dispatch({ type: actions.BULK_METADATA.EDIT, payload: metadata });
    }
  }, [actions.BULK_METADATA.EDIT, dispatch, state.bulk.metadata, state.bulk.metadata.graphicType]);

  const requiredFields = requiredFieldsForPublisher(property); // , disableRequiredFields);

  return (
    <Wrapper data-testid="metadata-card" margin={{ bottom: 'md', top: 'md' }}>
      <Typography variant="header3">Promo Image Upload</Typography>
      <Box padding={{ all: 'md' }}>
        <Group fullWidth direction="column">
          <FormLabel fullWidth label="Headline" required={requiredFields.headline}>
            <Input
              placeholder={`Headline ${requiredFields.headline ? 'Required' : ''}`}
              data-testid="headline-input"
              disabled={false}
              onBlur={({ target }: { target: HTMLInputElement }) => {
                onChange({ key: 'headline', value: target.value.trimEnd() });
              }}
              onChange={({ target }: { target: HTMLInputElement }) =>
                onChange({ key: 'headline', value: target.value.trimStart() })
              }
              value={state.bulk.metadata.headline}
            />
          </FormLabel>

          <FormLabel fullWidth label="Caption" required={requiredFields.caption}>
            <Textarea
              placeholder={`Caption ${requiredFields.caption ? 'Required' : ''}`}
              data-testid="caption-input"
              onBlur={({ target }: { target: HTMLInputElement }) => {
                onChange({ key: 'caption', value: target.value.trimEnd() });
              }}
              onChange={({ target }: { target: HTMLInputElement }) =>
                onChange({ key: 'caption', value: target.value.trimStart() })
              }
              value={state.bulk.metadata.caption}
              // Commented out - not sure why this is the only field with error prop
              // error={requiredFields.caption || !!fieldValidation?.caption}
            />
            {fieldValidation?.caption && <FormHelperText error>{fieldValidation?.caption}</FormHelperText>}
          </FormLabel>
          <Group.Item fullWidth>
            <Grid>
              <Grid.Row>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Credit" required={requiredFields.credit}>
                    <Input
                      placeholder={`Credit ${requiredFields.credit ? 'Required' : ''}`}
                      data-testid="credit-input"
                      onBlur={({ target }: { target: HTMLInputElement }) => {
                        onChange({ key: 'credit', value: target.value.trimEnd() });
                      }}
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        onChange({ key: 'credit', value: target.value.trimStart() })
                      }
                      value={state.bulk.metadata.credit}
                    />
                  </FormLabel>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Contact" required={requiredFields.contact}>
                    <Input
                      placeholder={`Contact ${requiredFields.contact ? 'Required' : ''}`}
                      data-testid="contact-input"
                      onBlur={({ target }: { target: HTMLInputElement }) => {
                        onChange({ key: 'contact', value: target.value.trimEnd() });
                      }}
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        onChange({ key: 'contact', value: target.value.trimStart() })
                      }
                      value={state.bulk.metadata.contact}
                    />
                  </FormLabel>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row bottom>
                <Grid.Col xs={12} sm={6}>
                  <FormLabel fullWidth label="Type" margin={{ top: 'md' }}>
                    <Select
                      data-testid="type-select"
                      name="Type"
                      onChange={({ target }: { target: HTMLInputElement }) =>
                        onChange({ key: 'graphicType', value: target.value as GraphicType })
                      }
                      value={state.bulk.metadata.graphicType || 'Illustration'}
                    >
                      {constants.GRAPHIC_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                  </FormLabel>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                  <PaddingColor
                    margin={{ top: 'md' }}
                    background={state.bulk.metadata.background}
                    onChange={(target: any) => {
                      onChange({ key: 'background', value: target.background.trimStart() });
                    }}
                    position="right"
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Group.Item>
        </Group>
      </Box>
      <Grid>
        <Grid.Row>
          {state.render.length === state.files.accepted.length &&
            state.files.accepted.map((file, i) => {
              const xScale = state.metadata[i]?.xScale || 0;
              return (
                <Grid.Col key={file.name} xs={12} sm={6} md={4} lg={3}>
                  <Box data-testid="promo-card" attached="top" margin={{ top: 'md' }}>
                    <Box.Title padding={{ all: 'none' }}>
                      <Group fullWidth>
                        <Group.Item
                          data-testid="promo-image-file-name"
                          flex
                          margin={{ vertical: 'smd', horizontal: 'mlg' }}
                        >
                          {file.name.split('.')[0]}
                        </Group.Item>
                        <Group.Item margin={{ right: 'smd' }}>
                          <Button
                            data-testid="promo-image-delete-button"
                            tertiary
                            icon={IconTrash}
                            onClick={() => {
                              dispatch({ type: actions.ALL.REMOVE_IMAGES, payload: { selected: [i] } });
                            }}
                          />
                        </Group.Item>
                      </Group>
                    </Box.Title>
                    <Box.Content>
                      <picture>
                        <img
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                          alt={file.name}
                          src={state.render[i].secure_url}
                          width={IMAGE_WIDTH}
                        />
                      </picture>
                    </Box.Content>
                    <Box fullWidth>
                      <Typography align="right" size="sm" margin={{ vertical: 'smd', horizontal: 'mlg' }}>
                        {helpers.formatBytes(file.size)}
                        {' • '}
                        {state.render[i].width / xScale} x {state.render[i].height / xScale} px
                        {xScale > 1 ? ` @${xScale}x` : ''}
                        {' • '}
                        {file.type.replace('image/', '').toUpperCase().replace('+XML', '')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid.Col>
              );
            })}
        </Grid.Row>
      </Grid>
      <Group direction="column" gap="md" margin={{ top: 'md' }}>
        <Typography variant="note" margin={{ horizontal: 'md' }}>
          Required fields are marked with an asterisk{' '}
          <Typography inline variant="note" color="lava">
            *
          </Typography>
        </Typography>
        <Typography data-testid="max-img-text" variant="note" margin={{ horizontal: 'md' }}>
          Maximum of {constants.MAX_FILES_IN_UPLOADER[type]} images can be uploaded at a one time. (
          {state?.files?.accepted?.length} / {constants.MAX_FILES_IN_UPLOADER[type]})
        </Typography>
      </Group>
      <Button
        data-testid="promo-images-upload-more-images-button"
        type="button"
        icon={IconPlusCircle}
        fullWidth
        secondary
        margin={{ top: 'md', bottom: 'lg' }}
        onClick={handleBrowseFiles}
        disabled={state.files.accepted.length === constants.MAX_FILES_IN_UPLOADER[type]}
      >
        Upload More Images
      </Button>
    </Wrapper>
  );
};

export default UploadDynamicForm;
