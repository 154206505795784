export const handleDelete = (authFetch: any, publicId: string) => {
  return new Promise((resolve, reject) => {
    const encodedPublicId = encodeURIComponent(publicId);

    return authFetch(`/api/:property/${encodedPublicId}`, {
      method: 'DELETE',
    })
      .then((data: any) => {
        if (data[publicId] === 'not_found') {
          reject();
        } else {
          resolve(true);
        }
      })
      .catch(() => {
        reject();
      });
  });
};

export const handleMetadataUpdates = (authFetch: any, publicId: string, metadata: object, publishedId?: string) => {
  return new Promise((resolve, reject) => {
    const encodedPublicId = encodeURIComponent(publicId);

    authFetch(`/api/:property/${encodedPublicId}`, {
      method: 'POST',
      body: JSON.stringify({
        ...metadata,
        publishedId,
      }),
    })
      .then((data: any) => {
        resolve(data);
      })
      .catch((err: Error) => {
        reject();
      });
  });
};
