import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">API Integration</Typography>
      <MarkdownBlock
        md={`
Image Manager has two types of endpoints, Internal and Private, most of Image Mangers API endpoints are internal endpoints used by the Image Manager client ONLY.

Image Manager's Private API endpoints are for integrating Image Manager with other Dow Jones / News Crop
applications.

## Authentication

To authorize a request to Image Manager's API, we need to generate an JWT access tokens to send in the header of the request.

Image Manager generates two types of JWT access tokens;

1. \`User Access Token\` - this user JWT is generate by the user using PKCE flow within the Image Manager UI. The permissions of this JWT token will be determined by the roles defined in users profile.
2. \`Machine-to-Machine Authentication\` - this machine JWT can be generate by calling the Image Manager
   Authorization Server. If you are authenticating Machine-to-Machine (M2M) then the JWT token will not contain any user information and will the Scope will limit then endpoint it authorizes. [See our M2M Authentication guild](/developer/api-tokens) for details on generating a JWT access token.

If you are using Machine-to-Machine Authentication you can pass some user information in custom headers to help filter some user information.

### Additional User Headers

- \`x-djtools-dj-user-id\` - _optional:_ user's SAMAccountName / DJ ID to pass in the header of requests to Image Manager
- \`x-djtools-email\` - _optional:_  user's email to pass in the header of requests to Image Manager
- \`x-djtools-username\` - _optional:_ user's display name to pass in the header of requests to Image Manager

## Making the Request

The Image Manager [v2 Swagger docs](/developer/swagger) are the easiest way to request the search API endpoint.

To authorize the swagger docs as yourself, toggle on the "Authorize as myself" button in the upper right corner of the swagger docs. Otherwise use the "Authorize" button to use the JWT token provided by the [M2M Authentication flow](/developer/api-tokens).

`}
      />
    </>
  );
}

export default DeveloperDocs;
