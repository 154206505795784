import UseUploadType from './types';
import type { PropertyType } from '../../types';
import { requiredFieldsForPublisher } from '../../utils/helpers';

const EMPTY_ERROR = 'cannot be empty.';

/**
 * Given an array of validation information for upload state, replace all `null` values with errors
 * about empty fields
 */
export const validateEmptyFields = (
  validation: UseUploadType.Validation.State,
  property: PropertyType
): [UseUploadType.Validation.State, boolean] => {
  const updatedValidation = [];
  let hasErrors = false;
  const requiredFields = requiredFieldsForPublisher(property);

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < validation.length; i++) {
    const { caption, contact, credit, datePhotographed, headline, slug } = validation[i];
    updatedValidation.push({
      caption: !requiredFields.caption && validation[i].caption === null ? false : validation[i].caption,
      contact: !requiredFields.contact && validation[i].contact === null ? false : validation[i].contact,
      credit: !requiredFields.credit && validation[i].credit === null ? false : validation[i].credit,
      datePhotographed:
        !requiredFields.datePhotographed && validation[i].datePhotographed === null
          ? false
          : validation[i].datePhotographed,
      // caption: caption === null ? `Caption ${EMPTY_ERROR}` : caption,
      // contact: contact === null ? `Contact ${EMPTY_ERROR}` : contact,
      // credit: credit === null ? `Credit ${EMPTY_ERROR}` : credit,
      // // datePhotographed may not be empty in the input, but if its invalid it will be empty in state
      // datePhotographed: datePhotographed === null ? 'Invalid Date Photographed' : datePhotographed,
      headline: requiredFields.headline && headline === null ? `Headline ${EMPTY_ERROR}` : headline,
      slug: requiredFields.slug && slug === null ? `Slug ${EMPTY_ERROR}` : slug, 
    });

    // false === valid field

    if (
      (requiredFields.caption && caption !== false) ||
      (requiredFields.contact && contact !== false) ||
      (requiredFields.credit && credit !== false) ||
      (requiredFields.datePhotographed && datePhotographed !== false) ||
      typeof datePhotographed === 'string' ||
      (requiredFields.headline && headline !== false) || 
      (requiredFields.slug && slug !== false || typeof slug === 'string')
    ) {
      hasErrors = true;
    }
  }

  return [updatedValidation, hasErrors];
};

/**
 * Given an object of validation, return boolean to indicate if all required fields are filled
 */
export const validateBulkEmptyFields = (
  validation: UseUploadType.BulkMetadata.Validation,
  property: PropertyType
): [UseUploadType.Validation.State, boolean] => {

  let hasErrors = false;
  const requiredFields = requiredFieldsForPublisher(property);
  const { caption, contact, credit, datePhotographed, headline } = validation;

  if (
    (requiredFields.caption && caption !== false) ||
    (requiredFields.contact && contact !== false) ||
    (requiredFields.credit && credit !== false) ||
    (requiredFields.datePhotographed && datePhotographed !== false) ||
    typeof datePhotographed === 'string' ||
    (requiredFields.headline && headline !== false)
  ) {
    hasErrors = true;
  }
  
  return [[], hasErrors];
};

export default validateEmptyFields;
