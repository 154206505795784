import { Illustration, Typography, Button, Box } from '@screentone/core';
import React from 'react';
import useUpload from '../../../hooks/useUpload';

import styles from '../../../layouts/Upload/Upload.module.css';

type UploadBoxProps = {
  /** function supplied by react-dropzone to handle opening the file system */
  handleBrowseFiles: React.MouseEventHandler<HTMLElement> | undefined;
};

function UploadBox({ handleBrowseFiles }: UploadBoxProps) {
  const { type } = useUpload();
  const uploadType = type;

  return (
    <Box className={styles.upload__panel_box}>
      <Illustration name="image-manager" className={styles.upload__panel_box_img} />

      <Typography variant="header2" margin={{ bottom: 'none' }}>
        Drag and drop images
      </Typography>

      <Typography variant="label2" margin={{ vertical: 'sm' }}>
        or
      </Typography>

      <Button
        data-testid="browse-file-btn"
        type="button"
        margin={{ top: 'sm', bottom: 'lg' }}
        primary
        onClick={handleBrowseFiles}
      >
        Browse Files
      </Button>
      <Typography componentEl="p" size="sm" color="asphalt">
        Allowed file types: {uploadType === 'dynamic' ? '.svg ' : ''}.jpeg, .png, .gif
      </Typography>
    </Box>
  );
}

export default UploadBox;
