import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Group,
  IconCaretRight,
  IconCheckCircle,
  IconError,
  Loader,
  Responsive,
  Wrapper,
} from '@screentone/core';

import ActionButtons from '../ActionButtons';
import CloseButtonIcon from './CloseButtonIcon';
import ImageTokenList from '../ImageTokenList';

import MetadataDescription from '../ImageDetails/MetadataDescription';
import DownloadButtons from '../ImageDetails/DownloadButtons';

import Image from '../Image';
import { helpers } from '../../utils';
import { createPublishedIdsObj } from '../../utils/helpers';
import useConfig from '../../hooks/useConfig';
import useSearch from '../../hooks/useSearch';

import type { ImageType, UploadImagePlaceholderType, StatusType } from '../../types';

import styles from './ImageCard.module.css';

type ImageCardProps = {
  image: ImageType | UploadImagePlaceholderType;
  /** additional buttons to display */
  additionalActions?: React.ReactNode;
  /** handler for dismiss button X */
  onDismiss?(event: React.MouseEvent<HTMLButtonElement>): void;
  /** whether image shows selected outline */
  selected?: boolean;
  /** the status of the image if it is being uploaded */
  status?: StatusType;
  /** handler for when image is selected */
  setImage?: (image: ImageType) => void;

  publishedId?: string;
};

function ImageCard({
  image: img,
  additionalActions,
  onDismiss,
  selected = false,
  status,
  setImage,
  publishedId,
}: ImageCardProps) {
  const {
    session: { env, property },
  } = useConfig();

  const [image, setLocalImage] = useState(img);
  const { imScrollToId } = useSearch();

  const publishedIdsObj = createPublishedIdsObj(image as ImageType, property, env);

  const executeScroll = () => {
    // this setTimeout is needed, if we don't have the scrollIntoView doesn't work for some reason. We think it's because somewhere else in the code the ET-6447 window.scrollY is being set and it's overriding the scrollIntoView.
    if (imScrollToId === image.asset_id) {
      const element = document.getElementById(imScrollToId);
      element?.scrollIntoView({ block: 'center', inline: 'nearest' });
      setTimeout(() => {
        const element = document.getElementById(imScrollToId);
        element?.scrollIntoView({ block: 'center', inline: 'nearest' });
      }, 1000);
    }
  };

  useEffect(() => {
    setLocalImage(img);
  }, [img]);

  useEffect(() => {
    executeScroll();
  }, [imScrollToId]);

  const setImageHandler = (updatedImage: ImageType) => {
    if (setImage) {
      setImage(updatedImage);
      setLocalImage(updatedImage);
    }
  };

  const isUploading = ['loading'].includes(status as string);
  const hideButtons = ['loading', 'prepublish'].includes(status as string);
  return (
    <>
      <Responsive xs sm>
        {(matches: boolean) => {
          const { caption, headline } = image.metadata;
          const boxClasses = [styles.imagecard, selected ? styles['imagecard--selected'] : ''];
          const boxProps = matches
            ? { className: [...boxClasses, styles['imagecard--small']].join(' ') }
            : { className: [...boxClasses, styles['imagecard--large']].join(' '), padding: { all: 'md' } };

          const imageUrl = helpers.getImageUrl({ image, env, property, defaultPreviewUrl: true });
          const imageDetailsHREF =
            image.asset_id && !hideButtons && image.access_mode !== 'local-upload'
              ? `/${property}/image/${publishedId ? publishedId : image.asset_id}`
              : undefined;
          return (
            <>
              <Box id={image.asset_id} {...boxProps} data-testid="image-card">
                <Responsive xs sm md>
                  <Wrapper className={styles.imagecard__tokens} margin={{ left: 'sm', top: 'sm' }}>
                    <ImageTokenList
                      image={image as ImageType}
                      mode={status ? 'upload' : undefined}
                      setImage={setImageHandler}
                      hideLabels
                    />
                  </Wrapper>
                </Responsive>

                {(onDismiss || status) && (
                  <Responsive xs sm>
                    {(m: boolean) => {
                      const wrapperProps = m ? { margin: { right: 'sm', top: 'sm' } } : {};
                      return (
                        <Wrapper className={styles.imagecard__close_button} {...wrapperProps}>
                          <Group gap="sm">
                            {'public_id' in image && (
                              <>
                                <Group.Item padding="sm">
                                  <DownloadButtons data-testid="download-btn" image={image as ImageType} />
                                </Group.Item>
                                <Divider orientation="vertical" flexItem />
                              </>
                            )}
                            {onDismiss && (
                              <Button
                                data-testid="close-button"
                                aria-label="close"
                                color="asphalt"
                                icon={CloseButtonIcon}
                                onClick={onDismiss}
                                tertiary
                              />
                            )}
                            {status === 'complete' && <IconCheckCircle color="emerald" />}
                            {isUploading && <Loader size="md" />}
                            {status === 'error' && <IconError color="lava" />}
                          </Group>
                        </Wrapper>
                      );
                    }}
                  </Responsive>
                )}

                <Responsive xs sm>
                  {(m: boolean) => {
                    let wrapperProps: any = {
                      // TODO: Peran wants to come back to this ??
                      style: { aspectRatio: helpers.getImageAspectRatio(image, property) },
                    };

                    wrapperProps = {
                      ...wrapperProps,
                      className: [
                        styles.imagecard__imgplacer,
                        styles[`imagecard__imgplacer--${m ? 'small' : 'large'}`],
                      ].join(' '),
                    };

                    return (
                      <div {...wrapperProps}>
                        {imageDetailsHREF ? (
                          <Link to={imageDetailsHREF} state={{ image }}>
                            <Image
                              data-testid="img-preview"
                              alt={caption || headline || 'Image without caption'}
                              src={imageUrl}
                            />
                          </Link>
                        ) : (
                          <Image
                            alt={caption || headline || 'Image without caption'}
                            className={styles.imagecard__img}
                            src={imageUrl}
                          />
                        )}
                      </div>
                    );
                  }}
                </Responsive>

                <Responsive xs sm>
                  {(m: boolean) => {
                    const padding = m ? { all: 'md' } : { left: 'lg' };

                    return (
                      <Wrapper className={styles.imagecard__text_wrapper} padding={padding}>
                        <Group className={styles.imagecard__text} direction="column" valign="space-between">
                          <Group direction="column" gap="md">
                            <MetadataDescription
                              image={image as ImageType}
                              setImage={(img) => {
                                if (setImage) {
                                  setImage(img);
                                }
                                setLocalImage(img);
                              }}
                              hideLabels={hideButtons}
                            />
                          </Group>

                          <Group align="space-between" className={styles.visible} fullWidth gap="none">
                            <Group direction="row" gap="sm">
                              {!hideButtons && (
                                <ActionButtons image={image as ImageType} publishedIdsObj={publishedIdsObj} />
                              )}
                              {/**
                               * TODO: Update 'ActionButtons' and 'additionalActions'
                               * so that the 'ActionButtons' controls the 'Add to Source'
                               * button instead of duplicating it in the 'additionalActions'.
                               */}
                              {additionalActions}
                            </Group>

                            {imageDetailsHREF && (
                              <Button
                                data-testid="view-image-details"
                                componentEl={Link}
                                state={{ image }}
                                tertiary
                                icon={IconCaretRight}
                                iconPosition="right"
                                to={imageDetailsHREF}
                              >
                                View Image Details
                              </Button>
                            )}
                          </Group>
                        </Group>
                      </Wrapper>
                    );
                  }}
                </Responsive>
              </Box>
            </>
          );
        }}
      </Responsive>
    </>
  );
}

export default ImageCard;
