import React, { useCallback } from 'react';
import { Button, Group, IconCaretDown, Dropdown, IconCaretUp, useDropdownState, FormLabel } from '@screentone/core';

import styles from './PaddingColor.module.css';
import ColorPicker from '../ColorPicker/ColorPicker.component';

type PaddingColorProps = {
  /** background color */
  background?: string;
  /** event to apply background color change */
  onChange: ({ background }: { background: string }) => void;
  /** dropdown disabled */
  disabled?: boolean;
  /** dropdown position */
  position?: string;
  /** dropdown position */
  cldColors?: string[];
  /** dropdown position */
  margin?: {} | null;
  //** Optional: hide form label  */
  hideLabel?: boolean;
};

function PaddingColor({
  background,
  onChange,
  disabled = false,
  position = 'left',
  cldColors = [],
  margin = null,
  hideLabel = false,
}: PaddingColorProps) {
  const { open, setOpen, componentRef } = useDropdownState();

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);
  return (
    <Group direction="column" gap="xs">
      {!hideLabel && <FormLabel label="Padding Color" labelPosition="top" margin={margin} onClick={handleClick} />}
      <Dropdown
        data-testid="background-color"
        className={styles.colorPicker__resize__padding_color}
        componentRef={componentRef}
        onToggle={() => setOpen(!open)}
        open={open}
        position={position}
        disabled={disabled}
      >
        <Dropdown.Trigger>
          <Group gap="xs" margin={{ all: 'xs' }}>
            <Group.Item>
              <div
                style={{ backgroundColor: background }}
                className={
                  background === 'transparent' || !background
                    ? [
                        styles.colorPicker__resize__padding_color__transparent_bg,
                        styles.colorPicker__resize__padding_color__color_box,
                      ].join(' ')
                    : styles.colorPicker__resize__padding_color__color_box
                }
              >
                &nbsp;
              </div>
            </Group.Item>
            <Group.Item>
              <Button icon={open ? IconCaretUp : IconCaretDown} iconPosition="right" tertiary disabled={disabled} />
            </Group.Item>
          </Group>
        </Dropdown.Trigger>
        <Dropdown.Content padding={{ all: 'xs' }} style={{ width: '15rem' }} arrow={`top-${position}`}>
          <Group direction="column" gap="md">
            <Group.Item fullWidth data-testid="color-picker">
              <ColorPicker color={background} onChange={onChange} cldColors={cldColors} />
            </Group.Item>
          </Group>
        </Dropdown.Content>
      </Dropdown>
    </Group>
  );
}

export default PaddingColor;
