import React, { useEffect, useState } from 'react';
import { Helmet as Head } from 'react-helmet';
import { PageWrapper, Typography, Box, Token, FabButton, IconPlus, Group } from '@screentone/core';
import useConfig from '../../../hooks/useConfig';
import CreateCollection from '../../../components/Collections/CreteCollection';
import InnerBrowserButton from '../../../components/InnerBrowserModel';

import { getImageUrl } from '../../../utils/helpers';
import { ImageDetailProvider } from '../../../hooks/useImageDetail';
import ImageDetailsLayout from '../../../layouts/ImageDetails';
import { EditImageProvider } from '../../../hooks/useEditImage';

function CollectionsPage() {
  const {
    authFetch,
    session: { env, property },
   } = useConfig();
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!data) {

      authFetch(`/api/:property/collections`)
        .then((data: any) => {
          console.log('data: ', data);
          setData(data);
        })
        .catch((err: Error) => {
          console.error('handleApplyTags Error: ', err);
        });
    }
  });


  return (
    <>
      <Head>
        <title>Images - Collections</title>
      </Head>
      <PageWrapper padding={{top: 'lg', bottom: 'xl'}}>
      
        <CreateCollection componentEl={FabButton} icon={IconPlus} toolTipContent="New Collection" />
        
        {data?.map((collection: any) => {
          // console.log('collection: ', collection);

          collection.related_assets.map((image: any) => {
            const imageUrl = getImageUrl({ image: {
              ...image,
              ...{ metadata: { active: 'true' }, context: {} }
            }, env, property, defaultPreviewUrl: true });
            
            
            console.log('imageUrl: ', imageUrl);
          });

          return (
            <Box key={collection.public_id} padding={{ all: 'md' }} margin={{ bottom: 'md' }}>
              <Typography variant="header2" margin={{ top: 'none', bottom: 'xs' }}>{collection.metadata.headline} {!['true', 'false'].includes(collection.metadata.searchable) &&  <Token color="blurple" margin={{right: 'sm'}}>PRIVATE</Token>}<Token>{collection.related_assets.length}</Token></Typography>
              <Typography variant="note">{collection.metadata.caption}</Typography>
              <Group gap="md" margin={{ top: 'md' }}>
              {collection.related_assets.map((image: any) => {
                const imageUrl = getImageUrl({ image: {
                  ...image,
                  ...{ metadata: { active: 'true' }, context: {} }
                }, env, property, defaultPreviewUrl: true });
            
                return (<>
                  <img key={image.public_id} src={imageUrl} alt={image.metadata.caption} style={{ height: '200px' }} />
                  <InnerBrowserButton href={`/${property}/image/${image.asset_id}`} trigger="Image Details">
                  
                    <ImageDetailProvider initialImage={image} imageId={image.asset_id}>
                      <EditImageProvider>
                        <ImageDetailsLayout />
                      </EditImageProvider>
                    </ImageDetailProvider>
                  </InnerBrowserButton>
                </>);
              })

                }</Group>
          </Box>
        )})}
      </PageWrapper>
    </>
  );
}

export default CollectionsPage;
