import React, { useEffect, useState } from 'react';
import { Alert, Input, Group, FormLabel, Button, Box, Typography, IconCaretLeft, Grid, Select } from '@screentone/core';
import { Link } from 'react-router-dom';

import smartCropInfo from '../../../utils/smartCropInfo';
import { helpers, constants } from '../../../utils';
import { getTransformationType } from '../../../utils/helpers';
import { useConfig } from '../../../hooks/useConfig';

import type { PropertyType } from '../../../types';

function DeveloperDocs() {
  const properties = constants.PROPERTY_LABELS;

  const searchParams = new URLSearchParams(location.search);
  const {
    authFetch,
    session: { env },
  } = useConfig();

  const [property, setProperty] = useState((searchParams.get('property') || 'mansionglobal') as PropertyType);
  const [id, setId] = useState(searchParams.get('id') as PropertyType);
  const [image, setImage] = useState();

  const [oldSmartCropData, setOldSmartCropData] = useState<{
    smartcrop?: any;
    transformation?: any;
    public_id?: string;
  }>({});
  const [newSmartCropData, setNewSmartCropData] = useState<{
    smartcrop?: any;
    transformation?: any;
    public_id?: string;
  }>({});

  const [newCldData, setNewCldData] = useState<{
    transformation?: any;
    public_id?: string;
  }>({});

  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);

  const formSubmit = () => {
    authFetch(`/api/${property}/${id}`)
      .then((img) => {
        setImage(img);
      })
      .catch((error) => {
        return { data: null, error };
      });
  };
  useEffect(() => {
    if (image?.tags?.includes(id)) {
      smartCropInfo({
        authFetch,
        env,
        property: property as PropertyType,
        id: id as string,
        width,
        height,
        image,
      }).then((scInfo) => {
        setOldSmartCropData(scInfo?.oldSC);
        setNewSmartCropData(scInfo?.newSC);
        setNewCldData(scInfo?.newCLD);
      });
    }
  }, [id, width, height, env, property, image]);

  const IMAGE_DOMAIN = helpers.getImageDomain(env, property as PropertyType);

  const transparentBgStyles = {
    backgroundImage:
      'linear-gradient(45deg, #ccc 25%, transparent 25%),linear-gradient(-45deg, #ccc 25%, transparent 25%),linear-gradient(45deg, transparent 75%, #ccc 75%),linear-gradient(-45deg, transparent 75%, #ccc 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  };

  const backStyles = {
    zIndex: 'var(--st-zindex-mid)',
    borderBottom: 'var(--st-border-width) solid var(--st-border-color)',
    backgroundColor: 'var(--st-page-bg)',
    padding: 'var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-mlg)',
    marginBottom: 'var(--st-spacer-lg)',
  };

  const setSize = (w: number, h: number) => {
    setOldSmartCropData({});
    setNewSmartCropData({});
    setNewCldData({});
    setWidth(w);
    setHeight(h);
  };

  // // image is an error
  // if (error && error.http_code !== 204) {
  //   return <ErrorPage type={error.http_code} />;
  // }

  return (
    <>
      {id && property && (
        <div style={backStyles}>
          <Group align="space-between">
            <Button icon={IconCaretLeft} tertiary componentEl={Link} href={`/${property}/image/${id}`}>
              Image Details
            </Button>
          </Group>
        </div>
      )}
      <>
        <Grid>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Typography variant="header1">Smart Crop Debugger</Typography>
              <Group valign="end" margin={{ bottom: 'md' }}>
                <FormLabel label="Property" labelPosition="top">
                  <Select
                    name="name"
                    value={property}
                    onChange={(event: any) => {
                      setProperty(event.target.value as PropertyType);
                    }}
                  >
                    {Object.keys(properties).map((e) => (
                      <option key={e} value={e}>
                        {properties[e as PropertyType]}
                      </option>
                    ))}
                  </Select>
                </FormLabel>
                <FormLabel label="id" labelPosition="top">
                  <Input value={id} onChange={(event: any) => setId(event.target.value)} />
                </FormLabel>
                <Button primary onClick={() => formSubmit()}>
                  Submit
                </Button>
              </Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Group margin={{ bottom: 'md' }}>
                <Button
                  tertiary
                  onClick={() => {
                    setSize(300, 300);
                  }}
                >
                  300 x 300
                </Button>
                <Button
                  tertiary
                  onClick={() => {
                    setSize(620, 349);
                  }}
                >
                  620 x 349
                </Button>
                <Button
                  tertiary
                  onClick={() => {
                    setSize(940, 529);
                  }}
                >
                  940 x 529
                </Button>

                <FormLabel label="width" labelPosition="left">
                  <Input
                    value={width}
                    onChange={(event: any) => setWidth(event.target.value)}
                    style={{ width: '70px' }}
                  />
                </FormLabel>
                <FormLabel label="height" labelPosition="left">
                  <Input
                    value={height}
                    onChange={(event: any) => setHeight(event.target.value)}
                    style={{ width: '70px' }}
                  />
                </FormLabel>
              </Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={4}>
              {id && image && getTransformationType(image, property as PropertyType, id) !== 'djSmartCrop' && (
                <Alert>This ID is not linked to a legacy smart crop image</Alert>
              )}
              {newSmartCropData?.smartcrop && (
                <>
                  <Box>
                    <Box.Title>New</Box.Title>
                    <Box.Content style={transparentBgStyles}>
                      {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
                      <img
                        src={`${IMAGE_DOMAIN}/image/upload/${newSmartCropData?.transformation}/${newSmartCropData?.public_id}`}
                        style={{ maxWidth: '100%' }}
                      />
                    </Box.Content>
                  </Box>
                  <Box attached="bottom">
                    <Box.Content>
                      <pre>
                        <code>{JSON.stringify(newSmartCropData?.smartcrop?.result, null, 2)}</code>
                      </pre>
                    </Box.Content>
                  </Box>
                  <Box attached="bottom">
                    <Box.Content>
                      <pre>
                        <code>
                          {JSON.stringify(
                            newSmartCropData?.smartcrop?.params.seedCrops.map(
                              (crop: {
                                x: number;
                                y: number;
                                height: number;
                                width: number;
                                seed: boolean;
                                size?: string;
                                outputWidth: number;
                                outputHeight: number;
                              }) => {
                                return {
                                  x: crop.x,
                                  y: crop.y,
                                  height: crop.height,
                                  width: crop.width,
                                  seed: crop.seed,
                                  size: crop.size,
                                  outputWidth: crop.outputWidth,
                                  outputHeight: crop.outputHeight,
                                };
                              }
                            ),
                            null,
                            2
                          )}
                        </code>
                      </pre>
                    </Box.Content>
                  </Box>
                </>
              )}
            </Grid.Col>

            <Grid.Col xs={4}>
              {oldSmartCropData?.smartcrop && (
                <>
                  <Box>
                    <Box.Title>Old</Box.Title>
                    <Box.Content style={transparentBgStyles}>
                      {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
                      <img
                        src={`https://image-manager.djnews.tools/images/${property}/${id}?width=${width}&height=${height} `}
                        style={{ maxWidth: '100%' }}
                      />
                    </Box.Content>
                  </Box>
                  <Box attached="bottom">
                    <Box.Content>
                      <pre>
                        <code>{JSON.stringify(oldSmartCropData?.smartcrop?.result, null, 2)}</code>
                      </pre>
                    </Box.Content>
                  </Box>
                  <Box attached="bottom">
                    <Box.Content>
                      <pre>
                        <code>
                          {JSON.stringify(
                            oldSmartCropData?.smartcrop?.params.seedCrops.map(
                              (crop: {
                                x: number;
                                y: number;
                                height: number;
                                width: number;
                                seed: boolean;
                                size?: string;
                                outputWidth: number;
                                outputHeight: number;
                              }) => {
                                const cropObj: {
                                  x: number;
                                  y: number;
                                  height: number;
                                  width: number;
                                  seed: boolean;
                                  size?: string;
                                  outputWidth: number;
                                  outputHeight: number;
                                } = {
                                  x: crop.x,
                                  y: crop.y,
                                  height: crop.height,
                                  width: crop.width,
                                  seed: crop.seed,
                                  outputWidth: crop.outputWidth,
                                  outputHeight: crop.outputHeight,
                                };
                                if (crop.size === 'square') {
                                  cropObj.size = 'square';
                                }
                                return cropObj;
                              }
                            ),
                            null,
                            2
                          )}
                        </code>
                      </pre>
                    </Box.Content>
                  </Box>
                </>
              )}
            </Grid.Col>
            <Grid.Col xs={4}>
              {newCldData?.transformation && (
                <Box>
                  <Box.Title>CLD</Box.Title>
                  <Box.Content style={transparentBgStyles}>
                    {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
                    <img
                      src={`${IMAGE_DOMAIN}/image/upload/${newCldData?.transformation}/${newCldData?.public_id}`}
                      style={{ maxWidth: '100%' }}
                    />
                  </Box.Content>
                </Box>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </>
    </>
  );
}

export default DeveloperDocs;
